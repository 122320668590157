<template>
  <div class="functionsSection" v-if="importantFunctions">
    <div class="grid-container">
      <div class="row">
        <div class="col-x12 col-xxl10 offset-xxl1">
          <div
            class="sectionTitle flatMd"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <h2
              class="title"
              v-text="
                $store.state.page.homepage.home.important_function_title
                  .mobile_title
              "
            ></h2>
            <h3
              class="subtitle"
              v-text="
                $store.state.page.homepage.home.important_function_title
                  .top_title_desktop
              "
            ></h3>
            <h3
              class="title"
              v-text="
                $store.state.page.homepage.home.important_function_title
                  .bottom_title_desktop
              "
            ></h3>
          </div>
          <div class="functionsSlider">
            <swiper
              ref="fnSlider"
              class="swiper"
              :options="swiperFnOption"
              @slideChange="handleSlideChange()"
            >
              <template v-for="(slideCols, i) in handleShitSlider">
                <swiper-slide
                  :key="i"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <template v-for="(infoBox, n) in slideCols">
                    <single-checklist
                      class="shortTitle"
                      :key="n + i + '-tes'"
                      :item="infoBox"
                      :large="$store.state.ui.device === 'lg'"
                      v-on:triggerCollapse="triggerCollapse"
                      :trigger="true"
                      :checked="infoBox.is_countable"
                    ></single-checklist>
                  </template>
                </swiper-slide>
              </template>
            </swiper>
            <div class="sliderTools">
              <div
                class="swiper-pagination swiper-pagination-two"
                slot="pagination"
              ></div>
              <div
                class="arrows swiper-prev swiper-prev-m"
                slot="button-prev"
                @click="handleArrowsEvent('b')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="14"
                  viewBox="0 0 25 14"
                >
                  <path
                    id="Path"
                    d="M23.958,5.949a1.051,1.051,0,0,1,0,2.1H3.556l4.117,4.155a1.058,1.058,0,0,1,0,1.487,1.035,1.035,0,0,1-1.473,0L.3,7.744a1.059,1.059,0,0,1,0-1.487h0L6.2.308a1.036,1.036,0,0,1,1.473,0,1.058,1.058,0,0,1,0,1.487L3.556,5.949Z"
                    transform="translate(0)"
                  />
                </svg>
              </div>
              <div
                class="arrows swiper-next swiper-next-m"
                slot="button-next"
                @click="handleArrowsEvent('n')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="14"
                  viewBox="0 0 25 14"
                >
                  <path
                    id="Path"
                    d="M1.042,8.05a1.051,1.051,0,0,1,0-2.1h20.4L17.328,1.794a1.056,1.056,0,0,1,0-1.485,1.035,1.035,0,0,1,1.474,0L24.7,6.257a1.058,1.058,0,0,1,0,1.486L18.8,13.692a1.035,1.035,0,0,1-1.474,0,1.057,1.057,0,0,1,0-1.486L21.444,8.05Z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/css/swiper.css'
import dynamicModule from '@/store/utils/dynamicModule'
import { mapActions, mapState } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import categoriesStore from '@/store/modules/categories'
import SingleChecklist from '@/components/single-checklist.vue'

export default {
  props: {
    items: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      swiperFnOption: {
        slidesPerView: 1,
        // slidesPerColumn: 4,
        // slidesPerColumnFill: 'row',
        // centeredSlides: false,
        // loop: true,
        spaceBetween: 6,
        observer: true,
        pagination: {
          el: '.swiper-pagination-two',
          type: 'fraction'
        },
        navigation: {
          nextEl: '.swiper-next-m',
          prevEl: '.swiper-prev-m'
        },
        allowTouchMove: false,
        breakpoints: {
          768: {
            slidesPerView: 2
            // slidesPerColumn: 3
          },
          1367: {
            slidesPerView: 3
            // slidesPerColumn: 2
          }
        }
      },
      infoBoxes: []
    }
  },
  components: {
    Swiper,
    SingleChecklist,
    SwiperSlide
  },
  mixins: [dynamicModule('categories', categoriesStore)],
  computed: {
    ...mapState('user', ['user']),
    ...mapState('categories', ['importantFunctions']),
    swiper () {
      return this.$refs?.fnSlider?.$swiper
    },
    handleShitSlider () {
      let wwidth = window.innerWidth
      let myarr = []
      let spliter = wwidth > 1365 ? 2 : wwidth > 768 ? 3 : 4
      let crIndex = 0
      for (let i = 0; i < this.infoBoxes.length; i++) {
        !this.infoBoxes[i].visible &&
          this.$set(this.infoBoxes[i], 'visible', false)
        if (i % spliter === 0) {
          crIndex++
          myarr.push([])
        }
        if (i === 0) {
          myarr.push([this.infoBoxes[i]])
        } else {
          myarr[crIndex].push(this.infoBoxes[i])
        }
      }
      return myarr.slice(1)
    }
  },
  methods: {
    ...mapActions('categories', ['loadImportantFunctions']),
    handleSlideChange (val) {
      this.infoBoxes.forEach((item) => {
        item.visible = false
      })
    },
    handleArrowsEvent (data) {
      const back = this.swiper.isBeginning
      const fwd = this.swiper.isEnd
      switch (data) {
        case 'b':
          if (back) {
            this.swiper.slideTo(this.infoBoxes.length)
            setTimeout(() => {
              this.swiper.slideNext()
            }, 0)
          }
          break
        default:
          if (fwd) {
            this.swiper.slideTo(0)
            setTimeout(() => {
              this.swiper.slidePrev()
            }, 0)
          }
          break
      }
    },
    triggerCollapse (id) {
      for (let i = 0; i < this.infoBoxes.length; i++) {
        if (this.infoBoxes[i].id !== id) {
          this.infoBoxes[i].visible = false
        }
      }
    }
  },
  mounted () {
    let self = this
    if (this.items === null) {
      this.loadImportantFunctions().then((response) => {
        self.infoBoxes = response.info_box
      })
    } else {
      this.infoBoxes = this.items
    }
    // this.$nextTick(() => {
    //   this.swiper.slideTo(0, 0)
    // })
  }
}
</script>
