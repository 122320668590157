import Usercentrics from '@usercentrics/cmp-browser-sdk'

export default class UserCentricsController {
  essential = 'esential'
  functional = 'customCategory-2547490b-c2b7-47b6-a503-33c5fd5eb077'
  performance = 'customCategory-c2b45c72-730a-497b-9f4e-7405940f28df'

  areEssentialCookiesAccepted () {
    return this.isCookieCategoryAccepted(this.essential)
  }

  areFunctionalCookiesAccepted () {
    return this.isCookieCategoryAccepted(this.functional)
  }

  arePerformanceCookiesAccepted () {
    return this.isCookieCategoryAccepted(this.performance)
  }

  isCookieCategoryAccepted (categorySlug) {
    let UC = new Usercentrics(process.env.VUE_APP_USERCENTRICS_KEY)
    return new Promise((resolve) => {
      UC.changeLanguage('de').then(() => {
        UC.init().then(() => {
          UC.getCategoriesBaseInfo().forEach(function (category) {
            if (category.slug === categorySlug) {
              resolve(category.services[0].consent.status)
            }
          })
        })
      })
    })
  }
}
