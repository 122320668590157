<template>
  <main v-if="homepage">
    <starting-point
    :key="'sp' + spKey"
    :homepage="homepage"
    :teasers="expertTeasers"
    @handleModalChecklist="handleModalChecklist"
    @handleLevelChange="handleLevelChange"
    @keyChange="keyChange" />
    <advantage-worlds :key="'aw' + awKey" v-if="homepage.advantageWorlds"
                      :items="homepage.advantageWorlds"></advantage-worlds>
    <banner-image-content-h
    v-if="homepage.banner && homepage.banner.length > 0"
    class="homepageBanner"
    :item="homepage.banner[0]"
    :image="responsiveImages(homepage.banner[0])"></banner-image-content-h>
    <other-functions :key="'of' + ofKey" v-if="homepage.importantFunctionsBoxes"
                     :items="homepage.importantFunctionsBoxes || null"></other-functions>
    <div class="overlay"
         :class="{ isModal: (cookieUser && $store.state.ui.device === 'sm') }"
         v-if="(dialogOverlay && $store.state.ui.device !== 'sm' ) || (user.chkModal && !user.firstInfobox && $store.state.ui.device === 'sm')"
         @click="dialogOverlay = false"></div>
    <div class="modalContainer cookieSampleModal homeCookieModal"
         v-if="user.chkModal && popups.checklist && !user.firstInfobox && $store.state.ui.device === 'sm'">
      <div class="modalPopup">
        <div class="popupHeader text-center">
          <div v-if="popups.checklist && responsiveImages(popups.checklist)" class="mb-4">
            <img :src="responsiveImages(popups.checklist)" style="max-height: 120px" alt="American Express">
          </div>
          <h2 v-if="popups.checklist.title" v-html="popups.checklist.title"></h2>
          <!-- <div class="spacer"></div> -->
          <span class="iconRight">
            <button class="iconButton small" type="button" @click="handleModalChecklist">
              <img :src="icons.close" width="100%"/>
            </button>
          </span>
        </div>
        <div class="popupContent">
          <div class="text-center" v-if="popups.checklist.content" v-html="popups.checklist.content"></div>
        </div>
        <div class="popupActions flex justify-center">
          <button class="button rounded block" @click="handleModalChecklist"
                  v-text="popups.checklist.button_title || 'Los geht\'s'"></button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import userStore from '@/store/modules/user'
import cardsStore from '@/store/modules/cards'
import checklistsStore from '@/store/modules/checklists'
import uiStore from '@/store/modules/ui'
import pagesStore from '@/store/modules/page'
import StartingPoint from '@/components/pages/starting-point'
import AdvantageWorlds from '@/components/pages/advantage-worlds'
import OtherFunctions from '@/components/pages/other-functions'
import BannerImageContentH from '@/components/banners/banner-image-content-h.vue'

export default {
  mixins: [
    dynamicModule('user', userStore),
    dynamicModule('cards', cardsStore),
    dynamicModule('checklists', checklistsStore),
    dynamicModule('page', pagesStore),
    dynamicModule('ui', uiStore)
  ],
  data () {
    return {
      dialogOverlay: false,
      cookieImageM: require('@/assets/images/mobil-route.png'),
      cookieUser: false,
      icons: {
        close: require('@/assets/images/x-icon-blue.svg'),
        closeWhite: require('@/assets/images/x-icon-white.svg')
      },
      spKey: 0,
      awKey: 0,
      ofKey: 0,
      startingPoint: '',
      homepage: null,
      popupTextTimeout: null,
      popupImageTimeout: null,
      expertTeasers: null
    }
  },
  metaInfo () {
    return {
      title: this.homepage?.home.meta.title !== '' ? this.homepage?.home.meta.title : '',
      description: this.homepage?.home.meta.description
    }
  },
  components: {
    StartingPoint,
    OtherFunctions,
    AdvantageWorlds,
    BannerImageContentH
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('cards', ['cards']),
    ...mapState('checklists', ['checklists', 'countableChecklist']),
    ...mapState('page', ['popups']),
    ...mapState('ui', ['reload'])
  },
  watch: {
    reload (nv) {
      if (nv) {
        this.handleLevelChange('i')
      }
    }
  },
  methods: {
    ...mapActions('user', ['addCheckedList', 'addUser', 'addFirstBox', 'addChkModal']),
    ...mapActions('page', ['loadHome', 'clearPage', 'searchPopups', 'loadExpertTeaserBoxes']),
    ...mapActions('checklists', ['searchCountable']),
    ...mapActions('ui', ['triggerReload', 'changeNavItems', 'changePopupImage', 'changePopupText']),
    handleLevelChange (val) {
      this.loadHome().then(resp => {
        this.homepage = resp
        this.awKey += 1
        this.ofKey += 1
        this.searchCountable().then(resp => {
          this.handleCountables(resp)
        })

        if (val && val === 'i') {
          this.triggerReload(false)
          this.changeNavItems()
          // this.keyChange()
        }
        if (val === 'key') {
          this.keyChange()
        }
        if (this.homepage?.popups?.length) {
          this.homepage.popups.forEach(popup => {
            if (popup?.type) {
              !localStorage.getItem('sv_popups') && localStorage.setItem('sv_popups', '{}')
              let popupLocalData = JSON.parse(localStorage.getItem('sv_popups'))

              !sessionStorage.getItem('sv_popups') && sessionStorage.setItem('sv_popups', '{}')
              let popupsSessionData = JSON.parse(sessionStorage.getItem('sv_popups'))

              if (!popupsSessionData.hasOwnProperty(popup.id)) {
                if (popupLocalData.hasOwnProperty(popup.id) &&
                (parseInt(popupLocalData[popup.id]) < parseInt(popup.visit_popup))) {
                  popupLocalData[popup.id] = parseInt(popupLocalData[popup.id]) + 1
                  popupsSessionData = { ...popupsSessionData, [popup['id']]: 1 }

                  this.triggerSurveyPopup(popup)
                  localStorage.setItem('sv_popups', JSON.stringify(popupLocalData))
                  sessionStorage.setItem('sv_popups', JSON.stringify(popupsSessionData))
                } else if (!popupLocalData.hasOwnProperty(popup.id)) {
                  popupLocalData = { ...popupLocalData, [popup['id']]: 1 }
                  popupsSessionData = { ...popupsSessionData, [popup['id']]: 1 }

                  this.triggerSurveyPopup(popup)
                  localStorage.setItem('sv_popups', JSON.stringify(popupLocalData))
                  sessionStorage.setItem('sv_popups', JSON.stringify(popupsSessionData))
                }
              }
            }
          })
        }
        if (this.user.currentLevel === 3) {
          this.loadExpertTeaserBoxes().then((resp) => {
            if (resp?.id) this.expertTeasers = resp
            else this.expertTeasers = null
          })
        }
      })
    },
    async handleCountables (myArray) {
      let data = this.user.checked
      const cardId = this.user.card.id
      for (let i = 0; i < myArray.length; i++) {
        if (myArray[i] && data && !data.hasOwnProperty(myArray[i])) {
          this.$set(data, myArray[i], {})
          data[`${myArray[i]}`] = {
            [`${cardId}`]: false
          }
        }
      }
      await this.addCheckedList(data)
      await this.addUser(this.user)
    },
    handleModalChecklist () {
      this.addFirstBox(true)
    },
    fullImage (data) {
      return data !== null ? this.$store.state.init.init.config.mediaBaseUrl + data : ''
    },
    responsiveImages (data) {
      if (this.$store.state.ui.device === 'sm') {
        return data.image_mobile ? this.fullImage(data.image_mobile) : this.fullImage(data.image_desktop)
      } else if (this.$store.state.ui.device === 'md') {
        return data.image_tablet ? this.fullImage(data.image_tablet) : this.fullImage(data.image_desktop)
      } else {
        return this.fullImage(data.image_desktop)
      }
    },
    keyChange () {
      this.spKey += 1
    },
    triggerSurveyPopup (data) {
      switch (data.type) {
        case 'survey_popup_text':
          this.popupTextTimeout = setTimeout(() => {
            this.changePopupText(data)
          }, (parseInt(data.delay) || 0) * 1000)
          break
        default:
          this.popupImageTimeout = setTimeout(() => {
            this.changePopupImage(data)
          }, (parseInt(data.delay) || 0) * 1000)
          break
      }
    }
  },
  mounted () {
    this.searchCountable().then(resp => {
      this.handleCountables(resp)
    })
    this.handleLevelChange()
    this.searchPopups()
    this.changeNavItems()
  },
  beforeDestroy () {
    clearTimeout(this.popupTextTimeout)
    clearTimeout(this.popupImageTimeout)
    this.clearPage()
  }
}
</script>
