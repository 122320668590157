<template>
  <div class="sliderSection" :class="{ smallSlider: small }" v-if="content">
    <div class="grid-container">
      <div class="row">
        <div class="col-x12 col-xxl10 offset-xxl1">
          <div class="sectionTitle" data-aos="fade-left" data-aos-duration="1000">
            <h3 class="subtitle" v-if="content.subtitle" v-text="content.subtitle"></h3>
            <h2 class="title" v-if="content.title" v-text="content.title"></h2>
          </div>
        </div>
      </div>
    </div>
    <div class="sliderHolder">
      <swiper ref="mySlider" class="swiper" :options="swiperOption" @slideChange="slideChange">
        <swiper-slide :key="i" v-for="(item, i) in sliderCategories" :class="{ 'swiper-no-swiping': isLocked && $store.state.ui.device !== 'sm' }">
          <div class="imageHolder">
            <router-link :to="{ name: 'Category', params: { slug: item.slug }}" class="showActive">
              <img :src="fullImage(item.homepage_image)"/>
            </router-link>
            <img :src="fullImage(item.homepage_image)" />
          </div>
          <!-- <div class="imageHolder">
            <img :src="fullImage(item.homepage_image)"/>
          </div> -->
          <div class="slideContent testtesttest" v-if="$store.state.ui.device !== 'sm'">
            <router-link :to="{ name: 'Category', params: { slug: item.slug }}" @mouseenter.native="isLocked = true" @mouseleave.native="isLocked = false">
              <h4 v-text="item.title"></h4>
            </router-link>
            <p v-text="item.description"></p>
            <router-link :to="{ name: 'Category', params: { slug: item.slug }}" @mouseenter.native="isLocked = true" @mouseleave.native="isLocked = false" class="button rounded inverse">
              Mehr erfahren
            </router-link>
          </div>
          <div class="slideContent" v-else>
            <router-link :to="{ name: 'Category', params: { slug: item.slug }}">
              <h4 v-text="item.title"></h4>
            </router-link>
            <p v-text="item.description"></p>
            <router-link :to="{ name: 'Category', params: { slug: item.slug }}" class="button rounded inverse">
              Mehr erfahren
            </router-link>
          </div>
        </swiper-slide>
      </swiper>
      <div class="sliderTools">
        <div class="swiper-pagination swiper-pagination-one" slot="pagination"></div>
        <div class="arrows swiper-prev swiper-prev-a" slot="button-prev">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="14" viewBox="0 0 25 14">
            <path id="Path"
                  d="M23.958,5.949a1.051,1.051,0,0,1,0,2.1H3.556l4.117,4.155a1.058,1.058,0,0,1,0,1.487,1.035,1.035,0,0,1-1.473,0L.3,7.744a1.059,1.059,0,0,1,0-1.487h0L6.2.308a1.036,1.036,0,0,1,1.473,0,1.058,1.058,0,0,1,0,1.487L3.556,5.949Z"
                  transform="translate(0)"/>
          </svg>
        </div>
        <div class="arrows swiper-next swiper-next-a" slot="button-next">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="14" viewBox="0 0 25 14">
            <path id="Path"
                  d="M1.042,8.05a1.051,1.051,0,0,1,0-2.1h20.4L17.328,1.794a1.056,1.056,0,0,1,0-1.485,1.035,1.035,0,0,1,1.474,0L24.7,6.257a1.058,1.058,0,0,1,0,1.486L18.8,13.692a1.035,1.035,0,0,1-1.474,0,1.057,1.057,0,0,1,0-1.486L21.444,8.05Z"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import categoriesStore from '@/store/modules/categories'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  props: {
    content: {
      default: function () {
        return { title: 'Vorteilswelten', subtitle: '' }
      }
    },
    items: {
      type: Array,
      default: null
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  mixins: [
    dynamicModule('categories', categoriesStore)
  ],
  data () {
    return {
      isLocked: false,
      swiperOption: {
        slidesPerView: 1,
        autoHeight: false,
        centeredSlides: true,
        spaceBetween: 10,
        slideToClickedSlide: true,
        initialSlide: 1,
        // observer: true,
        // observeParent: true,
        // resistance: true,
        // resistanceRatio: 1,
        pagination: {
          el: '.swiper-pagination-one',
          type: 'fraction'
        },
        navigation: {
          nextEl: '.swiper-next-a',
          prevEl: '.swiper-prev-a'
        },
        threshold: 20,
        loop: true,
        loopAdditionalSlides: 6,
        noSwiping: true,
        breakpoints: {
          768: {
            slidesPerView: 2,
            threshold: 50
          },
          993: {
            slidesPerView: this.small ? 4 : 3,
            spaceBetween: 10,
            threshold: 50
          },
          1366: {
            slidesPerView: this.small ? 4 : 3,
            spaceBetween: 20,
            threshold: 50
          }
        }
      },
      sliderCategories: []
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  computed: {
    ...mapState('categories', ['categories']),
    swiper () {
      return this.$refs.mySlider.$swiper
    }
  },
  methods: {
    ...mapActions('categories', ['loadCategories']),
    fullImage (data) {
      return data !== null ? this.$store.state.init.init.config.mediaBaseUrl + data : ''
    },
    responsiveImages (data) {
      if (this.$store.state.ui.device === 'sm') {
        return data.image_mobile ? this.fullImage(data.image_mobile) : this.fullImage(data.image_desktop)
      } else if (this.$store.state.ui.device === 'md') {
        return data.image_tablet ? this.fullImage(data.image_tablet) : this.fullImage(data.image_desktop)
      } else {
        return this.fullImage(data.image_desktop)
      }
    },
    slideChange () {
      this.swiper.update()
    }
  },
  mounted () {
    if (this.items === null) {
      this.loadCategories(this.$route.params.slug).then((resp) => {
        this.sliderCategories = this.categories
        this.$nextTick(() => {
          this.swiper.update()
          // this.swiper.slideNext()
          this.swiper.slidePrev()
        })
      })
    } else {
      this.sliderCategories = this.items
      this.$nextTick(() => {
        this.swiper.update()
        // this.swiper.slideNext()
        this.swiper.slidePrev()
      })
    }
  },
  beforeDestroy () {
    this.isLocked = false
  }
}
</script>
