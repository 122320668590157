import { AuthHttp } from '@/helpers/http-helper'

const state = {
  results: {},
  suggests: []
}

const getters = {
  getResults: state => state.results,
  getSuggests: state => state.results
}

const mutations = {
  setResults (state, results) {
    state.results = results
  },
  setSuggests (state, suggests) {
    state.suggests = suggests
  },
  clearResult (state, results) {
    state.results = results
  },
  clearSuggest (state, suggests) {
    state.suggests = suggests
  }
}

const actions = {
  search ({ commit }, data) {
    return AuthHttp.get('/search?q=' + data.query).then(resp => {
      commit('setResults', resp.data)
      return resp.data
    })
  },
  findSuggests ({ commit }, data) {
    return AuthHttp.get('/search/suggest?q=' + data.query).then(resp => {
      commit('setSuggests', resp.data)
      return resp.data
    })
  },
  clearSuggests ({ commit }) {
    commit('clearSuggest', {})
  },
  clearSearch ({ commit }) {
    commit('clearResult', {})
  }
}
const namespaced = true

export default {
  namespaced,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
