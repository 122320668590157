<template>
  <main v-if="page">
    <div class="pageContent imageBannerNone" sticky-container>
      <div class="grid-container">
        <div class="row">
          <div class="col-x12 col-xxl10 offset-xxl1">
            <div v-sticky="shouldStick" sticky-offset="{ top: 80 }">
              <page-tools></page-tools>
            </div>
          </div>
        </div>
      </div>
      <div class="click-event-content">
        <ContentElements v-for="(element, index) in page.content_elements" :content="element" :key="index"/>
      </div>
      <banner-image-content-h
      v-if="page.banner && page.banner.length > 0"
      class="faqsBanner"
      :item="page.banner[0]"
      :image="responsiveImages(page.banner[0])"></banner-image-content-h>
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex'
import Sticky from 'vue-sticky-directive'
import pagesStore from '@/store/modules/page'
import dynamicModule from '@/store/utils/dynamicModule'
import PageTools from '@/components/pages/page-tools'
import ContentElements from '@/components/pages/content-elements-renderer'
import BannerImageContentH from '@/components/banners/banner-image-content-h.vue'

export default {
  data () {
    return {
      page: null,
      popupTextTimeout: null,
      popupImageTimeout: null
    }
  },
  metaInfo () {
    return {
      title: this.page?.meta.title !== '' ? this.page?.meta.title : this.page?.title,
      description: this.page?.meta.description
    }
  },
  mixins: [
    dynamicModule('page', pagesStore)
  ],
  directives: {
    Sticky
  },
  computed: {
    shouldStick () {
      return this.$store.state.ui.device !== 'sm' && (['/impressum', '/datenschutz'].includes(this.$route.fullPath))
    }
  },
  components: {
    PageTools,
    ContentElements,
    BannerImageContentH
  },
  methods: {
    ...mapActions('page', ['pageDetails', 'clearPage']),
    ...mapActions('ui', ['changePopupText', 'changePopupImage']),
    fullImage (data) {
      return data !== null ? this.$store.state.init.init.config.mediaBaseUrl + data : ''
    },
    responsiveImages (data) {
      if (this.$store.state.ui.device === 'sm') {
        return data.image_mobile ? this.fullImage(data.image_mobile) : this.fullImage(data.image_desktop)
      } else if (this.$store.state.ui.device === 'md') {
        return data.image_tablet ? this.fullImage(data.image_tablet) : this.fullImage(data.image_desktop)
      } else {
        return this.fullImage(data.image_desktop)
      }
    },
    triggerSurveyPopup (data) {
      switch (data.type) {
        case 'survey_popup_text':
          this.popupTextTimeout = setTimeout(() => {
            this.changePopupText(data)
          }, parseInt(data.delay || 0) * 1000)
          break
        default:
          this.popupImageTimeout = setTimeout(() => {
            this.changePopupImage(data)
          }, parseInt(data.delay || 0) * 1000)
          break
      }
    },
    handlePagePopups (data) {
      if (data?.popups?.length) {
        data.popups.forEach(popup => {
          if (popup?.type) {
            !localStorage.getItem('sv_popups') && localStorage.setItem('sv_popups', '{}')
            let popupLocalData = JSON.parse(localStorage.getItem('sv_popups'))

            !sessionStorage.getItem('sv_popups') && sessionStorage.setItem('sv_popups', '{}')
            let popupsSessionData = JSON.parse(sessionStorage.getItem('sv_popups'))

            if (!popupsSessionData.hasOwnProperty(popup.id)) {
              if (popupLocalData.hasOwnProperty(popup.id) &&
                (parseInt(popupLocalData[popup.id]) < parseInt(popup.visit_popup))) {
                popupLocalData[popup.id] = parseInt(popupLocalData[popup.id]) + 1
                popupsSessionData = { ...popupsSessionData, [popup['id']]: 1 }

                this.triggerSurveyPopup(popup)
                localStorage.setItem('sv_popups', JSON.stringify(popupLocalData))
                sessionStorage.setItem('sv_popups', JSON.stringify(popupsSessionData))
              } else if (!popupLocalData.hasOwnProperty(popup.id)) {
                popupLocalData = { ...popupLocalData, [popup['id']]: 1 }
                popupsSessionData = { ...popupsSessionData, [popup['id']]: 1 }

                this.triggerSurveyPopup(popup)
                localStorage.setItem('sv_popups', JSON.stringify(popupLocalData))
                sessionStorage.setItem('sv_popups', JSON.stringify(popupsSessionData))
              }
            }
          }
        })
      }
    }
  },
  mounted () {
    clearTimeout(this.popupTextTimeout)
    clearTimeout(this.popupImageTimeout)
    if (this.$route.params.slug !== undefined) {
      this.pageDetails(this.$route.params.slug).then(response => {
        this.page = response

        // localStorage.getItem('uc_performance') && this.$gtm.trackView(response.title, response.slug)
        this.handlePagePopups(this.page)
      }).catch(error => {
        Promise.reject(error)

        this.$router.push({ name: 'Home' })
      })
    } else if (this.$route.params.pathMatch === 'cookie-check') {
      this.$router.push({ name: 'Home' })
      let self = this
      setTimeout(function () {
        self.$store.dispatch('ui/changeCookieModal', true)
      }, 2000)
    } else {
      // const routeSlug = this.$route.params.slug
      this.pageDetails(this.$route.params.pathMatch).then(response => {
        this.page = response
        // localStorage.getItem('uc_performance') && this.$gtm.trackView(response.title, response.slug)
      }).catch(error => {
        Promise.reject(error)

        this.$router.push({ name: 'Home' })
      })
    }

    let self = this
    window.addEventListener('click', event => {
      let { target } = event
      while (target && target.tagName !== 'A') target = target.parentNode

      // handle only links that do not reference external resources
      if (target && target.matches("a[href='" + process.env.VUE_APP_PUBLIC_PATH + "cookie-popup']") && target.href) {
        event.preventDefault()
        self.$store.dispatch('ui/changeCookieModal', true)
      } else if (target && target.matches('a') && target.href) {
        if (!target.getAttribute('href').includes('http')) {
          event.stopPropagation()
          event.preventDefault()
          // console.log(target.getAttribute('href'), this.$route.path)
          target.getAttribute('href') !== self.$route.path && self.$router.push({ path: target.getAttribute('href') })
        }
      }
    })
  },
  beforeDestroy () {
    this.clearPage()
    clearTimeout(this.popupTextTimeout)
    clearTimeout(this.popupImageTimeout)
  },
  watch: {
    '$route' (to, from) {
      clearTimeout(this.popupTextTimeout)
      clearTimeout(this.popupImageTimeout)
      if (to.params.slug !== undefined) {
        this.pageDetails(this.$route.params.slug).then(response => {
          this.page = response
          this.handlePagePopups(this.page)
          // localStorage.getItem('uc_performance') && this.$gtm.trackView(response.title, response.slug)
        }).catch(error => {
          Promise.reject(error)

          this.$router.push({ name: 'Home' })
        })
      } else if (to.params.pathMatch === 'cookie-check') {
        this.$router.push({ name: 'Home' })
        let self = this
        setTimeout(function () {
          self.$store.dispatch('ui/changeCookieModal', true)
        }, 2000)
      } else {
        this.pageDetails(to.params.pathMatch).then(response => {
          this.page = response
          // localStorage.getItem('uc_performance') && this.$gtm.trackView(response.title, response.slug)
        }).catch(error => {
          Promise.reject(error)

          this.$router.push({ name: 'Home' })
        })
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
