export function createScript (src, id = '', async = true, defer = false) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.id = id
    script.async = async
    script.defer = defer
    script.onload = resolve
    script.onerror = reject
    document.head.appendChild(script)
  })
}
