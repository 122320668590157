<template>
  <router-view/>
</template>

<script>
export default {
  mounted () {
  }
}
</script>

<style scoped></style>
