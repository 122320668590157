import axios from 'axios'
import { store } from '../store'

export const Http = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT
})

Http.interceptors.request.use(function (request) {
  request.headers.Authorization = 'Bearer ' + store.state.authStore.token.access_token
  // request.headers.common['Access-Control-Allow-Origin'] = '*'
  return request
})

Http.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response.status === 401) { // need login
  } else if (error.response.status === 403) { // no access
  }

  return Promise.reject(error)
})

export const AuthHttp = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT
})

AuthHttp.interceptors.request.use(function (request) {
  request.headers.Card = (store.state.user && store.state.user.user.card.id) ? store.state.user.user.card.id : null
  request.headers.Level = (store.state.user && store.state.user.user.currentLevel) ? store.state.user.user.currentLevel : null
  return request
})
