<template>
  <div class="grid-container" v-if="content">
    <div class="row" sticky-container>
      <div class="col-x12 col-s4 offset-xxl1">
        <div v-sticky="shouldStick" sticky-offset="{ top: 150 }">
          <page-title :pageHeaders="content"></page-title>
        </div>
      </div>
      <div class="col-x12 col-xxl10 offset-xxl1 simplePage contentColumn">
        <div class="row flex flex-wrap">
          <div class="col-x12 col-s4 click-event-links" v-if="content.left_content">
            <div data-aos="fade-up" data-aos-duration="1000" v-html="content.left_content" v-sticky="shouldStick"
                 sticky-offset="{ top: 234 }"></div>
          </div>
          <div class="col-x12 col-s7 offset-s1 fixedRightContent" data-aos="fade-up" data-aos-duration="1000"
               v-if="content.right_content"
               v-html="content.right_content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sticky from 'vue-sticky-directive'
import PageTitle from '@/components/pages/page-title.vue'

export default {
  props: {
    content: {
      default: null
    }
  },
  mixins: [],
  data () {
    return {
      faqSections: []
    }
  },
  computed: {
    shouldStick () {
      return this.$store.state.ui.device !== 'sm'
    }
  },
  components: {
    PageTitle
  },
  directives: {
    Sticky
  }
}
</script>
