<template>
  <main
    class="starterMain"
    :class="{
      mobileOverflow:
        !selectedCard && visibleCardHolder && $store.state.ui.device === 'sm'
    }"
  >
    <div
      class="starterBanner"
      ref="starterBanner"
      :class="{
        mobileOverflow:
          !selectedCard && visibleCardHolder && $store.state.ui.device === 'sm',
        sliderVisible: currentStep === 2
      }"
      v-if="pageProps"
      :style="{ backgroundImage: 'url(' + responsiveImages(pageProps) + ')' }"
    >
      <div
        class="stickyIntro"
        :class="[selectedCard ? 'slCard' : 'noCard']"
        v-if="currentStep === 1"
      >
        <div
          data-aos-offset="50"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="900"
          v-if="$store.state.ui.device === 'sm'"
        >
          <!-- <img :src="returnLogo"/> -->
          <div class="flex align-center justify-center amex-logo-wrapper">
            <span class="flex align-center">
              <img :src="require('@/assets/images/amex_blue_box.svg')" />
            </span>
            <div
              class="ml-2 flex align-center amexLogoFade"
              :class="{ business: user.cardType && user.cardType === 2 }"
            >
              <span class="imgFirst">
                <img :src="require('@/assets/images/tagline_consumer.svg')" />
              </span>
              <span class="imgSecond">
                <img :src="require('@/assets/images/tagline_business.svg')" />
              </span>
            </div>
          </div>
        </div>
        <div
          data-aos-offset="50"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="1000"
        >
          <div
            class="mobileIntroWrapper"
            v-if="$store.state.ui.device === 'sm'"
          >
            <h4 v-text="pageProps.responsive_popup_title"></h4>
            <p v-text="pageProps.popup_content"></p>
          </div>
          <div class="desktopIntroWrapper" v-else>
            <h5 v-text="pageProps.popup_title"></h5>
            <h4 v-text="pageProps.popup_subtitle"></h4>
            <p v-text="pageProps.popup_content"></p>
          </div>
        </div>
        <div
          class="flex justify-center"
          v-if="selectedCard !== null && !skipFirstStep"
        >
          <button class="button rounded inverse block" @click.stop="nextStep()">
            <span>Weiter</span>
          </button>
        </div>
      </div>
      <transition name="slideRight">
        <div
          class="cardsWrapper"
          :class="{ expanded: visibleCardHolder }"
          v-if="!selectedCard"
        >
          <div class="toggleButton" :class="{ expanded: visibleCardHolder }">
            <button class="iconButton large" @click.stop="visibleCardSwitch()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="14"
                viewBox="0 0 25 14"
              >
                <path
                  id="Path"
                  d="M23.958,5.949a1.051,1.051,0,0,1,0,2.1H3.556l4.117,4.155a1.058,1.058,0,0,1,0,1.487,1.035,1.035,0,0,1-1.473,0L.3,7.744a1.059,1.059,0,0,1,0-1.487h0L6.2.308a1.036,1.036,0,0,1,1.473,0,1.058,1.058,0,0,1,0,1.487L3.556,5.949Z"
                  transform="translate(0)"
                />
              </svg>
            </button>
            <span @click.stop="visibleCardSwitch()">Produkt wählen</span>
          </div>
          <div class="cardsHolder">
            <transition name="slide">
              <div class="sectionTitle" v-if="visibleCardHolder">
                <h2 class="title" v-text="pageProps.title"></h2>
                <p>
                  {{ pageProps.no_client_title }}<br /><a
                    :href="pageProps.no_client_link"
                    target="_blank"
                    >Hier entlang</a
                  >
                </p>
              </div>
            </transition>
            <transition name="slide">
              <div class="cards" v-if="visibleCardHolder">
                <div class="cardTypesSwitch">
                  <ul :class="[cardType === 1 ? 'private' : 'business']">
                    <li
                      :class="{ active: cardType === 1 }"
                      @click.stop="switchCardType(1)"
                    >
                      <router-link
                        :to="{ name: 'Start', query: { card: 'private' } }"
                        >Private Karten</router-link
                      >
                    </li>
                    <li
                      :class="{ active: cardType === 2 }"
                      @click.stop="switchCardType(2)"
                    >
                      <router-link
                        :to="{ name: 'Start', query: { card: 'business' } }"
                        >Business Karten</router-link
                      >
                    </li>
                  </ul>
                </div>
                <div class="cardsContainer">
                  <ul>
                    <li v-for="(card, i) in cardTypes" :key="i">
                      <a
                        :href="card.external_link"
                        target="_blank"
                        class="singleCard"
                        :class="{ animated: cardAnimate }"
                        v-if="!!card.is_external && card.external_link"
                      >
                        <div class="cardTypeImage col-x6">
                          <img :src="fullImage(card.image_desktop)" />
                        </div>
                        <div class="cardTypeTitle">
                          <h4 v-text="card.title"></h4>
                          <button v-text="card.external_link_title"></button>
                        </div>
                      </a>
                      <div
                        v-else
                        class="singleCard"
                        :class="{ animated: cardAnimate }"
                      >
                        <div class="cardTypeImage col-x6">
                          <img
                            :src="fullImage(card.image_desktop)"
                            @click.stop="setCard(card)"
                          />
                        </div>
                        <div class="cardTypeTitle">
                          <h4 v-text="card.title"></h4>
                          <button @click.stop="setCard(card)">Auswählen</button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </transition>
      <transition name="fade" :duration="1000">
        <div
          class="sliderWrapper"
          v-if="currentStep === 2"
          v-show="!cookieModal"
        >
          <swiper
            :ref="dynamicRef"
            class="swiper"
            :options="swiperOption"
            @slideChange="slideChange"
          >
            <template v-for="(item, i) in popups">
              <swiper-slide :key="i">
                <div
                  class="infoSlider"
                  :class="{ expandWidth: item.type === 2 }"
                >
                  <a
                    @click="handleReset"
                    class="backToCarSelection"
                    v-if="item.id === 1 && !cardPreSelected"
                  >
                    <span class="backToCardArrow">
                      <!-- <img :src="require('@/assets/images/prevarrow.svg')"/> -->
                    </span>
                    <span>Kartenauswahl</span>
                  </a>
                  <div
                    class="singleIntroSlide"
                    :class="{
                      cookieSlide: item.type === 2,
                      chkSlide: item.type === 3
                    }"
                    v-if="item.content"
                  >
                    <div
                      class="introImage"
                      :class="item.id === 1 ? 'responsiveMargin' : ''"
                      v-if="item.content.images && item.type !== 3"
                    >
                      <img :src="responsiveImages(item.content.images)" />
                    </div>
                    <div
                      class="introImage imageCardHolder"
                      v-if="item.type === 3"
                    >
                      <img
                        :src="fullImage(selectedCard.image_desktop)"
                        width="159px"
                      />
                    </div>
                    <div
                      class="introHeader"
                      v-if="
                        item.content.popup_subtitle || item.content.popup_title
                      "
                    >
                      <span
                        v-if="item.content.popup_subtitle"
                        v-text="item.content.popup_subtitle"
                      ></span>
                      <h3
                        v-if="item.content.popup_title"
                        v-text="item.content.popup_title"
                      ></h3>
                      <!-- <span> - {{popups.length}}</span> -->
                    </div>
                    <div class="introContent" v-if="item.content.popup_content">
                      <p v-html="item.content.popup_content"></p>
                    </div>
                    <div class="introContent" v-if="item.type === 3">
                      <div class="introChecklist">
                        <div class="starterList">
                          <ul class="flex flex-column">
                            <li v-for="(item, i) in startListBoxes" :key="i">
                              <single-checklist
                                :item="item"
                                :checked="item.is_countable"
                                :large="false"
                                :title="false"
                                v-on:triggerCollapse="triggerCollapse"
                                :trigger="true"
                                :in-start-slide="true"
                                :rate="false"
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="introActions">
                      <button
                        class="button rounded block"
                        v-if="item.type === 2"
                        @click.stop.prevent="$event => acceptCookieModal()"
                      >
                        <span>Alle ablehnen</span>
                      </button>
                      <button
                        class="button rounded block"
                        v-if="item.type === 2"
                        @click.stop.prevent="changeCookieModal(true)"
                      >
                        <span>Mehr Optionen</span>
                      </button>
                      <button
                        class="button rounded block"
                        @click.stop="
                          item.type === 2
                            ? acceptAllCookies(item, i)
                            : changeSlide(item, i)
                        "
                      >
                        <span>{{
                          item.type === 2
                            ? "Alle akzeptieren"
                            : item.type === 3
                            ? "Jetzt starten"
                            : "Weiter"
                        }}</span>
                      </button>
                    </div>
                    <div
                      class="customDotsSlider"
                      :class="{ shown: customDotsView }"
                    >
                      <div class="swiper-pagination" slot="pagination"></div>
                    </div>
                  </div>
                </div>
              </swiper-slide>
            </template>
          </swiper>
        </div>
      </transition>
      <div
        class="overlay"
        v-if="
          (visibleCardHolder &&
            !selectedCard &&
            $store.state.ui.device !== 'lg') ||
            currentStep === 2
        "
      ></div>
    </div>
  </main>
</template>

<script>
import 'swiper/css/swiper.css'
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import initStore from '@/store/modules/init'
import userStore from '@/store/modules/user'
import uiStore from '@/store/modules/ui'
import checklistsStore from '@/store/modules/checklists'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import SingleChecklist from '@/components/single-checklist.vue'
import storage from '@/helpers/storage'

export default {
  data () {
    // let self = this
    return {
      loading: false,
      logo: require('@/assets/images/blue-box-tagline-consumer.svg'),
      cardType: 1,
      cards: [],
      selectedCard: null,
      currentStep: 1,
      currentSlideIndex: 0,
      customDotsView: true,
      dynamicRef: 'introductionSlider',
      swiperOption: {
        autoHeight: true,
        // effect: 'coverflow',
        // coverflowEffect: { rotate: 1 },
        speed: 1000,
        slidesPerView: 1,
        centeredSlides: true,
        spaceBetween: 50,
        // resistance: true,
        // resistanceRatio: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: false,
        loop: false,
        allowTouchMove: true,
        threshold: 50,
        observer: true,
        observeParent: true,
        noSwiping: true,
        breakpoints: {
          768: {
            autoHeight: true
          }
        }
      },
      visibleCardHolder: true,
      showFirstInfoBox: false,
      cardPreSelected: false,
      skipFirstStep: false,
      pageProps: null,
      popups: null,
      introList: [],
      cardAnimate: false,
      icons: {
        close: require('@/assets/images/x-icon-blue.svg')
      },
      startingPage: null
    }
  },
  metaInfo () {
    return {
      title: this.startingPage !== null ? this.startingPage.meta.title : '',
      description:
        this.startingPage !== null ? this.startingPage.meta.description : ''
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    // CookiePopup,
    SingleChecklist
  },
  mixins: [
    dynamicModule('user', userStore),
    dynamicModule('checklists', checklistsStore),
    dynamicModule('init', initStore),
    dynamicModule('ui', uiStore)
  ],
  computed: {
    ...mapState('user', ['user']),
    ...mapState('init', ['init']),
    ...mapState('ui', ['cookieModal', 'device']),
    cardTypes () {
      let self = this
      return this.cards.filter((item, i) => {
        return item.type === self.cardType
      })
    },
    startListBoxes () {
      // let self = this
      return this.introList.filter(item => {
        // self.$set(item, 'visible', false)
        // self.$set(item, 'done', false)
        return item
      })
    },
    returnLogo () {
      return require('@/assets/images/logo_tagline_' +
        (this.cardType === 1 ? 'consumer' : 'business') +
        '.svg')
    },
    swiper () {
      return this.$refs[this.dynamicRef]?.$swiper
    }
  },
  watch: {
    '$store.state.ui.device' (val) {
      this.visibleCardHolder = val === 'lg'
    },
    cookieModal (nv, ov) {
      // debugger
      if (!ov && nv) {
        this.swiper && this.swiper.slideNext()
        // const slngth = parseInt(this.popups.length - 2)
        // setTimeout(() => {
        //   this.changeSlide(this.popups[slngth], slngth)
        // }, 300)
      }
    }
  },
  methods: {
    ...mapActions('user', [
      'addUser',
      'addCard',
      'addCardType',
      'addCookies',
      'addIntro',
      'addSwitcher',
      'addLevel',
      'addSelectedCards',
      'addCookieConsent'
    ]),
    ...mapActions('checklists', ['startList']),
    ...mapActions('init', ['loadInit', 'loadCardPopups']),
    ...mapActions('ui', ['changeCookieModal']),
    handleReset () {
      this.selectedCard = null
      this.currentStep = 1
    },
    switchCardType (nr) {
      this.cardAnimate = false
      this.cardType = Number(nr)
      this.addCardType(parseInt(this.cardType))
      setTimeout(() => {
        this.cardAnimate = true
      }, 200)
    },
    setCard (item) {
      // let oldCard = this.user.card
      if (
        this.user.card?.id &&
        !this.user.selectedCards?.hasOwnProperty(this.user.card?.id) &&
        this.user.cookies
      ) {
        let selectedCards = this.user.selectedCards
        selectedCards = {
          ...selectedCards,
          ...{
            [`${this.user.card.id}`]: {
              card: this.user.card,
              level: this.user.currentLevel
            }
          }
        }

        this.addSelectedCards(selectedCards)
        // this.addSwitcher(true)
      }
      const crlvl = this.user.selectedCards[item.id]?.level
      const hasCard = this.user.selectedCards.hasOwnProperty(item.id)

      this.selectedCard = item
      this.addCard(this.selectedCard)
      this.addCardType(parseInt(this.selectedCard.type))
      this.addLevel(crlvl && crlvl !== null ? crlvl : 1)

      // if (localStorage.getItem('uc_performance')) {
      //   if (hasCard) {
      //     this.$gtm.trackEvent({
      //       event: 'card_changed',
      //       action: 'click',
      //       card: item.title,
      //       card_id: item.id,
      //       old_card: oldCard.title,
      //       old_card_id: oldCard.id,
      //       level_id: crlvl || 1
      //     })
      //   } else {
      //     this.$gtm.trackEvent({
      //       event: 'card_selected',
      //       action: 'click',
      //       card: item.title,
      //       card_id: item.id
      //     })
      //   }
      // }

      this.$nextTick(() => {
        if ((!hasCard && this.skipFirstStep && !this.user.cookies) || !this.user?.intro) {
          this.nextStep()
        } else if (
          (this.user.cookies ||
          this.user.switcher ||
          this.user.selectedCards.length > 1)
        ) {
          this.addSwitcher(false)
          this.addIntro(true)

          if (localStorage.getItem('redirect') !== null) {
            let redirect = localStorage.getItem('redirect')
            localStorage.removeItem('redirect')
            this.$router.push({ path: redirect })
          } else {
            this.$router.push({ name: 'Home' })
          }
        }
      })
    },
    nextStep () {
      this.loadCardPopups(this.selectedCard.id).then(resp => {
        this.currentStep = 2
        const sd = this.dynamicRef
        this.dynamicRef = sd + Math.random().toFixed(2) * 100
        if (resp) {
          this.popups = resp

          if (
            // parseInt(localStorage.getItem('obd_intro')) === 1 &&
            localStorage.getItem('uc_essential') &&
            localStorage.getItem('uc_functional') &&
            localStorage.getItem('uc_performance')
          ) {
            this.popups = this.popups.filter(item => parseInt(item.type) !== 2)
          }
        }
      })
    },
    changeSlide (item, i) {
      this.swiper.allowSlideNext = !this.cookieModal

      if (item.type === 3) {
        this.addIntro(true)
        storage.setItem('user', JSON.stringify(this.user))

        if (localStorage.getItem('uc_performance')) {
          // !this.hasGtm() && this.createGtm()
          // !this.$gtm.enabled() && this.$gtm.enable(true)
          // this.$gtm.trackEvent({
          //   event: 'time_event',
          //   action: 'click',
          //   timer_type: 'start_screen_slides',
          //   time: Date.now() - parseInt(localStorage.getItem('in_time_start_screen_slides'))
          // })

          localStorage.removeItem('in_time_start_screen_slides')
          localStorage.setItem('in_time_start_starter_checklist', Date.now())
        }

        if (localStorage.getItem('redirect') !== null) {
          let redirect = localStorage.getItem('redirect')
          localStorage.removeItem('redirect')
          this.$router.push({ path: redirect })
        } else {
          this.$router.push({ name: 'Home' })
        }
      } else if (item.type === 2) {
        this.addSwitcher(false)
        storage.setItem('user', JSON.stringify(this.user))
        this.swiper.slideNext()
      } else {
        if (this.popups[i + 2] === undefined) {
          this.getStartChecklist()
        }
        this.swiper.slideNext()
      }
    },
    getStartChecklist () {
      let self = this
      this.startList(this.showFirstInfoBox).then(res => {
        if (res) {
          this.introList = res
          setTimeout(() => {
            self.swiper.activeIndex = this.popups.length - 1
            self.swiper.update()
          }, 300)
        }
      })
    },
    visibleCardSwitch () {
      this.visibleCardHolder = !this.visibleCardHolder
      this.switchCardType(this.user?.cardType || 1)
    },
    validateData () {
      if (this.user.cookies) {
        // if (!this.user.switcher) {
        //   this.selectedCard = this.user.card
        //   this.nextStep()
        //   if (this.currentStep === 2) {
        //     this.getStartChecklist()
        //   }
        // } else {
        // }
        this.selectedCard = null
      }
    },
    fullImage (data) {
      return data !== null
        ? this.$store.state.init.init.config.mediaBaseUrl + data
        : ''
    },
    responsiveImages (data) {
      if (this.$store.state.ui.device === 'sm') {
        return data.image_mobile
          ? this.fullImage(data.image_mobile)
          : this.fullImage(data.image_desktop)
      } else if (
        this.$store.state.ui.device === 'md' ||
        this.$store.state.ui.device === 'tp'
      ) {
        return data.image_tablet
          ? this.fullImage(data.image_tablet)
          : this.fullImage(data.image_desktop)
      } else {
        return this.fullImage(data.image_desktop)
      }
    },
    findCardBySlug (slug) {
      let result = null

      this.cards.forEach(function (card) {
        if (card.slug === slug) {
          result = card
        }
      })

      return result
    },
    slideChange () {
      this.swiper.allowSlideNext = !this.cookieModal
      if (this.popups[this.swiper.realIndex].type === 2 && !this.user.cookies) {
        this.swiper.allowSlideNext = false
      }
    },
    triggerCollapse (id) {
      for (let i = 0; i < this.startListBoxes.length; i++) {
        if (this.startListBoxes[i].id !== id) {
          this.startListBoxes[i].visible = false
        }
      }
    },
    acceptAllCookies (item, i) {
      let self = this
      this.$userCentrics.acceptAllServices().then(function () {
        self.$userCentrics.getCategories()

        localStorage.setItem('uc_essential', true)
        localStorage.setItem('uc_functional', true)
        localStorage.setItem('uc_performance', true)
        self.$store.dispatch('user/addCookieConsent', true)
        self.$store.dispatch('user/addCookies', true)
        // !self.hasRecaptcha() && self.createRecaptcha()
        // !self.hasGtm() && self.createGtm()
        // !self.$gtm.enabled() && self.$gtm.enable(true)
        self.cookieModalClosed(true, item, i)
      })
    },
    acceptCookieModal () {
      localStorage.setItem('uc_essential', true)
      this.addCookies(true)

      // !this.hasRecaptcha() && this.createRecaptcha()

      // let _self = this

      // this.$store.dispatch('user/addCookieConsent', true)
      if (localStorage.getItem('uc_functional') === 'false') {
        // debugger
        this.$store.replaceState({})
        sessionStorage.clear()
        localStorage.clear()
        // setTimeout(() => {
        //   sessionStorage.clear()
        //   this.$store.replaceState({})
        //   localStorage.clear()
        //   // this.swiper.slideNext()
        //   // window.location.href = '/start/'
        //   // _self.$router.go()
        // }, 1000)
      }
      this.swiper.slideNext()
    },
    cookieModalClosed: function (value, item, i) {
      if (value) {
        this.addCookies(true)
        this.addCookieConsent(true)
        this.changeCookieModal(false).then(() => {
          this.swiper.allowSlideNext = true
          setTimeout(() => {
            this.changeSlide(item, i)
          }, 300)
        })
      }
    }
  },
  async created () {
    await this.loadInit().then(resp => {
      this.skipFirstStep = true

      this.cards = resp.cards
      this.pageProps = resp.startingPage.content_elements[0]
      this.startingPage = resp.startingPage
      if (this.$route.query.type !== undefined) {
        this.switchCardType(this.$route.query.type === 'private' ? 1 : 2)
      } else {
        this.switchCardType(1)
      }

      if (this.$route.query.card !== undefined) {
        // this.addSwitcher(true)
        this.cardPreSelected = true
        this.skipFirstStep = false
        if (
          this.$route.query.inbx !== undefined &&
          parseInt(this.$route.query.inbx) === 1
        ) {
          this.showFirstInfoBox = true
        }

        let card = this.findCardBySlug(this.$route.query.card)

        if (card !== null && Object.keys(card).length > 0) {
          this.setCard(card)
        }
      }
      this.loading = true
    })
  },
  mounted () {
    this.$nextTick(() => {
      const storageUser = storage.getUser()
      if (storageUser) {
        this.addUser(JSON.parse(storageUser))
      }
      this.validateData()
    })
  }
}
</script>

<style scoped>
@media screen and (max-width: 767px) {
  #app >>> div {
    padding-bottom: 0px;
  }

  .mainFooter {
    display: none;
  }
}
</style>
