export default (name, module) => {
  return {
    created: function () {
      const store = this.$store._modules.root._children[name]

      if (store === undefined) {
        this.$store.registerModule(name, module)
      }
    }
  }
}
