<template>
  <div v-if="pageHeaders" class="sectionTitle" data-aos="fade-left" data-aos-duration="1000">
    <h2 class="title" v-if="pageHeaders.title" v-text="pageHeaders.title"></h2>
    <p v-if="pageHeaders.description" v-text="pageHeaders.description"></p>
  </div>
</template>

<script>
export default {
  props: {
    pageHeaders: {
      default: null,
      required: true
    }
  }
}
</script>
