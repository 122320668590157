<template>
  <div class="accordion accordion-protection">
    <div v-for="(item, index) in items" :key="index" :id="'-acc-' + index"
         class="accordion-item" :class="{'is-active': item.active}">
      <div class="accordion-item-title">
        <button @click="item.active = !item.active" class="accordion-item-trigger">
          <h4 class="accordion-item-title-text" v-text="item.title"/>
          <span class="accordion-item-trigger-icon"></span>
        </button>
      </div>
      <transition
        name="accordion-item"
        @enter="startTransition"
        @after-enter="endTransition"
        @before-leave="startTransition"
        @after-leave="endTransition">
        <div v-if="item.active" class="accordion-item-details">
          <div v-html="item.content" class="accordion-item-details-inner"></div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    items: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      list: []
    }
  },
  created () {
    this.items.forEach((item, i) => {
      this.$set(this.items, i, { ...item, active: false })
    })
  },
  methods: {
    startTransition (el) {
      el.style.height = el.scrollHeight + 'px'
    },
    endTransition (el) {
      el.style.height = ''
    }
  }
}
</script>
<style scoped lang="css">
.accordion-item-details-inner{
    line-height: 1.5 !important;
}

</style>
<style scoped lang="scss">

.accordion {
  padding: 0;
  border: 1px solid rgba(10, 10, 10, 0.1);

  div:not(:last-child) {
    border-bottom: 1px solid rgba(10, 10, 10, 0.1);
  }

  div:last-child .accordion-item-details {
    border-radius: 5px;
  }

  dd {
    margin-left: 0;
  }
}

.accordion-item-trigger,
.accordion-item-details-inner {
  padding: 0.75rem 1.25rem;
   p {
    // margin-bottom: 20px !important;
    font-size: 1.15rem;
    color: #53565a !important;
  }
}

.accordion-item-title {
  position: relative;

  h4 {
    font-size: 1.25rem;
    margin-bottom: 0;
    padding-right: 1.25rem;
    color: #53565a;
  }
}

.accordion-item-trigger {
  width: 100%;
  text-align: left;
  background-color: #fefefe;
  border: none;
  cursor: pointer;
}

.accordion-item-trigger-icon {
  $size: 8px;
  display: block;
  position: absolute;
  top: 0;
  right: 1.25rem;
  bottom: 0;
  margin: auto;
  width: $size;
  height: $size;
  border-right: 2px solid #363636;
  border-bottom: 2px solid #363636;
  transform: translateY(-$size / 4) rotate(45deg);
  transition: transform 0.2s ease;

  .is-active & {
    transform: translateY($size / 4) rotate(225deg);
  }
}
.accordion-item-details {
  overflow: hidden;
  background-color: #fefefe;
}

.accordion-item-details-inner ul li{
  list-style:disc;
}

.accordion-item-enter-active, .accordion-item-leave-active {
  will-change: height;
  transition: height 0.2s ease;
}

.accordion-item-enter, .accordion-item-leave-to {
  height: 0 !important;
}
</style>
