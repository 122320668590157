<template>
  <div v-if="homepage" class="startingPoint">
    <div
      class="mainSection"
      :class="{
        homeBlueBanner:
          $route.name === 'Home' &&
          homepage &&
          homepage.home &&
          homepage.home.images.dark,
      }"
    >
      <transition name="fade">
        <div
          class="imageBanner"
          v-if="imageChanged"
          :style="{ 'background-image': 'url(' + bgImage + ')' }"
        ></div>
      </transition>
      <div
        class="mainSectionContent"
        :class="{
          activeSearch: user.currentLevel !== 1,
          secondLevel: user.currentLevel === 2,
          userDrop: dropdownActive,
          starterOpened: overlay,
        }"
      >
        <div class="grid-container">
          <div
            class="expertSearch"
            v-if="user.currentLevel !== 1 && $store.state.ui.device === 'sm'"
          >
            <div class="rowInput">
              <div class="inputBox inputIcon rounded left transparent large">
                <input
                  type="text"
                  v-model="searchQuery"
                  @keypress.enter="handleSearch(searchQuery)"
                  placeholder="Suchbegriff eingeben …"
                />
                <button
                  class="icon cursor-pointer"
                  @click.stop="handleSearch(searchQuery)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="22px"
                    height="22px"
                  >
                    <g>
                      <g>
                        <path
                          id="Fill_1"
                          data-name="Fill 1"
                          d="M21.214,22a.784.784,0,0,1-.556-.229l-6.487-6.489a8.646,8.646,0,1,1,1.111-1.111l6.487,6.488A.785.785,0,0,1,21.214,22ZM8.643,1.571a7.072,7.072,0,0,0-5,12.072,7.072,7.072,0,0,0,10-10A7.025,7.025,0,0,0,8.643,1.571Z"
                          transform="translate(0 0)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </svg>
                </button>
                <span
                  class="clearInput"
                  v-if="searchQuery.length > 0"
                  @click.stop="handleClear"
                >
                  <img :src="icons.close" alt=""/>
                </span>
                <div class="searchDropdown" v-if="dropResult">
                  <ul>
                    <template v-for="(item, i) in suggests">
                      <li :key="'ll' + i">
                        <a @click="handleSearch(item)">
                          <span v-text="item"></span>
                        </a>
                      </li>
                    </template>
                    <li
                      v-if="searchQuery.trim(' ') !== '' && suggests.length < 1"
                    >
                      <span v-text="'No results'"></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            class="badgePart dropdown active"
            data-aos="fade-up"
            data-aos-duration="1000"
            tabindex="-1"
            @blur="dropdownActive = false"
          >
            <div class="badgeIcon" @click="dropdownActive = !dropdownActive">
              <!-- <img :src="badgeIcon" /> -->
              <template v-if="user.currentLevel === 1">
                <i>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="35"
                    viewBox="0 0 36 35"
                  >
                    <path
                      id="starter_1"
                      data-name="Starter"
                      d="M33.566,35H2.432A2.442,2.442,0,0,1,0,32.813a6.373,6.373,0,0,1,3.6-5.7c3.942-2.328,7.751-4.375,7.79-4.4.617-.311.976-1.813,1.192-2.915.029-.11.051-.185.07-.252.036-.125.062-.215.109-.439-2.146-1.784-2.925-4.645-3.2-6.73a18.774,18.774,0,0,1-.083-4.14C9.873.4,16.45,0,17.764,0c.12,0,.2,0,.235.005S18.12,0,18.242,0c1.313,0,7.883.4,8.275,8.246a18.767,18.767,0,0,1-.083,4.14c-.275,2.086-1.053,4.946-3.2,6.73a3.666,3.666,0,0,0,.108.534c.01.038.02.076.031.117.232,1.181.634,2.653,1.233,2.955.038.02,3.839,2.062,7.79,4.4A6.311,6.311,0,0,1,36,32.8,2.439,2.439,0,0,1,33.566,35ZM17.764,2.561c-4.894,0-5.626,4.065-5.713,5.812l0,.085-.01.085c-.189,1.687-.13,6.523,2.376,8.607l1.183.982-.311,1.5c-.067.322-.1.452-.156.631-.012.038-.023.075-.035.122-.441,2.21-1.035,3.838-2.5,4.6-.037.02-3.763,2.021-7.68,4.333C3.2,30.33,2.79,31.2,2.623,32.439H33.381c-.163-1.276-.561-2.108-2.283-3.11-3.921-2.315-7.659-4.321-7.695-4.341-1.26-.658-2.02-2.049-2.54-4.653l-.01-.039a6.1,6.1,0,0,1-.169-.846l-.185-1.4,1.087-.9c2.506-2.084,2.564-6.925,2.375-8.614l-.009-.077,0-.085c-.252-5.057-3.683-5.812-5.705-5.812h-.107L18,2.569l-.141-.007Z"
                      transform="translate(0)"
                      fill="#006fcf"
                    />
                  </svg>
                </i>
                <span class="levelName">Starter</span>
              </template>
              <template v-else-if="user.currentLevel === 2">
                <i>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36.002"
                    height="35"
                    viewBox="0 0 36.002 35"
                  >
                    <path
                      id="explorer_!"
                      data-name="Explorer 1"
                      d="M7.788,35h0A2.3,2.3,0,0,1,6.5,34.6a2.3,2.3,0,0,1-.828-2.173L7.4,21.8.553,14.274A2.417,2.417,0,0,1,.1,12.023a2.26,2.26,0,0,1,1.731-1.472L11.617,9l4.591-7.912A2.164,2.164,0,0,1,18.014,0,2.32,2.32,0,0,1,19.9,1.087L24.412,9l9.709,1.552a2.162,2.162,0,0,1,1.732,1.472,1.987,1.987,0,0,1-.527,2.251L28.552,21.8l1.806,10.626A2.133,2.133,0,0,1,29.53,34.6a2.481,2.481,0,0,1-1.28.388,2.277,2.277,0,0,1-.978-.233l-9.257-4.964L8.757,34.752A1.95,1.95,0,0,1,7.788,35ZM18.014,2.483l-5.043,8.609L2.36,12.8l7.526,8.3L7.929,32.657l10.085-5.508,10.161,5.43L26.218,21.022l7.376-8.145L23.057,11.171,18.014,2.483Z"
                      transform="translate(0.001)"
                      fill="#006fcf"
                    />
                  </svg>
                </i>
                <span class="levelName">Explorer</span>
              </template>
              <template v-else-if="user.currentLevel === 3">
                <i>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35.003"
                    height="35"
                    viewBox="0 0 35.003 35"
                  >
                    <path
                      id="expert_1"
                      data-name="Expert 1"
                      d="M50,18a13.7,13.7,0,0,1,11.69,20.836l5.551,6.635a1.112,1.112,0,0,1-.793,1.82l-4.962.349-1.053,4.495a1.142,1.142,0,0,1-1.981.455L52.314,45.2a13.815,13.815,0,0,1-4.626,0l-6.14,7.393a1.141,1.141,0,0,1-1.953-.357l-.027-.1L38.514,47.64l-4.961-.349a1.113,1.113,0,0,1-.854-1.742l.061-.08,5.55-6.634A13.7,13.7,0,0,1,50,18ZM39.684,40.7l-3.78,4.52,3.607.253a1.128,1.128,0,0,1,.993.761l.029.1.722,3.084,4.037-4.86A13.728,13.728,0,0,1,39.683,40.7h0ZM54.712,44.56l4.036,4.86.723-3.083a1.128,1.128,0,0,1,1.023-.862l3.607-.253-3.78-4.52a13.725,13.725,0,0,1-5.61,3.859h0ZM50,20.283A11.413,11.413,0,1,0,61.415,31.7,11.413,11.413,0,0,0,50,20.283Zm.872,3.584,2.06,4.163,4.6.667a.97.97,0,0,1,.538,1.655l-3.332,3.241.786,4.576a.974.974,0,0,1-1.41,1.023L50,37.03l-4.119,2.161a.974.974,0,0,1-1.411-1.023l.787-4.576-3.332-3.241a.969.969,0,0,1,.538-1.654l4.6-.668,2.06-4.163a.973.973,0,0,1,1.743,0h0ZM50,26.37l-1.646,3.256-3.68.522,2.663,2.534-.629,3.58L50,34.572l3.292,1.69-.629-3.579,2.663-2.534-3.68-.522L50,26.37Z"
                      transform="translate(-32.5 -18)"
                      fill="#006fcf"
                    />
                  </svg>
                </i>
                <span class="levelName">Expert</span>
              </template>
            </div>
            <div class="badgeText" @click="dropdownActive = !dropdownActive">
              User level
            </div>
            <div class="dropdown-content center" v-if="dropdownActive">
              <div class="dropdown-inner-content">
                <span @click="dropdownActive = false">
                  <img :src="icons.close" width="100%"/>
                </span>
                <h2
                  v-html="homepage.home.level_info.l_title"
                  data-aos="fade-up"
                  data-aos-duration="500"
                ></h2>
                <p
                  class="dropdownInnerDesc"
                  v-text="homepage.home.level_info.l_description"
                  data-aos="fade-up"
                  data-aos-duration="500"
                ></p>
                <ul>
                  <li>
                    <div
                      class="badgeIcon small transparent no-shadow bordered"
                      data-aos="fade-up"
                      data-aos-duration="500"
                      :class="{ inactive: user.currentLevel !== 1 }"
                      @click="handleChangeLevel(1, false)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="35"
                        viewBox="0 0 36 35"
                      >
                        <path
                          id="starter_1"
                          data-name="Starter"
                          d="M33.566,35H2.432A2.442,2.442,0,0,1,0,32.813a6.373,6.373,0,0,1,3.6-5.7c3.942-2.328,7.751-4.375,7.79-4.4.617-.311.976-1.813,1.192-2.915.029-.11.051-.185.07-.252.036-.125.062-.215.109-.439-2.146-1.784-2.925-4.645-3.2-6.73a18.774,18.774,0,0,1-.083-4.14C9.873.4,16.45,0,17.764,0c.12,0,.2,0,.235.005S18.12,0,18.242,0c1.313,0,7.883.4,8.275,8.246a18.767,18.767,0,0,1-.083,4.14c-.275,2.086-1.053,4.946-3.2,6.73a3.666,3.666,0,0,0,.108.534c.01.038.02.076.031.117.232,1.181.634,2.653,1.233,2.955.038.02,3.839,2.062,7.79,4.4A6.311,6.311,0,0,1,36,32.8,2.439,2.439,0,0,1,33.566,35ZM17.764,2.561c-4.894,0-5.626,4.065-5.713,5.812l0,.085-.01.085c-.189,1.687-.13,6.523,2.376,8.607l1.183.982-.311,1.5c-.067.322-.1.452-.156.631-.012.038-.023.075-.035.122-.441,2.21-1.035,3.838-2.5,4.6-.037.02-3.763,2.021-7.68,4.333C3.2,30.33,2.79,31.2,2.623,32.439H33.381c-.163-1.276-.561-2.108-2.283-3.11-3.921-2.315-7.659-4.321-7.695-4.341-1.26-.658-2.02-2.049-2.54-4.653l-.01-.039a6.1,6.1,0,0,1-.169-.846l-.185-1.4,1.087-.9c2.506-2.084,2.564-6.925,2.375-8.614l-.009-.077,0-.085c-.252-5.057-3.683-5.812-5.705-5.812h-.107L18,2.569l-.141-.007Z"
                          transform="translate(0)"
                          fill="#006fcf"
                        />
                      </svg>
                      <span class="levelName">Starter</span>
                    </div>
                  </li>
                  <li>
                    <div
                      class="badgeIcon small transparent no-shadow bordered"
                      data-aos="fade-up"
                      data-aos-duration="500"
                      :class="{ inactive: user.currentLevel !== 2 }"
                      @click="handleChangeLevel(2, false)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36.002"
                        height="35"
                        viewBox="0 0 36.002 35"
                      >
                        <path
                          id="explorer_1"
                          data-name="Explorer 1"
                          d="M7.788,35h0A2.3,2.3,0,0,1,6.5,34.6a2.3,2.3,0,0,1-.828-2.173L7.4,21.8.553,14.274A2.417,2.417,0,0,1,.1,12.023a2.26,2.26,0,0,1,1.731-1.472L11.617,9l4.591-7.912A2.164,2.164,0,0,1,18.014,0,2.32,2.32,0,0,1,19.9,1.087L24.412,9l9.709,1.552a2.162,2.162,0,0,1,1.732,1.472,1.987,1.987,0,0,1-.527,2.251L28.552,21.8l1.806,10.626A2.133,2.133,0,0,1,29.53,34.6a2.481,2.481,0,0,1-1.28.388,2.277,2.277,0,0,1-.978-.233l-9.257-4.964L8.757,34.752A1.95,1.95,0,0,1,7.788,35ZM18.014,2.483l-5.043,8.609L2.36,12.8l7.526,8.3L7.929,32.657l10.085-5.508,10.161,5.43L26.218,21.022l7.376-8.145L23.057,11.171,18.014,2.483Z"
                          transform="translate(0.001)"
                          fill="#006fcf"
                        />
                      </svg>
                      <span class="levelName">Explorer</span>
                    </div>
                  </li>
                  <li>
                    <div
                      class="badgeIcon small transparent no-shadow bordered"
                      data-aos="fade-up"
                      data-aos-duration="500"
                      :class="{ inactive: user.currentLevel !== 3 }"
                      @click="handleChangeLevel(3, false)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="35.003"
                        height="35"
                        viewBox="0 0 35.003 35"
                      >
                        <path
                          id="expert_1"
                          data-name="Expert 1"
                          d="M50,18a13.7,13.7,0,0,1,11.69,20.836l5.551,6.635a1.112,1.112,0,0,1-.793,1.82l-4.962.349-1.053,4.495a1.142,1.142,0,0,1-1.981.455L52.314,45.2a13.815,13.815,0,0,1-4.626,0l-6.14,7.393a1.141,1.141,0,0,1-1.953-.357l-.027-.1L38.514,47.64l-4.961-.349a1.113,1.113,0,0,1-.854-1.742l.061-.08,5.55-6.634A13.7,13.7,0,0,1,50,18ZM39.684,40.7l-3.78,4.52,3.607.253a1.128,1.128,0,0,1,.993.761l.029.1.722,3.084,4.037-4.86A13.728,13.728,0,0,1,39.683,40.7h0ZM54.712,44.56l4.036,4.86.723-3.083a1.128,1.128,0,0,1,1.023-.862l3.607-.253-3.78-4.52a13.725,13.725,0,0,1-5.61,3.859h0ZM50,20.283A11.413,11.413,0,1,0,61.415,31.7,11.413,11.413,0,0,0,50,20.283Zm.872,3.584,2.06,4.163,4.6.667a.97.97,0,0,1,.538,1.655l-3.332,3.241.786,4.576a.974.974,0,0,1-1.41,1.023L50,37.03l-4.119,2.161a.974.974,0,0,1-1.411-1.023l.787-4.576-3.332-3.241a.969.969,0,0,1,.538-1.654l4.6-.668,2.06-4.163a.973.973,0,0,1,1.743,0h0ZM50,26.37l-1.646,3.256-3.68.522,2.663,2.534-.629,3.58L50,34.572l3.292,1.69-.629-3.579,2.663-2.534-3.68-.522L50,26.37Z"
                          transform="translate(-32.5 -18)"
                          fill="#006fcf"
                        />
                      </svg>
                      <span class="levelName">Expert</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="textPart" data-aos="fade-in" data-aos-duration="1000">
            <h3
              v-if="homepage.home.subtitle"
              v-text="homepage.home.subtitle"
            ></h3>
            <h2 v-if="homepage.home.title" v-text="homepage.home.title"></h2>
            <div
              class="expertSearch"
              v-if="user.currentLevel !== 1 && $store.state.ui.device !== 'sm'"
            >
              <div class="rowInput">
                <div class="inputBox inputIcon rounded left transparent large">
                  <input
                    type="text"
                    v-model="searchQuery"
                    @keypress.enter="handleSearch(searchQuery)"
                    placeholder="Suchbegriff eingeben …"
                  />
                  <button
                    class="icon cursor-pointer"
                    @click.stop="handleSearch(searchQuery)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="22px"
                      height="22px"
                    >
                      <g>
                        <g>
                          <path
                            id="Fill_2"
                            data-name="Fill 2"
                            d="M21.214,22a.784.784,0,0,1-.556-.229l-6.487-6.489a8.646,8.646,0,1,1,1.111-1.111l6.487,6.488A.785.785,0,0,1,21.214,22ZM8.643,1.571a7.072,7.072,0,0,0-5,12.072,7.072,7.072,0,0,0,10-10A7.025,7.025,0,0,0,8.643,1.571Z"
                            transform="translate(0 0)"
                            fill="#fff"
                          />
                        </g>
                      </g>
                    </svg>
                  </button>
                  <span
                    class="clearInput"
                    v-if="searchQuery.length > 0"
                    @click.stop="handleClear"
                  >
                    <img :src="icons.close" alt=""/>
                  </span>
                  <div class="searchDropdown" v-if="dropResult">
                    <ul>
                      <template v-for="(item, i) in suggests">
                        <li :key="'ll' + i">
                          <a @click="handleSearch(item)">
                            <span v-text="item"></span>
                          </a>
                        </li>
                      </template>
                      <li
                        v-if="
                          searchQuery.trim(' ') !== '' && suggests.length < 1
                        "
                      >
                        <span v-text="'No results'"></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="checklistHolder"
      @click.self="blurMethod"
      :class="{
        starterOpened: overlay,
        secondLevel: user.currentLevel === 2,
        lastLevel: user.currentLevel === 3,
        lastLevelBox: user.currentLevel === 3 && expertInfoBox,
      }"
    >
      <div class="grid-container" @click.self="blurMethod">
        <div class="checklistPart" @click.self="blurMethod">
          <div>
            <div
              class="percentageCircle"
              @click.prevent="handleStarterListToggle()"
            >
              <div>
                <radial-progress-bar
                  :diameter="progressBar.diameter"
                  :completed-steps="progressBar.completedSteps"
                  :total-steps="progressBar.totalSteps"
                  startColor="#006FCF"
                  stopColor="#006FCF"
                  innerStrokeColor="transparent"
                  :innerStrokeWidth="progressBar.innerStrokeWidth"
                  :strokeWidth="progressBar.strokeWidth"
                >
                  <span class="progress"
                  >{{ Math.round(progressBar.completedSteps) }}%</span
                  >
                  <span class="done">erledigt</span>
                </radial-progress-bar>
              </div>
            </div>
            <div
              class="checklistStarter"
              tabindex="-1"
              ref="starterListRef"
              id="starterListID"
            >
              <div class="contentBox platinum-box" v-if="(user.currentLevel === 3 && teasers)">
                <div class="platinum-box__wrapper">
                  <h2 v-text="teasers.name"></h2>
                  <p v-text="teasers.description"></p>
                  <a v-if="teasers.button_link" class="button rounded block" :href="teasers.button_link" v-text="teasers.button_name"></a>
                </div>
              </div>

              <div class="contentBox default"
                   v-else-if="user.currentLevel !== 3 && homepage && homepage.home && homepage.home.blue_part">
                <div>
                  <h2 v-text="homepage.home.blue_part.title"></h2>
                  <p v-html="homepage.home.blue_part.subtitle"></p>
                  <button class="button rounded block" @click.prevent="handleStarterListToggle()">
                    <span v-text="starterList ? 'Checkliste verbergen' : 'Checkliste öffnen'"></span>
                  </button>
                </div>
              </div>

              <div class="contentBox" v-else-if="user.currentLevel === 3 && currentCategory">
                <span class="iconRight">
                  <button
                    class="iconButton small"
                    type="button"
                    @click.stop="saveCategory('c', currentCategory)"
                  >
                    <img :src="icons.arrowRight" width="100%"/>
                  </button>
                </span>
                <div>
                  <h2
                    v-if="currentCategory"
                    v-text="currentCategory.title"
                  ></h2>
                  <p
                    v-if="currentCategory"
                    v-text="currentCategory.description"
                  ></p>
                  <button
                    class="button rounded block"
                    @click.stop="saveCategory('s', currentCategory)"
                  >
                    Mehr dazu
                  </button>
                </div>
              </div>

              <div class="extraTools" v-if="user.currentLevel === 3"
                   data-aos="fade-up"
                   data-aos-duration="800">
                <div
                  class="bonusPoints"
                  :key="'et-' + bpKey"
                  v-if="focusBox"
                >
                  <single-checklist
                    :item="focusBox"
                    :large="false"
                    :rate="false"
                    type="blue"
                    :checked="false"
                  ></single-checklist>
                </div>
                <div
                  class="expertList"
                >
                  <div class="expertChecklist" v-if="expertInfoBox">
                    <single-checklist
                      :item="expertInfoBox"
                      :large="true"
                      :checked="expertInfoBox.is_countable"
                    ></single-checklist>
                  </div>
                </div>
              </div>
              <transition name="slide">
                <div class="starterList" v-if="starterList">
                  <ul
                    class="flex flex-column"
                    data-aos="fade-up"
                    data-aos-duration="800"
                    data-aos-offset="10"
                  >
                    <template v-for="(item, i) in homepage.checklistBoxes">
                      <transition name="slide" :key="'first' + i">
                        <li
                          v-if="
                            i === 0 && popups.checklist && !user.firstInfobox
                          "
                        >
                          <div
                            class="cookieBox"
                            :class="[
                              $store.state.ui.device === 'sm'
                                ? 'mobileStarterlist'
                                : '',
                            ]"
                          >
                            <div
                              v-if="
                                popups.checklist &&
                                responsiveImages(popups.checklist) &&
                                $store.state.ui.device !== 'sm'
                              "
                            >
                              <img
                                :src="responsiveImages(popups.checklist)"
                                alt="American Express"
                              />
                            </div>
                            <div>
                              <h4
                                v-if="popups.checklist.title"
                                v-html="popups.checklist.title"
                              ></h4>
                              <div
                                v-if="popups.checklist.content"
                                v-html="popups.checklist.content"
                              ></div>
                              <button
                                v-if="$store.state.ui.device !== 'sm'"
                                class="button rounded inverse medium"
                                @click="triggerChecklistModal"
                              >
                                <span
                                  v-text="
                                    popups.checklist.button_title ||
                                    'Los geht\'s'
                                  "
                                ></span>
                              </button>
                            </div>
                            <span class="iconRight">
                              <button
                                class="iconButton small"
                                type="button"
                                @click="triggerChecklistModal"
                              >
                                <img :src="icons.close" width="100%"/>
                              </button>
                            </span>
                          </div>
                        </li>
                      </transition>
                      <li :key="i">
                        <single-checklist
                          class="shortTitle"
                          :item="item"
                          :inChecklist="true"
                          :large="false"
                          v-on:triggerCollapse="triggerCollapse"
                          :trigger="true"
                          :title="false"
                          :checked="item.is_countable"
                        >
                        </single-checklist>
                      </li>
                    </template>
                  </ul>
                  <div class="starterListCollapse">
                    <button
                      class="button rounded"
                      @click.stop="handleStarterListToggle()"
                    >
                      Checkliste verbergen
                    </button>
                  </div>
                </div>
              </transition>
              <div
                class="checklistExpand"
                v-if="user.currentLevel !== 3"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <button @click.stop="handleStarterListToggle()">
                  <!-- {{ starterList ? 'Checkliste verbergen' : (user.currentLevel === 1 ? 'Starter' : user.currentLevel === 2 ?
                  'Explorer' : 'Expert') + ' Checkliste' }} -->
                  {{
                    starterList ? "Checkliste verbergen" : "Checkliste öffnen"
                  }}
                  <div class="spacer"></div>
                  <span>
                    <svg
                      v-if="!starterList"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <g fill="none" fill-rule="evenodd">
                        <g>
                          <path
                            fill="#ffffff"
                            d="M541.563 852.063c.517 0 .937.419.937.937s-.42.938-.938.938h-5.625v5.625c0 .517-.42.937-.937.937-.518 0-.938-.42-.938-.938v-5.625h-5.625c-.518 0-.937-.419-.937-.937s.42-.938.938-.938h5.625v-5.625c0-.518.42-.937.937-.937.518 0 .938.42.938.938v5.625h5.625zM535 866.125c7.237 0 13.125-5.888 13.125-13.125s-5.888-13.125-13.125-13.125-13.125 5.888-13.125 13.125 5.888 13.125 13.125 13.125zM535 838c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15z"
                            transform="translate(-520 -838)"
                          />
                        </g>
                      </g>
                    </svg>
                    <svg
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                    >
                      <g fill="none" fill-rule="evenodd">
                        <g>
                          <path
                            fill="#ffffff"
                            d="M611.563 852.063c.517 0 .937.419.937.937s-.42.938-.938.938h-13.125c-.518 0-.937-.42-.937-.938s.42-.938.938-.938h13.125zM605 866.125c7.237 0 13.125-5.888 13.125-13.125s-5.888-13.125-13.125-13.125-13.125 5.888-13.125 13.125 5.888 13.125 13.125 13.125zM605 838c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15z"
                            transform="translate(-590 -838)"
                          />
                        </g>
                      </g>
                    </svg>
                  </span>
                </button>
              </div>
              <!-- <div class="extraBonusPoints" v-if="user.currentLevel !== 3 && focusBox">
                <div class="bonusPoints" data-aos="fade-up" data-aos-duration="800" v-if="focusBox">
                  <single-checklist :item="focusBox" :large="false" :rate="false" type="blue" :checked="false"></single-checklist>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modalContainer levelUpDialog" v-if="levelUpDialog">
      <div class="modalPopup">
        <div id="canvasWrapper"></div>
        <div class="popUpImage flex justify-center">
          <div class="badgeIcon">
            <template v-if="user.currentLevel === 2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36.002"
                height="35"
                viewBox="0 0 36.002 35"
              >
                <path
                  id="explorer_1"
                  data-name="Explorer 1"
                  d="M7.788,35h0A2.3,2.3,0,0,1,6.5,34.6a2.3,2.3,0,0,1-.828-2.173L7.4,21.8.553,14.274A2.417,2.417,0,0,1,.1,12.023a2.26,2.26,0,0,1,1.731-1.472L11.617,9l4.591-7.912A2.164,2.164,0,0,1,18.014,0,2.32,2.32,0,0,1,19.9,1.087L24.412,9l9.709,1.552a2.162,2.162,0,0,1,1.732,1.472,1.987,1.987,0,0,1-.527,2.251L28.552,21.8l1.806,10.626A2.133,2.133,0,0,1,29.53,34.6a2.481,2.481,0,0,1-1.28.388,2.277,2.277,0,0,1-.978-.233l-9.257-4.964L8.757,34.752A1.95,1.95,0,0,1,7.788,35ZM18.014,2.483l-5.043,8.609L2.36,12.8l7.526,8.3L7.929,32.657l10.085-5.508,10.161,5.43L26.218,21.022l7.376-8.145L23.057,11.171,18.014,2.483Z"
                  transform="translate(0.001)"
                  fill="#006fcf"
                />
              </svg>
              <span class="levelName">Explorer</span>
            </template>
            <template v-else-if="user.currentLevel === 3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="35.003"
                height="35"
                viewBox="0 0 35.003 35"
              >
                <path
                  id="expert_1"
                  data-name="Expert 1"
                  d="M50,18a13.7,13.7,0,0,1,11.69,20.836l5.551,6.635a1.112,1.112,0,0,1-.793,1.82l-4.962.349-1.053,4.495a1.142,1.142,0,0,1-1.981.455L52.314,45.2a13.815,13.815,0,0,1-4.626,0l-6.14,7.393a1.141,1.141,0,0,1-1.953-.357l-.027-.1L38.514,47.64l-4.961-.349a1.113,1.113,0,0,1-.854-1.742l.061-.08,5.55-6.634A13.7,13.7,0,0,1,50,18ZM39.684,40.7l-3.78,4.52,3.607.253a1.128,1.128,0,0,1,.993.761l.029.1.722,3.084,4.037-4.86A13.728,13.728,0,0,1,39.683,40.7h0ZM54.712,44.56l4.036,4.86.723-3.083a1.128,1.128,0,0,1,1.023-.862l3.607-.253-3.78-4.52a13.725,13.725,0,0,1-5.61,3.859h0ZM50,20.283A11.413,11.413,0,1,0,61.415,31.7,11.413,11.413,0,0,0,50,20.283Zm.872,3.584,2.06,4.163,4.6.667a.97.97,0,0,1,.538,1.655l-3.332,3.241.786,4.576a.974.974,0,0,1-1.41,1.023L50,37.03l-4.119,2.161a.974.974,0,0,1-1.411-1.023l.787-4.576-3.332-3.241a.969.969,0,0,1,.538-1.654l4.6-.668,2.06-4.163a.973.973,0,0,1,1.743,0h0ZM50,26.37l-1.646,3.256-3.68.522,2.663,2.534-.629,3.58L50,34.572l3.292,1.69-.629-3.579,2.663-2.534-3.68-.522L50,26.37Z"
                  transform="translate(-32.5 -18)"
                  fill="#006fcf"
                />
              </svg>
              <span class="levelName">Expert</span>
            </template>
          </div>
          <span class="iconRight">
            <button
              class="iconButton small"
              type="button"
              @click.stop="handleModal()"
            >
              <img :src="icons.close" width="100%"/>
            </button>
          </span>
        </div>
        <div class="popupHeader">
          <h2 v-text="levelContent.popup_info.title"></h2>
        </div>
        <div
          class="popupContent text-center"
          v-html="levelContent.popup_info.info"
        ></div>
        <div class="popupActions flex justify-center">
          <button class="button rounded block" @click.stop="handleModal()">
            <span>Jetzt starten</span>
          </button>
        </div>
      </div>
    </div>
    <div
      class="overlay"
      v-if="starterList && $store.state.ui.device !== 'sm'"
      @click="blurMethod"
    ></div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import userStore from '@/store/modules/user'
import cardsStore from '@/store/modules/cards'
import checklistsStore from '@/store/modules/checklists'
import searchStore from '@/store/modules/search'
import SingleChecklist from '@/components/single-checklist.vue'
import RadialProgressBar from 'vue-radial-progress'
import Confetti from 'canvas-confetti'

export default {
  // is: 'starting-point',
  props: {
    homepage: {
      type: Object,
      required: true
    },
    teasers: {
      type: Object,
      default: null
    }
  },
  mixins: [
    dynamicModule('user', userStore),
    dynamicModule('cards', cardsStore),
    dynamicModule('checklists', checklistsStore),
    dynamicModule('search', searchStore)
  ],
  data () {
    return {
      overlay: false,
      starterList: false,
      dropdownActive: false,
      progressLevel: 1,
      searchQuery: '',
      boxesCount: [],
      progressBar: {
        completedSteps: 0,
        totalSteps: 100,
        diameter: 220,
        strokeWidth: 15,
        innerStrokeWidth: 8,
        animateSpeed: 50
      },
      icons: {
        close: require('@/assets/images/x-icon-blue.svg'),
        closeWhite: require('@/assets/images/x-icon-white.svg'),
        arrowRight: require('@/assets/images/icon--rechts.svg')
      },
      searchList: [],
      levelUpDialog: false,
      stepAnimation: true,
      counter: false,
      percentageNumber: 0,
      closing: false,
      dropResult: false,
      currentCategory: null,
      levelContent: null,
      expertInfoBox: null,
      imageChanged: true,
      focusBox: null,
      bpKey: 0
    }
  },
  components: {
    SingleChecklist,
    RadialProgressBar
  },
  watch: {
    cyclePercentage (nv, ov) {
      this.calcPercentage()
    },
    '$store.state.ui.device' (val) {
      this.progressbarResize()
    },
    percentageNumber (val) {
      let formatVal = isNaN(val) ? 0 : val
      let self = this
      clearInterval(self.counter)
      if (formatVal === self.progressBar.completedSteps) {
        return
      }
      self.counter = setInterval(function () {
        if (
          Math.floor(self.progressBar.completedSteps) !== Math.floor(formatVal)
        ) {
          let change = (formatVal - self.progressBar.completedSteps) / 5
          change = change >= 0 ? Math.ceil(change) : Math.floor(change)
          self.progressBar.completedSteps =
            self.progressBar.completedSteps + change
        } else {
          self.progressBar.completedSteps = formatVal
          clearInterval(self.counter)
          if (self.progressBar.completedSteps > 99 && !self.finishedLevels() && self.user.currentLevel < 4) {
            self.currentModal()
            self.starterList = false
            self.handleChangeLevel(
              self.user.currentLevel < 3 ? self.user.currentLevel + 1 : 3,
              true
            )
          } else if (
            self.progressBar.completedSteps < 100 &&
            self.user?.levels[self.user.currentLevel]?.hasOwnProperty(
              self.user.card?.id
            )
          ) {
            self.user.levels[self.user.currentLevel][self.user.card.id] = false
            self.addLevels(self.user.levels)
          }
          // else if (self.progressBar.completedSteps === 100 && !self.finishedLevels()) {
          //   self.currentModal()
          //   self.handleModal()
          // }
        }
      }, 150)
    },
    starterList (val) {
      if (val) {
        let el = document.querySelector('#starterListID')
        el.focus()
        this.overlay = val
      } else {
        setTimeout(() => {
          this.overlay = false
        }, 800)
      }
    },
    toggleSearch (val) {
      if (!val) {
        this.handleClear()
      }
    },
    searchQuery (nv, ov) {
      if (nv.length < 1) {
        this.handleClear()
      } else {
        this.handleSuggets()
      }
    },
    'user.currentLevel' (val) {
      if (val === 3) {
        this.calcPercentage()
        this.handleCategory()
        this.handleThirdCards()
        this.handleFocusBoxes()
      }
    },
    bgImage (val) {
      this.imageChanged = false
      if (val) {
        setTimeout(() => {
          this.imageChanged = true
        }, 300)
      }
    }
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('checklists', ['checklists', 'countableChecklist']),
    ...mapState('search', ['results', 'suggests']),
    ...mapState('page', ['popups']),
    cyclePercentage () {
      if (this.user.currentLevel === 3) {
        this.handleThirdCards()

        return this.countableChecklist.filter((id) => {
          return (
            this.user.checked[id] && this.user.checked[id][this.user.card.id]
          )
        })
      } else {
        return this.homepage.checklistBoxes.filter((item) => {
          return (
            this.user.checked[item.id] &&
            this.user.checked[item.id][this.user.card.id]
          )
        })
      }
    },
    bgImage () {
      return this.responsiveImages(this.homepage.home.images)
    }
  },
  methods: {
    ...mapActions('user', [
      'addUser',
      'addLevel',
      'addLevels',
      'addCategories',
      'addChkModal',
      'addFocusBoxes',
      'addSelectedCards'
    ]),
    ...mapActions('search', ['search', 'findSuggests', 'clearSuggests']),
    ...mapActions('checklists', ['searchInfoBox']),
    fullImage (data) {
      return data !== null
        ? this.$store.state.init.init.config.mediaBaseUrl + data
        : ''
    },
    responsiveImages (data) {
      if (this.$store.state.ui.device === 'sm') {
        return data.image_mobile
          ? this.fullImage(data.image_mobile)
          : this.fullImage(data.image_desktop)
      } else if (this.$store.state.ui.device === 'md') {
        return data.image_tablet
          ? this.fullImage(data.image_tablet)
          : this.fullImage(data.image_desktop)
      } else {
        return this.fullImage(data.image_desktop)
      }
    },
    progressbarResize () {
      if (this.$store.state.ui.device !== 'lg') {
        this.progressBar.diameter = 140
        this.progressBar.strokeWidth = 10
        this.progressBar.innerStrokeWidth = 7
      } else {
        this.progressBar.diameter = 220
        this.progressBar.strokeWidth = 15
        this.progressBar.innerStrokeWidth = 8
      }
    },
    calcPercentage () {
      this.percentageNumber =
        (this.cyclePercentage.length /
          (this.user.currentLevel === 3
            ? this.countableChecklist.length
            : this.homepage.checklistCounter)) *
        100
    },
    async handleChangeLevel (val, animate) {
      if (
        (val !== this.user.currentLevel && !animate) ||
        (val < 4 && animate)
      ) {
        // if (localStorage.getItem('uc_performance') && !animate) {
        //   this.$gtm.trackEvent({
        //     event: 'manual_level_switch',
        //     action: 'click',
        //     old_level_id: this.user.currentLevel,
        //     level_id: val,
        //     card: this.user.card.title,
        //     card_id: this.user.card.id
        //   })
        // }

        await this.addLevel(val)
        await this.addUser(this.user)

        if (this.user.selectedCards.hasOwnProperty(this.user.card.id)) {
          this.user.selectedCards[this.user.card.id].level = val
          this.addSelectedCards(this.user.selectedCards)
        }
        this.$emit('handleLevelChange', val === 3 && !animate && 'key')
        this.dropdownActive = false
        animate &&
        setTimeout(() => {
          this.handleModal()
        }, 300)
      }
    },
    handleStarterListToggle () {
      if (this.user.currentLevel !== 3) {
        if (!this.closing) {
          this.closing = true
          if (this.starterList) {
            this.starterList = false
            this.closing = false
          } else {
            this.starterList = true
            this.closing = false

            // if (localStorage.getItem('uc_performance')) {
            //   this.$gtm.trackEvent({
            //     event: 'checklist_opened',
            //     category: 'Checklist',
            //     action: 'click',
            //     level_id: this.user.currentLevel,
            //     card: this.user.card.title,
            //     card_id: this.user.card.id
            //   })
            // }

            if (
              this.starterList &&
              !this.user.chkModal &&
              !this.user.firstInfobox
            ) {
              this.addChkModal(false)
            }
          }
        }
      } else {
        this.starterList = false
        this.closing = false
      }
    },
    handleModal () {
      this.levelUpDialog = !this.levelUpDialog
      if (this.levelUpDialog) {
        setTimeout(() => {
          if (localStorage.getItem('uc_performance')) {
            // this.$gtm.trackEvent({
            //   event: 'level_up',
            //   action: 'click',
            //   level_id: this.user.currentLevel,
            //   card: this.user.card.title,
            //   card_id: this.user.card.id
            // })

            if (parseInt(this.user.currentLevel) === 2) {
              // this.$gtm.trackEvent({
              //   event: 'time_event',
              //   action: 'click',
              //   timer_type: 'starter_checklist',
              //   time: Date.now() - parseInt(localStorage.getItem('in_time_start_starter_checklist'))
              // })

              localStorage.removeItem('in_time_start_starter_checklist')
              localStorage.setItem('in_time_start_explorer_checklist', Date.now())
            } else if (parseInt(this.user.currentLevel) === 3) {
              // this.$gtm.trackEvent({
              //   event: 'time_event',
              //   action: 'click',
              //   timer_type: 'explorer_checklist',
              //   time: Date.now() - parseInt(localStorage.getItem('in_time_start_explorer_checklist'))
              // })

              localStorage.removeItem('in_time_start_explorer_checklist')
            }
          }

          this.createCanvas()
        }, 50)
      } else {
        this.$emit('keyChange')
      }
    },
    fireAnimation () {
      this.canvas({
        angle: 90,
        spread: 360,
        particleCount: 800,
        origin: { y: 0.2 },
        gravity: 0.9,
        ticks: 800,
        startVelocity: 25,
        colors: ['#006FCF'],
        scalar: this.randomInRange(1, 1.7)
      })
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 100)
    },
    randomInRange (min, max) {
      return Math.random() * (max - min) + min
    },
    createCanvas () {
      let elWrapp = document.getElementById('canvasWrapper')
      let elCanvas = document.createElement('canvas')
      elWrapp.appendChild(elCanvas)
      this.canvas = Confetti.create(elCanvas, { resize: true })
      setTimeout(() => {
        this.fireAnimation(elCanvas)
      }, 100)
    },
    finishedLevels () {
      let bool = true
      const cardId = this.user.card.id
      let checkedLevels = this.user.levels
      if (checkedLevels.hasOwnProperty(this.user.currentLevel)) {
        if (
          !checkedLevels[this.user.currentLevel].hasOwnProperty(cardId) ||
          !checkedLevels[this.user.currentLevel][cardId]
        ) {
          checkedLevels[this.user.currentLevel] = {
            ...checkedLevels[this.user.currentLevel],
            [`${cardId}`]: true
          }
          bool = false
        }
      } else {
        checkedLevels[this.user.currentLevel] = {
          ...checkedLevels[this.user.currentLevel],
          [`${cardId}`]: true
        }
        bool = false
      }
      this.addLevels(checkedLevels)
      return bool
    },
    blurMethod (e) {
      this.closing = true
      this.starterList = false
      setTimeout(() => {
        this.closing = false
      }, 100)
    },
    handleSearch (val) {
      const query = val
      if (query.trim(' ') !== '') {
        this.dropResult = false
        this.$router.push({ name: 'Search', query: { keyword: query } })
      }
    },
    handleSuggets (val) {
      const query = val || this.searchQuery
      if (query.trim(' ') !== '') {
        this.findSuggests({ query: query }).then((resp) => {
          this.dropResult = resp.length > 0
          this.searchList = resp
        })
      }
    },
    handleClear () {
      this.searchQuery = ''
      this.dropResult = false
      this.clearSuggests()
    },
    triggerCollapse (id) {
      for (let i = 0; i < this.homepage.checklistBoxes.length; i++) {
        if (this.homepage.checklistBoxes[i].id !== id) {
          this.homepage.checklistBoxes[i].visible = false
        }
      }
    },
    async handleCategory () {
      let data = this.user.categories
      let awArray = this.homepage.advantageWorlds
      if (data && awArray.length > 0) {
        let counter = 0
        for (let i = 0; i < awArray.length; i++) {
          if (!data.hasOwnProperty(awArray[i].id) && counter === 0) {
            // this.$set(data, awArray[i].id, true)
            data[`${awArray[i].id}`] = true
            counter++
            this.currentCategory = awArray[i]
            break
          }
        }
        if (counter < 1) {
          data = {}
          this.currentCategory = null
          await this.addCategories(data)
          await this.handleCategory()
        }
        await this.addCategories(data)
        await this.addUser(this.user)
      }
    },
    saveCategory (type, val) {
      this.currentCategory = null
      let data = this.user.categories
      data[`${val.id}`] = true
      this.addCategories(data)
      this.$nextTick(() => {
        if (type === 'c') this.handleCategory()
        else {
          this.$router.push({ name: 'Category', params: { slug: val.slug } })
        }
      })
    },
    currentModal () {
      let self = this
      let lvl = self.user.currentLevel
      this.homepage.levels.filter((item, i) => {
        switch (lvl) {
          case 1:
            if (item.title.toLowerCase() === 'starter') {
              self.levelContent = item
            }
            break
          case 2:
            if (item.title.toLowerCase() === 'explorer') {
              self.levelContent = item
            }
            break
          default:
            if (item.title.toLowerCase() === 'expert') self.levelContent = item
            break
        }
        // self.levelContent = lvl === 1 ? (item.title.toLowerCase() === 'starter') : (lvl === 2 ? (item.title.toLowerCase() === 'explorer') : (item.title.toLowerCase() === 'expert'))
      })
    },
    handleThirdCards () {
      let cnt = 0
      for (let id in this.countableChecklist) {
        if (this.user.checked.hasOwnProperty(this.countableChecklist[id])) {
          if (
            !this.user.checked[this.countableChecklist[id]][this.user.card.id]
          ) {
            this.findInfoBox(this.countableChecklist[id])
            cnt++
            break
          }
        }
      }
      if (cnt === 0) {
        this.expertInfoBox = null
      }
    },
    findInfoBox (id) {
      this.expertInfoBox = null
      this.searchInfoBox(id).then((resp) => {
        this.expertInfoBox = resp
      })
    },
    triggerChecklistModal () {
      this.$emit('handleModalChecklist')
    },
    async handleFocusBoxes () {
      let data = this.user.focusBoxes
      let fbArray = this.homepage.focusBoxes
      if (data && fbArray.length > 0) {
        let counter = 0
        for (let i = 0; i < fbArray.length; i++) {
          if (!data.hasOwnProperty(fbArray[i].id) && counter === 0) {
            // this.$set(data, awArray[i].id, true)
            data[`${fbArray[i].id}`] = true
            counter++
            this.focusBox = fbArray[i]
            break
          }
        }
        if (counter < 1) {
          data = {}
          this.focusBox = null
          await this.addFocusBoxes(data)
          await this.handleFocusBoxes()
        }
        await this.addFocusBoxes(data)
        await this.addUser(this.user)
      }
    },
    goToAmexOBD () {
      window.open(process.env.VUE_APP_OBD_URL, '_blank') || window.location.replace(process.env.VUE_APP_OBD_URL)
    }
  },
  mounted () {
    let self = this
    self.calcPercentage()
    this.$nextTick(() => {
      self.progressbarResize()
      self.cookieUser = !self.user.cookies
      if (self.user.currentLevel === 3) {
        self.handleCategory()
        self.handleThirdCards()
        self.handleFocusBoxes()
      }
    })
  }
}
</script>
