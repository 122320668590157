<script>
import FaqBoxes from './faq-boxes'
import HowToVideos from './how-to-videos'
import ContactBoxes from './contact-boxes'
import ContentColumns from './content-columns'
import FeedbackSection from './feedback-section'
import AdvantageWorlds from './advantage-worlds'
import OtherFunctions from './other-functions'
import StartingPoint from './starting-point'

export default {
  functional: true,
  name: 'ContentElementsRenderer',
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  render (createElement, context) {
    let render = []
    switch (context.props.content._group) {
      case 'starting_point':
        render.push(createElement(StartingPoint, { props: context.props }))
        break
      case 'faq_boxes':
        render.push(createElement(FaqBoxes, { props: context.props }))
        break
      case 'how_to_boxes':
        render.push(createElement(HowToVideos, { props: context.props }))
        break
      case 'contact_boxes':
        render.push(createElement(ContactBoxes, { props: context.props }))
        break
      case 'content_columns':
        render.push(createElement(ContentColumns, { props: context.props }))
        break
      case 'feedback_section':
        render.push(createElement(FeedbackSection, { props: context.props }))
        break
      case 'advantage_worlds':
        render.push(createElement(AdvantageWorlds, { props: context.props }))
        break
      case 'other_functions':
        render.push(createElement(OtherFunctions))
        break
    }
    return render
  }
}
</script>
