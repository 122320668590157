<template>
  <div class="modalContainer popupText" @click.self="$emit('handleToggle', item.type)" :class="item.style">
    <div class="modalPopup noBg">
      <span class="innerPoupBg">
        <AmexLogoSvg />
      </span>
      <div class="popupHeader">
        <h2 v-text="item.title"></h2>
        <div class="spacer"></div>
        <span class="iconRight">
          <button class="iconButton small flat" @click="$emit('handleToggle', item.type)" type="button">
            <img :src="(item.style !== '') ? icons.closeWhite : icons.closeBlack"/>
          </button>
        </span>
      </div>
      <div class="popupContent auto-height text-center">
        <div v-html="item.content_elements || item.content || ''"></div>
      </div>
      <div class="popupActions flex justify-center">
        <a class="button rounded small" :href="item.button_action" target="_blank">
          <span v-text="item.button_title"></span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import AmexLogoSvg from '@/components/icons/bg-amex-logo'
export default {
  props: {
    item: {
      type: Object,
      default: null,
      required: true
    }
  },
  data () {
    return {
      icons: {
        close: require('@/assets/images/x-icon-blue.svg'),
        closeWhite: require('@/assets/images/x-icon-white.svg'),
        closeBlack: require('@/assets/images/x-icon-black.svg')
      },
      popupData: {
        title: 'Headline',
        description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
        buttonText: 'Call to Action',
        buttonLink: '#'
      }
    }
  },
  components: {
    AmexLogoSvg
  }
}
</script>
