<template>
  <main v-if="content">
    <div class="pageContent imageBannerNone"
         :class="{ shrinkedHeader: $store.state.ui.device === 'sm' && !$store.state.user.user.card.id || ($route.name === 'Static' && $route.fullPath === '/datenschutz') }">
      <div class="grid-container">
        <div class="row">
          <div class="col-x12 col-xxl10 offset-xxl1">
            <div>
              <page-tools></page-tools>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-container" v-if="content">
        <div class="row">
          <div class="col-x12 col-xxl10 offset-xxl1">
            <div class="row">
              <div class="col-s8 col-m5">
                <page-title :pageHeaders="content"></page-title>
              </div>
            </div>
          </div>
          <div class="col-x12 col-xxl10 offset-xxl1">
            <div class="plainCards row">
              <div v-for="(item, i) in filteredKontaktCards" :key="i" class="singlePlainCard" data-aos="fade-up"
                   data-aos-duration="1000" :data-aos-delay="50 * i">
                <div>
                  <h4 v-text="item.title"></h4>
                </div>
                <div class="spcTools">
                  <ul>
                    <template v-for="(contList, n) in item.content">
                      <li :key="n + 'contListItems'" v-if="contList._group !== 'content'" :id="'chklst' + item.id">
                      <span>
                        <img :src="icons[contList._group]"/>
                      </span>
                        <a :href="contList.url" v-if="contList._group === 'link'" v-text="contList.title"
                           :target="contList.newtab === '1' ? '_blank' : '_self' "></a>
                        <a :href="'mailto:' + contList.email" v-if="contList._group === 'email'"
                           v-text="contList.title"></a>
                        <a @click.prevent="handleCall(contList.number)" v-if="contList._group === 'phone_number'"
                           v-text="contList.title"></a>
                        <a :href="contList.file" v-if="contList._group === 'file'" v-text="contList.title"
                           target="_blank"></a>
                        <a :href="fullImage(contList.image_desktop)" v-if="contList._group === 'image'"
                           v-text="contList.title" target="_blank"></a>
                      </li>
                      <li :key="n + 'contListItems'" v-else>
                        <p v-html="contList.content"></p>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-x12 col-xxl10 offset-xxl1">
            <div class="plainCards static row">
              <div v-for="(item, i) in staticCards" :key="i" class="singlePlainCard largePlain static"
                   data-aos="fade-up" data-aos-duration="1000" :data-aos-delay="50 * i">
                <div class="content">
                  <div>
                    <h4 v-text="item.title"></h4>
                  </div>
                  <div class="spcTools">
                    <ul>
                      <template v-for="(contList, n) in item.content_elements">
                        <li :key="n + 'contListItems'" v-if="contList._group !== 'content'" :id="'chklst' + item.id">
                        <span>
                          <img :src="icons[contList._group]"/>
                        </span>
                          <a :href="contList.url" v-if="contList._group === 'link'" v-text="contList.title"
                             :target="contList.newtab === '1' ? '_blank' : '_self' "></a>
                          <a :href="'mailto:' + contList.email" v-if="contList._group === 'email'"
                             v-text="contList.title"></a>
                          <a @click.prevent="handleCall(contList.number)" v-if="contList._group === 'phone_number'"
                             v-text="contList.title"></a>
                          <a :href="contList.file" v-if="contList._group === 'file'" v-text="contList.title"
                             target="_blank"></a>
                          <a :href="fullImage(contList.image_desktop)" v-if="contList._group === 'image'"
                             v-text="contList.title" target="_blank"></a>
                        </li>
                        <li :key="n + 'contListItems'" v-else>
                          <p v-html="contList.content"></p>
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>
                <div class="imageContent">
                  <img :src="fullImage(item.image)" alt=""/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import userStore from '@/store/modules/user'
import uiStore from '@/store/modules/ui'
import dynamicModule from '@/store/utils/dynamicModule'
import pagesStore from '@/store/modules/page'
import PageTitle from '@/components/pages/page-title.vue'
import PageTools from '@/components/pages/page-tools.vue'

export default {
  data () {
    return {
      page: null,
      kontaktType: 1,
      kontaktCards: [],
      staticCards: [],
      icons: {
        link: require('@/assets/images/icon-link.svg'),
        phone_number: require('@/assets/images/icon-telefon.svg'),
        email: require('@/assets/images/icon-mail.svg'),
        video: require('@/assets/images/icon-video.svg'),
        message: require('@/assets/images/icon-message.svg'),
        file: require('@/assets/images/icon-details-text.svg'),
        image: require('@/assets/images/icon-camera.svg'),
        plusIcon: require('@/assets/images/plus-blue.svg'),
        minusIcon: require('@/assets/images/plus-minus.svg'),
        checkUnfilled: require('@/assets/images/checkbox-blue-frame.svg'),
        checkFilled: require('@/assets/images/checkbox-blue-filled.svg')
      }
    }
  },
  metaInfo () {
    return {
      title: this.page?.meta.title !== '' ? this.page?.meta.title : this.page?.title,
      description: this.page?.meta.description
    }
  },
  mixins: [
    dynamicModule(name, userStore),
    dynamicModule('page', pagesStore),
    dynamicModule('ui', uiStore)
  ],
  components: {
    PageTitle,
    PageTools
  },
  props: {
    content: {
      default: function () {
        return { title: 'Kontakt', subtitle: '' }
      }
    }
  },
  watch: {},
  computed: {
    ...mapState('page', ['teaserBoxes']),
    ...mapState('ui', ['device']),
    filteredKontaktCards () {
      return this.kontaktCards.filter((item, i) => {
        return item.type
      })
    }
  },
  methods: {
    ...mapActions('page', ['loadStaticContactBoxes', 'pageDetails']),
    handleKontaktType (val) {
      this.kontaktType = val
    },
    fullImage (data) {
      return data !== null ? this.$store.state.init.init.config.mediaBaseUrl + data : ''
    }
  },
  created () {
    if (this.$store.state.user?.user?.card?.id) {
      this.$router.push({ name: 'Page', params: { slug: 'kontakt' } })
    } else {
      this.pageDetails('kontakt').then(response => {
        this.page = response
      })

      this.loadStaticContactBoxes().then(resp => {
        this.kontaktCards = resp.contact_boxes
        this.staticCards = resp.teaser_boxes
      })
    }
  },
  mounted () {
  }
}
</script>
