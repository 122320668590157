<template>
  <div class="modalContainer videoPlayerModal" @click.self="handleDialog()" v-if="visibleModal">
    <div class="modalPopup" style="max-width: 800px">
      <div class="popupHeader">
        <h2 v-text="modal.title"></h2>
        <div class="spacer"></div>
        <span class="iconRight">
          <button class="iconButton small" type="button" @click="handleDialog()">
            <img :src="icons.closeWhite"/>
          </button>
        </span>
      </div>
      <div class="popupContent auto-height" v-if="modal.type === 'video'">
        <iframe width="100%" height="400" :src="modal.url" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
      </div>
      <div class="popupContent auto-height text-center" v-else>
        <img :src="responsiveImages(modal.url)"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import uiStore from '@/store/modules/ui'
const name = 'ui'
export default {
  data () {
    return {
      icons: {
        close: require('@/assets/images/x-icon-blue.svg'),
        closeWhite: require('@/assets/images/x-icon-white.svg')
      }
    }
  },
  mixins: [
    dynamicModule(name, uiStore)
  ],
  computed: {
    ...mapState(name, ['modal', 'showModal']),
    visibleModal () {
      return this.showModal
    }
  },
  methods: {
    ...mapActions(name, ['clearModal']),
    handleDialog () {
      this.clearModal()
    },
    fullImage (data) {
      return data !== null ? this.$store.state.init.init.config.mediaBaseUrl + data : ''
    },
    responsiveImages (data) {
      if (this.$store.state.ui.device === 'sm') {
        return data.image_mobile ? this.fullImage(data.image_mobile) : this.fullImage(data.image_desktop)
      } else if (this.$store.state.ui.device === 'md') {
        return data.image_tablet ? this.fullImage(data.image_tablet) : this.fullImage(data.image_desktop)
      } else {
        return this.fullImage(data.image_desktop)
      }
    }
  }
}
</script>
