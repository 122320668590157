<template>
  <main class="singleAW" v-if="category">
    <div class="mainSection notHome">
      <div class="imageBanner auto-height">
        <img :src="responsiveImages(category)" :alt="category.title"/>
      </div>
    </div>
    <div class="pageContent">
      <div class="grid-container">
        <div class="row">
          <div class="col-x12 col-xxl10 offset-xxl1">
            <page-tools></page-tools>
            <div class="sectionTitle flex flatMd" data-aos="fade-left" data-aos-duration="1000">
              <div class="text-left">
                <!-- <h3 class="subtitle">Vorteilswelt</h3> -->
                <h2 class="title" v-text="category.title"></h2>
                <p v-text="category.description"></p>
              </div>
              <div class="spacer"></div>
              <div class="rightTools" v-if="category.show_damage_reports_link">
                <button class="button rounded"
                        @click.stop="$router.push({ name: 'Category', params: {slug: $store.state.init.init.reportClaim} })">
                  Schaden melden
                </button>
              </div>
              <div class="rightTools" v-if="category.email_subject && category.email_button">
                <a :href="generateEmailContent(category)"
                class="button rounded medium flex"
                target="_blank">
                  <span class="button__icon">
                    <img :src="(category.email_icon && fullImage(category.email_icon)) || emailIcon" alt="">
                  </span>
                  {{ category.email_button }}
                </a>
              </div>
            </div>
            <div class="checklistPageHolder">
              <ul class="row" data-aos="fade-up" :data-aos-delay="0" data-aos-duration="1000">
                <li class="col-x12 col-s6 col-l4" v-for="(item, i) in categoryCheckboxes" :key="i">
                  <single-checklist class="shortTitle" :item="item" :large="$store.state.ui.device === 'lg'"
                                    v-on:triggerCollapse="triggerCollapse" :trigger="true"
                                    :checked="item.is_countable"></single-checklist>
                </li>
              </ul>
            </div>
            <div class="buttonIconLink" data-aos="fade-up" data-aos-duration="1000">
              <button
                @click.stop="$router.push({ name: 'Category', params: { slug: $store.state.init.init.reportClaim }})"
                v-if="category.show_damage_reports_link">
                Schaden melden
                <div class="spacer"></div>
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g fill="none" fill-rule="evenodd">
                      <g>
                        <g>
                          <g>
                            <path fill="#FFF"
                                  d="M12 22.5c5.79 0 10.5-4.71 10.5-10.5S17.79 1.5 12 1.5 1.5 6.21 1.5 12 6.21 22.5 12 22.5zM12 0c6.628 0 12 5.372 12 12s-5.372 12-12 12S0 18.628 0 12 5.372 0 12 0z"
                                  transform="translate(-1081 -931) translate(770 908) translate(311 23)"/>
                            <path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                                  d="M10.08 7.2L14.88 12 10.08 16.8"
                                  transform="translate(-1081 -931) translate(770 908) translate(311 23)"/>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              </button>
            </div>
            <div class="buttonIconLink" data-aos="fade-up" data-aos-duration="1000" v-if="category.email_subject && category.email_button">
              <a :href="generateEmailContent(category)"
                target="_blank">
                {{ category.email_button }}
                <div class="spacer"></div>
                <!-- <span>
                  <img :src="(category.email_icon && fullImage(category.email_icon)) || emailIcon" alt="">
                </span> -->
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g fill="none" fill-rule="evenodd">
                      <g>
                        <g>
                          <g>
                            <path fill="#FFF"
                                  d="M12 22.5c5.79 0 10.5-4.71 10.5-10.5S17.79 1.5 12 1.5 1.5 6.21 1.5 12 6.21 22.5 12 22.5zM12 0c6.628 0 12 5.372 12 12s-5.372 12-12 12S0 18.628 0 12 5.372 0 12 0z"
                                  transform="translate(-1081 -931) translate(770 908) translate(311 23)"/>
                            <path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"
                                  d="M10.08 7.2L14.88 12 10.08 16.8"
                                  transform="translate(-1081 -931) translate(770 908) translate(311 23)"/>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <banner-image-content-h
    v-if="category.banner && category.banner.length > 0"
    class="categoryBanner"
    :item="category.banner[0]"
    :image="responsiveImages(category.banner[0])"></banner-image-content-h>
    <advantage-worlds :small="true"></advantage-worlds>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
// import storage from '@/helpers/storage'
import dynamicModule from '@/store/utils/dynamicModule'
import userStore from '@/store/modules/user'
import categoriesStore from '@/store/modules/categories'
import checklistsStore from '@/store/modules/checklists'
import SingleChecklist from '@/components/single-checklist.vue'
import PageTools from '@/components/pages/page-tools.vue'
import AdvantageWorlds from '@/components/pages/advantage-worlds.vue'
import BannerImageContentH from '@/components/banners/banner-image-content-h.vue'

export default {
  mixins: [
    dynamicModule('user', userStore),
    dynamicModule('checklists', checklistsStore),
    dynamicModule('categories', categoriesStore)
  ],
  data () {
    return {
      category: null,
      categoryCheckboxes: [],
      emailIcon: require('@/assets/images/icons-business-cards-contactless.svg')
    }
  },
  metaInfo () {
    return {
      title: this.category?.meta.title !== '' ? this.category?.meta.title : this.category?.title,
      description: this.page?.category.description
    }
  },
  components: {
    SingleChecklist,
    PageTools,
    AdvantageWorlds,
    BannerImageContentH
  },
  directives: {},
  computed: {
    ...mapState('user', ['user']),
    ...mapState('checklists', ['checklists']),
    ...mapState('categories', ['categories']),
    categoryList () {
      const routeId = parseInt(this.$route.params.id)
      return this.functionsItems.filter((item, i) => {
        return item.category === routeId && item.count
      })
    }
  },
  methods: {
    ...mapActions('user', ['addUser']),
    ...mapActions('checklists', ['search']),
    ...mapActions('categories', ['searchCategories', 'details', 'clearCategory']),
    ...mapActions('ui', ['changePopupText', 'changePopupImage']),
    setCheckboxes () {
      this.categoryCheckboxes = this.category.info_box
    },
    fullImage (data) {
      return data !== null ? this.$store.state.init.init.config.mediaBaseUrl + data : ''
    },
    responsiveImages (data) {
      if (this.$store.state.ui.device === 'sm') {
        return data.image_mobile ? this.fullImage(data.image_mobile) : this.fullImage(data.image_desktop)
      } else if (this.$store.state.ui.device === 'md') {
        return data.image_tablet ? this.fullImage(data.image_tablet) : this.fullImage(data.image_desktop)
      } else {
        return this.fullImage(data.image_desktop)
      }
    },
    triggerCollapse (id) {
      for (let i = 0; i < this.categoryCheckboxes.length; i++) {
        if (this.categoryCheckboxes[i].id !== id) {
          this.categoryCheckboxes[i].visible = false
        }
      }
    },
    generateEmailContent (cat) {
      return 'mailto:?subject=' + cat.email_subject + '&body=' + (cat.email_message?.replace(/(?:\r\n|\r|\n)/g, '%0D%0A') || cat.email_message) + ' %0D%0A' + cat.email_link
    },
    handlePagePopups (data) {
      if (data?.popups?.length) {
        data.popups.forEach(popup => {
          if (popup?.type) {
            !localStorage.getItem('sv_popups') && localStorage.setItem('sv_popups', '{}')
            let popupLocalData = JSON.parse(localStorage.getItem('sv_popups'))

            !sessionStorage.getItem('sv_popups') && sessionStorage.setItem('sv_popups', '{}')
            let popupsSessionData = JSON.parse(sessionStorage.getItem('sv_popups'))

            if (!popupsSessionData.hasOwnProperty(popup.id)) {
              if (popupLocalData.hasOwnProperty(popup.id) &&
                (parseInt(popupLocalData[popup.id]) < parseInt(popup.visit_popup))) {
                popupLocalData[popup.id] = parseInt(popupLocalData[popup.id]) + 1
                popupsSessionData = { ...popupsSessionData, [popup['id']]: 1 }

                this.triggerSurveyPopup(popup)
                localStorage.setItem('sv_popups', JSON.stringify(popupLocalData))
                sessionStorage.setItem('sv_popups', JSON.stringify(popupsSessionData))
              } else if (!popupLocalData.hasOwnProperty(popup.id)) {
                popupLocalData = { ...popupLocalData, [popup['id']]: 1 }
                popupsSessionData = { ...popupsSessionData, [popup['id']]: 1 }

                this.triggerSurveyPopup(popup)
                localStorage.setItem('sv_popups', JSON.stringify(popupLocalData))
                sessionStorage.setItem('sv_popups', JSON.stringify(popupsSessionData))
              }
            }
          }
        })
      }
    },
    triggerSurveyPopup (data) {
      switch (data.type) {
        case 'survey_popup_text':
          this.popupTextTimeout = setTimeout(() => {
            this.changePopupText(data)
          }, parseInt(data.delay || 0) * 1000)
          break
        default:
          this.popupImageTimeout = setTimeout(() => {
            this.changePopupImage(data)
          }, parseInt(data.delay || 0) * 1000)
          break
      }
    }
  },
  created () {
    this.details(this.$route.params.slug).then(resp => {
      if (resp) {
        this.category = resp
        this.handlePagePopups(this.category)
        this.setCheckboxes()

        // if (localStorage.getItem('uc_performance')) {
        //   this.$gtm.trackEvent({
        //     event: 'benefit_world_opened',
        //     level_id: this.user.currentLevel,
        //     card: this.user.card.title,
        //     card_id: this.user.card.id
        //   })
        // }
      }
    })
  },
  beforeDestroy () {
    this.clearCategory()
  }
}
</script>

<style scoped lang="scss"></style>
