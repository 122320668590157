import { AuthHttp } from '@/helpers/http-helper'
// import router from '@/router/index'
const getDefaultState = () => {
  return {
    pages: [],
    page: {},
    homepage: {},
    popups: {},
    staticContact: [],
    teaserBoxes: []
    // expertTeaserBoxes: null
  }
}
const state = getDefaultState()

const getters = {
  getPages: state => state.pages,
  getPage: state => state.page,
  getPopups: state => state.popups,
  getStatic: state => state.staticContact,
  getTeaserBoxes: state => state.teaserBoxes
  // getExpertTeaserBoxes: state => state.expertTeaserBoxes
}

const mutations = {
  setPages (state, data) {
    state.pages = data
  },
  setPage (state, data) {
    state.page = data
  },
  setHomepage (state, data) {
    state.homepage = data
  },
  setPopups (state, data) {
    state.popups = data
  },
  setStatic (state, data) {
    state.staticContact = data
  },
  setTeaserBoxes (state, data) {
    state.teaserBoxes = data
  },
  // setExpertTeaserBoxes (state, data) {
  //   state.expertTeaserBoxes = data
  // },
  clear (state) {
    state = getDefaultState()
  }
}

const actions = {
  searchPages ({ commit }) {
    return AuthHttp.get('/pages/').then(response => {
      commit('setPages', response.data)
      return response.data
    })
  },
  loadHome ({ commit }) {
    return AuthHttp.get('/homepage').then(response => {
      commit('setHomepage', response.data)
      return response.data
    })
  },
  pageDetails ({ commit }, data) {
    return AuthHttp.get('/pages/slug/' + data).then(response => {
      commit('setPage', response.data)
      return response.data
    })
  },
  loadVideos ({ commit }) {
    return AuthHttp.get('/how-to-videos').then(response => {
      return response.data
    })
  },
  loadFaqs ({ commit }) {
    return AuthHttp.get('/faq-topics').then(response => {
      return response.data
    })
  },
  loadFaq ({ commit }, data) {
    return AuthHttp.get('/faq-topics/' + data).then(response => {
      commit('setPage', response.data)
      return response.data
    })
  },
  loadContactBoxes ({ commit }) {
    return AuthHttp.get('/contact-boxes').then(response => {
      return response.data
    })
  },
  loadStaticContactBoxes ({ commit }) {
    return AuthHttp.get('/static-contact-boxes').then(response => {
      return response.data
    })
  },
  loadFeedbackCats ({ commit }) {
    return AuthHttp.get('/feedback-categories').then(response => {
      return response.data
    })
  },
  loadDamages ({ commit }) {
    return AuthHttp.get('/damage-reports').then(response => {
      return response.data
    })
  },
  submitFeedback ({ commit }, data) {
    return AuthHttp.post('/feedback/create', data).then(response => {
      return response.data
    }).catch(error => {
      return error.response
    })
  },
  searchPopups ({ commit }) {
    return AuthHttp.get('/popups').then(resp => {
      commit('setPopups', resp.data)
      return resp.data
    })
  },
  loadTeaserBoxes ({ commit }, data) {
    return AuthHttp.get('/contact-page-teaser-boxes').then(response => {
      commit('setTeaserBoxes', response.data)
      return response.data
    }).catch(error => {
      return error.response
    })
  },
  loadExpertTeaserBoxes ({ commit }) {
    return AuthHttp.get('/expert-level-teaser-box').then(response => {
      // commit('setExpertTeaserBoxes', response.data)
      return response.data
    }).catch(error => {
      return error.response
    })
  },
  clearPage ({ commit }) {
    commit('clear')
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
