import { AuthHttp } from '@/helpers/http-helper'

const getDefaultState = () => {
  return {
    checklists: [],
    countableChecklist: [],
    importantFunctionsList: []
  }
}
const state = getDefaultState()

const getters = {
  getChecklists: state => state.checklists
}

const mutations = {
  setCountableChecklist (state, list) {
    state.countableChecklist = list
  },
  setChecklists (state, list) {
    state.checklists = list
  },
  setImportantFunctionsList (state, list) {
    state.importantFunctionsList = list
  }
}

const actions = {
  searchInfoBox ({ commit }, id) {
    return AuthHttp.get('/info-boxes/' + id).then(resp => {
      return resp.data
    })
  },
  searchCountable ({ commit }) {
    return AuthHttp.get('/info-boxes-ids').then(resp => {
      commit('setCountableChecklist', resp.data)
      return resp.data
    })
  },
  startList ({ commit }, data) {
    return AuthHttp.get('/info-boxes/starting-page/', { params: { inbx: data ? 1 : 0 } }).then(resp => {
      return resp.data
    })
  },
  loadImportantFunctionsList ({ commit }) {
    return AuthHttp.get('info-boxes/important-functions').then(resp => {
      commit('setImportantFunctionsList', resp.data)
      return resp.data
    })
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
