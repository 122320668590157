<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import userStore from '@/store/modules/user'
import dynamicModule from '@/store/utils/dynamicModule'

export default {
  name: 'App',
  mixins: [
    dynamicModule('user', userStore)
  ],
  methods: {
    ...mapActions('user', ['addUser'])
  },
  created () {
    localStorage.setItem('in_time_start_screen_slides', Date.now())
  }
}
</script>
