<template>
  <div class="initial-cookie-popup">
    <div class="initial-cookie-popup__inner">
      <header class="initial-cookie-popup__inner__header">
        <h3>Einstellungen für Cookies</h3>
      </header>
      <div class="initial-cookie-popup__inner__content">
        <div class="initial-cookie-popup__inner__content__text">
          <h6>Unsere Verwendung von Cookies</h6>
          <p>
            <b>American Express verwendet Cookies</b> auf dieser Website für
            nicht wesentliche Zwecke. Sie helfen uns damit zu verstehen, wie Sie
            unsere Website nutzen, Inhalte zu personalisieren und unser
            Online-Marketing zu verbessern. Durch Klicken auf „Alle akzeptieren“
            erklären Sie sich damit einverstanden, dass wir und unsere Partner
            Cookies auf Ihrem Gerät für diese Zwecke speichern und abrufen
            können. Sie können Ihre Einstellungen jederzeit ändern und weitere
            Informationen über die Funktionsweise von Cookies finden, indem Sie
            in der Rubrik „Cookie-Einstellungen festlegen“ in unseren
            <router-link :to="{ name: 'DataProtection' }" target="_blank">
              Cookie-Richtlinien
            </router-link>
            nachsehen.
          </p>
        </div>
        <div class="initial-cookie-popup__inner__content__list">
          <div
            class="initial-cookie-popup__inner__content__list__item"
            v-for="(item, i) in cookiesList"
            :key="i"
          >
            <initial-cookie-checklist
              :item="item"
              v-on:category-accepted="cookieCategoryAccepted"
              v-on:category-declined="cookieCategoryDeclined"
            ></initial-cookie-checklist>
          </div>
        </div>
      </div>
    </div>

    <footer class="initial-cookie-popup__footer">
      <button @click.prevent="acceptCookieModal()">
        Einstellungen speichern
      </button>
      <button @click="acceptAllServices">
        Alle akzeptieren
      </button>
    </footer>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import InitialCookieChecklist from './initial-cookie-checklist.vue'

export default {
  name: 'InitialDetailedCookiePopup',
  components: { InitialCookieChecklist },
  data () {
    return {
      isPopupVisible: true,
      isCookieExpanded: false,
      cookiesList: []
    }
  },

  methods: {
    ...mapActions('user', [
      'addUser',
      'addCheckedList',
      'addCookies',
      'addCookieConsent'
    ]),
    ...mapActions('ui', ['changeCookieModal']),

    cookieCategoryAccepted (services, functional = false) {
      this.updateUserCentricsServices(services, true)
    },
    cookieCategoryDeclined (services) {
      this.updateUserCentricsServices(services, false)
    },
    acceptAllServices () {
      let self = this
      this.$userCentrics.acceptAllServices().then(function () {
        self.$userCentrics.getCategories()

        localStorage.setItem('uc_essential', true)
        localStorage.setItem('uc_functional', true)
        localStorage.setItem('uc_performance', true)

        self.$store.dispatch('user/addCookieConsent', true)
        self.$store.dispatch('user/addCookies', true)
        self.changeCookieModal(false)
        // self.closeInitialCookiePopup()
      })
    },

    declineAllServices () {
      let self = this

      localStorage.setItem('uc_essential', false)
      localStorage.setItem('uc_functional', false)
      localStorage.setItem('uc_performance', false)
      self.changeCookieModal(false)
      // self.closeInitialCookiePopup()
    },

    // acceptCookieModal () {
    //   let self = this

    //   localStorage.setItem('uc_essential', true)
    //   self.addCookies(true)

    //   if (localStorage.getItem('uc_functional') === 'false') {
    //     self.$store.replaceState({})
    //     sessionStorage.clear()
    //     localStorage.clear()
    //   }
    // },
    acceptCookieModal () {
      localStorage.setItem('uc_essential', true)
      // !this.hasRecaptcha() && this.createRecaptcha()
      this.$store.dispatch('user/addCookies', true)
      this.changeCookieModal(false)

      let _self = this

      // this.$store.dispatch('user/addCookieConsent', true)
      if (localStorage.getItem('uc_functional') === 'false') {
        // debugger
        sessionStorage.clear()
        localStorage.clear()
        setTimeout(function () {
          sessionStorage.clear()
          _self.$store.replaceState({})
          localStorage.clear()
          // window.location.href = '/start/'
          _self.$router.go()
        }, 1000)
      }
      // if (localStorage.getItem('uc_performance')) {
      //   // !this.hasGtm() && this.createGtm()
      //   // !this.$gtm.enabled() && this.$gtm.enable(true)
      // } else {
      //   this.removeGtm()
      //   this.$gtm.enable(false)
      // }
    },

    functionalCookieAccepted () {
      return localStorage.getItem('uc_functional') === 'true'
    },

    closeInitialCookiePopup () {
      this.isPopupVisible = false
    },
    cookieDetails () {
      this.isCookieExpanded = true
    }
  },
  mounted () {
    let self = this

    this.$userCentrics.getCategoriesFullInfo().then(function (categories) {
      categories.forEach(function (item) {
        let checked = true
        item.services.forEach(function (service) {
          checked = checked && service.consent.status
        })

        item.checked = checked
        item.visible = false
      })

      self.cookiesList = categories
    })
  }
}
</script>
