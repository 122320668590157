<template>
  <main>
    <div class="pageContent imageBannerNone searchPageContent">
      <div class="grid-container">
        <div class="row">
          <div class="col-x12 col-xxl10 offset-xxl1">
            <page-tools></page-tools>
            <div
              class="sectionTitle"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <h2
                v-if="
                  (!results.boxes || results.boxes.length === 0) &&
                  (!results.videos || results.videos.length === 0) &&
                  (!results.faq || results.faq.length === 0) &&
                  (!results.contact_boxes || results.contact_boxes.length === 0) &&
                  (!results.advantage_worlds || results.advantage_worlds.length === 0)
                "
                class="title"
              >
                <span style="color: #a7a8aa">Keine Suchresultate für:</span
                ><br />
                „{{ searchKeyword }}“
              </h2>
              <h2 v-else class="title">
                <span style="color: #a7a8aa">Ihre Suchresultate für:</span
                ><br />
                „{{ searchKeyword }}“
              </h2>
            </div>
          </div>
          <div
            v-if="
              (!results.boxes || results.boxes.length === 0) &&
              (!results.videos || results.videos.length === 0) &&
              (!results.faq || results.faq.length === 0) &&
              (!results.contact_boxes || results.contact_boxes.length === 0) &&
              (!results.advantage_worlds || results.advantage_worlds.length === 0)
            "
            class="
              no-search-result-text
              col-x12 col-xxl10
              offset-xxl1
              simplePage
            "
          >
            <p>
              Schauen Sie doch mal in den
              <router-link :to="{ name: 'Page', params: { slug: 'faqs'} }">FAQs</router-link> oder unter
              <router-link :to="{ name: 'Home' }">wichtige Funktionen</router-link>, ob Sie die
              <br />
              gewünschte Antwort finden.
            </p>
          </div>
          <div>
            <div
              class="col-x12 col-xxl10 offset-xxl1 simplePage"
              v-if="results && results.boxes && results.boxes.length > 0"
            >
              <h3 data-aos="fade-left" data-aos-duration="1000">
                Vorteile, Funktionen & Services
              </h3>
              <div class="checklistPageHolder">
                <ul class="row">
                  <li
                    class="col-x12 col-s6 col-l4"
                    v-for="(item, i) in results.boxes"
                    :key="'bx-' + i"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    :data-aos-delay="50 * i"
                  >
                    <single-checklist
                      class="shortTitle"
                      v-on:triggerCollapse="triggerBoxes"
                      :trigger="true"
                      :item="item"
                      :large="false"
                      :checked="item.is_countable"
                      :rate="false"
                    ></single-checklist>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="col-x12 col-xxl10 offset-xxl1 simplePage"
              v-if="results && results.videos && results.videos.length > 0"
            >
              <h3 data-aos="fade-left" data-aos-duration="1000">
                How-To-Videos
              </h3>
              <div class="videoGrid row">
                <div
                  class="videoWrapper col-s6 col-m4"
                  v-for="(video, i) in results.videos"
                  :key="i"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  :data-aos-delay="50 * i"
                >
                  <div class="videoBox" @click.stop="handleVideoPlay(video)">
                    <img :src="responsiveImages(video)" />
                    <span class="playButton">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                      >
                        <g fill="none" fill-rule="evenodd">
                          <g fill="#006FCF" fill-rule="nonzero">
                            <g>
                              <path
                                d="M15 0c8.313 0 15 6.688 15 15 0 8.313-6.688 15-15 15-8.313 0-15-6.688-15-15C0 6.687 6.688 0 15 0zm0 1.875C7.75 1.875 1.875 7.75 1.875 15S7.75 28.125 15 28.125 28.125 22.25 28.125 15 22.25 1.875 15 1.875zm-1.838 7.148l7.188 4.375c1.2.731 1.2 2.473 0 3.203l-7.188 4.375c-1.25.76-2.85-.139-2.85-1.602v-8.749c0-1.463 1.6-2.363 2.85-1.602zm-.975 1.602v8.75L19.375 15l-7.188-4.375z"
                                transform="translate(-660 -838) translate(660 838)"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </div>
                  <h4 v-text="video.title"></h4>
                </div>
              </div>
            </div>
            <div
              class="col-x12 col-xxl10 offset-xxl1 simplePage"
              v-if="results && results.faq && results.faq.length > 0"
            >
              <h3 data-aos="fade-left" data-aos-duration="1000">FAQs</h3>
              <div class="checklistPageHolder">
                <ul class="row">
                  <li
                    class="col-x12 col-s6 col-l4"
                    v-for="(item, i) in results.faq"
                    :key="'fq-' + i"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    :data-aos-delay="50 * i"
                  >
                    <single-checklist
                      class="shortTitle"
                      v-on:triggerCollapse="triggerFaqs"
                      :trigger="true"
                      :item="item"
                      :title="true"
                      :large="false"
                      :checked="false"
                      :rate="false"
                    ></single-checklist>
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="col-x12 col-xxl10 offset-xxl1 simplePage"
              v-if="
                results &&
                results.advantage_worlds &&
                results.advantage_worlds.length > 0
              "
            >
              <h3 data-aos="fade-left" data-aos-duration="1000">
                Vorteilswelt
              </h3>
              <div class="vorteilsWeltPageHolder">
                <div class="row vwelten-search-res">
                  <div
                    class="vw-item col-x12 col-s6 col-l6"
                    v-for="(item, i) in results.advantage_worlds"
                    :key="'vw-' + i"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    :data-aos-delay="50 * i"
                  >
                    <div class="imageHolder">
                      <router-link
                        :to="{ name: 'Category', params: { slug: item.slug } }"
                        class="showActive"
                      >
                        <img
                          v-if="item.homepage_image"
                          :src="fullImage(item.homepage_image)"
                        />
                      </router-link>
                      <img
                        v-if="item.homepage_image"
                        :src="fullImage(item.homepage_image)"
                      />
                    </div>

                    <div
                      class="slideContent"
                      :class="[!item.homepage_image ? 'no-image-item' : '']"
                    >
                      <router-link
                        :to="{ name: 'Category', params: { slug: item.slug } }"
                        @mouseenter.native="isLocked = true"
                        @mouseleave.native="isLocked = false"
                      >
                        <h4 v-text="item.title"></h4>
                      </router-link>
                      <p v-text="item.description"></p>
                      <router-link
                        :to="{ name: 'Category', params: { slug: item.slug } }"
                        @mouseenter.native="isLocked = true"
                        @mouseleave.native="isLocked = false"
                        class="button rounded inverse"
                      >
                        Mehr erfahren
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-x12 col-xxl10 offset-xxl1"
              v-if="
                results &&
                results.contact_boxes &&
                results.contact_boxes.length > 0
              "
            >
              <h3 data-aos="fade-left" data-aos-duration="1000">Kontakt</h3>
              <div class="kontaktPageHolder">
                <div class="plainCards row">
                  <div
                    class="singlePlainCard largePlain"
                    v-for="(item, i) in results.contact_boxes"
                    :key="'kontakt-' + i"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    :data-aos-delay="50 * i"
                  >
                    <div class="content">
                      <div>
                        <h4 v-text="item.title"></h4>
                      </div>
                      <div class="spcTools">
                        <ul>
                          <template
                            v-for="(contList, n) in item.content"
                          >
                            <li
                              :key="n + 'contListItems'"
                              v-if="contList._group !== 'content'"
                              :id="'chklst' + item.id"
                            >
                              <span>
                                <img :src="icons[contList._group]" />
                              </span>
                              <a
                                :href="contList.url"
                                v-if="contList._group === 'link'"
                                v-text="contList.title"
                                :target="
                                  contList.newtab === '1' ? '_blank' : '_self'
                                "
                              ></a>
                              <a
                                :href="'mailto:' + contList.email"
                                v-if="contList._group === 'email'"
                                v-text="contList.title"
                              ></a>
                              <a
                                @click.prevent="handleCall(contList.number)"
                                v-if="contList._group === 'phone_number'"
                                v-text="contList.title"
                              ></a>
                              <a
                                :href="contList.file"
                                v-if="contList._group === 'file'"
                                v-text="contList.title"
                                target="_blank"
                              ></a>
                              <a
                                :href="fullImage(contList.image_desktop)"
                                v-if="contList._group === 'image'"
                                v-text="contList.title"
                                target="_blank"
                              ></a>
                            </li>
                            <li
                              :key="n + 'contListItems'"
                              v-else
                              v-html="contList.content"
                            ></li>
                          </template>
                        </ul>
                      </div>
                    </div>
                    <div class="imageContent" v-if="item.image">
                      <img :src="fullImage(item.image)" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-x12 col-xxl10 offset-xxl1"
            v-if="
              (!results.boxes || results.boxes.length === 0) &&
              (!results.videos || results.videos.length === 0) &&
              (!results.faq || results.faq.length === 0) &&
              (!results.contact_boxes || results.contact_boxes.length === 0) &&
              (!results.advantage_worlds || results.advantage_worlds.length === 0)
            "
          >
            <div class="plainCards static row">
              <div
                v-for="(item, i) in staticCards"
                :key="i"
                class="singlePlainCard largePlain static"
                data-aos="fade-up"
                data-aos-duration="1000"
                :data-aos-delay="50 * i"
              >
                <div class="content">
                  <div>
                    <h4 v-text="item.title"></h4>
                  </div>
                  <div class="spcTools">
                    <ul>
                      <template v-for="(contList, n) in item.content_elements">
                        <li
                          :key="n + 'contListItems'"
                          v-if="contList._group !== 'content'"
                          :id="'chklst' + item.id"
                        >
                          <span>
                            <img :src="icons[contList._group]" />
                          </span>
                          <a
                            :href="contList.url"
                            v-if="contList._group === 'link'"
                            v-text="contList.title"
                            :target="
                              contList.newtab === '1' ? '_blank' : '_self'
                            "
                          ></a>
                          <a
                            :href="'mailto:' + contList.email"
                            v-if="contList._group === 'email'"
                            v-text="contList.title"
                          ></a>
                          <a
                            @click.prevent="handleCall(contList.number)"
                            v-if="contList._group === 'phone_number'"
                            v-text="contList.title"
                          ></a>
                          <a
                            :href="contList.file"
                            v-if="contList._group === 'file'"
                            v-text="contList.title"
                            target="_blank"
                          ></a>
                          <a
                            :href="fullImage(contList.image_desktop)"
                            v-if="contList._group === 'image'"
                            v-text="contList.title"
                            target="_blank"
                          ></a>
                        </li>
                        <li :key="n + 'contListItems'" v-else>
                          <p v-html="contList.content"></p>
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>
                <div class="imageContent">
                  <img :src="fullImage(item.image)" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modalContainer videoPlayerModal"
      @click.self="handleVideoClose()"
      v-if="videoPlayer"
    >
      <div class="modalPopup">
        <div class="popupHeader">
          <h2 v-text="selectedVideo.title"></h2>
          <div class="spacer"></div>
          <span class="iconRight">
            <button
              class="iconButton small"
              type="button"
              @click="handleVideoClose()"
            >
              <img :src="icons.closeWhite" />
            </button>
          </span>
        </div>
        <div class="popupContent auto-height">
          <iframe
            :src="selectedVideo.url"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import SingleChecklist from '@/components/single-checklist.vue'
import PageTools from '@/components/pages/page-tools.vue'
import searchStore from '@/store/modules/search'

export default {
  data () {
    return {
      searchKeyword: '',
      searchObject: {},
      selectedVideo: {
        title: '',
        url: ''
      },
      videoPlayer: false,
      icons: {
        link: require('@/assets/images/icon-link.svg'),
        phone_number: require('@/assets/images/icon-telefon.svg'),
        email: require('@/assets/images/icon-mail.svg'),
        video: require('@/assets/images/icon-video.svg'),
        message: require('@/assets/images/icon-message.svg'),
        file: require('@/assets/images/icon-details-text.svg'),
        image: require('@/assets/images/icon-camera.svg'),
        plusIcon: require('@/assets/images/plus-blue.svg'),
        minusIcon: require('@/assets/images/plus-minus.svg'),
        checkUnfilled: require('@/assets/images/checkbox-blue-frame.svg'),
        checkFilled: require('@/assets/images/checkbox-blue-filled.svg'),
        close: require('@/assets/images/x-icon-blue.svg'),
        closeWhite: require('@/assets/images/x-icon-white.svg')
      },
      staticCards: []
    }
  },
  mixins: [dynamicModule('search', searchStore)],
  props: {
    keyword: {
      type: String,
      default: null,
      required: true
    }
  },
  components: {
    SingleChecklist,
    PageTools
  },
  watch: {
    keyword (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.handleSearchForm()
      }
    },
    videoPlayer (val) {
      let el = document.getElementsByTagName('body')[0]
      el.style.overflow = val ? 'hidden' : ''
    }
  },
  computed: {
    ...mapState('search', ['results', 'suggests'])
  },
  methods: {
    ...mapActions('page', ['loadContactBoxes']),
    ...mapActions('search', ['search', 'findSuggests', 'clearSuggests']),
    fullImage (data) {
      return data !== null
        ? this.$store.state.init.init.config.mediaBaseUrl + data
        : ''
    },
    responsiveImages (data) {
      if (this.$store.state.ui.device === 'sm') {
        return data.image_mobile
          ? this.fullImage(data.image_mobile)
          : this.fullImage(data.image_desktop)
      } else if (this.$store.state.ui.device === 'md') {
        return data.image_tablet
          ? this.fullImage(data.image_tablet)
          : this.fullImage(data.image_desktop)
      } else {
        return this.fullImage(data.image_desktop)
      }
    },
    handleSearchForm () {
      this.searchKeyword = this.keyword
      this.search({ query: `${encodeURIComponent(this.keyword)}` })
    },
    handleVideoPlay (video) {
      if (video) {
        this.selectedVideo = {
          title: video.title,
          url: video.url + '?autoplay=1'
        }
        this.videoPlayer = true
      }
    },
    handleVideoClose () {
      this.videoPlayer = false
      this.selectedVideo = {
        title: '',
        url: ''
      }
    },
    triggerBoxes (id) {
      for (let i = 0; i < this.results.boxes.length; i++) {
        if (typeof this.results.boxes[i].visible === 'undefined') {
          this.$set('visible', true)
        }
        if (this.results.boxes[i].id !== id) {
          this.results.boxes[i].visible = false
        }
      }
    },
    triggerFaqs (id) {
      for (let i = 0; i < this.results.faq.length; i++) {
        if (typeof this.results.faq[i].visible === 'undefined') {
          this.$set('visible', true)
        }
        if (this.results.faq[i].id !== id) {
          this.results.faq[i].visible = false
        }
      }
    }
  },
  created () {
    if (!this.keyword) {
      this.$router.push({ name: 'Home' })
    }
    this.loadContactBoxes().then((resp) => {
      this.kontaktCards = resp.contact_boxes
      this.staticCards = resp.teaser_boxes
    })
  },
  updated () {
    // this.handleSearchForm()
  },
  mounted () {
    this.handleSearchForm()
  },
  beforeDestroy () {}
}
</script>
