<template>
  <div class="feedbackPage" v-if="content">
    <div class="grid-container">
      <div class="row">
        <div class="col-x12 col-xxl10 offset-xxl1">
          <div class="row flex flex-wrap">
            <div class="col-x12 col-s6 col-m4" data-aos="fade-left" data-aos-duration="1000" v-if="content._group === 'feedback_section'">
              <div class="sectionTitle text-left">
                <h2 class="title" v-if="content.title" v-text="content.title"></h2>
                <p v-if="content.description" v-text="content.description"></p>
              </div>
            </div>
            <div class="col-x12 feedbackSelectWrapper">
              <div class="row flex flex-wrap">
                <div class="col-x12 col-s12 col-m4">
                  <div class="col-12 col-s6 col-m12 amexSelect row" data-aos="fade-up" data-aos-duration="1000">
                    <multiselect
                      class="feedbackDropdown"
                      v-model="selectedOption"
                      :options="options"
                      :searchable="false"
                      track-by="title"
                      label="title"
                      placeholder="Kategorie auswählen"
                      :showLabels="false"
                      openDirection="below"
                      @input="handleSelect">
                      select-arrow
                      <template slot="caret" slot-scope="{}">
                        <div class="multiselect__select">
                          <span>
                            <img :src="require('@/assets/images/select-arrow.svg')" width="30px" />
                          </span>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </div>
                <div class="col-x12 col-s6 col-m4" v-if="selectedOption">
                  <div class="feedbackRates" data-aos="fade-up" data-aos-duration="1000">
                     <p v-text="selectedOption.description"></p>
                    <div class="rateSliders">
                      <ul class="flex flex-column">
                        <li v-for="(item, i) in rates" :key="i">
                          <div class="checkRateGeneral">
                            <div class="rateTitle" :class="{ active: item.rate > 0 }">
                              <span v-text="item.name"></span>
                              <p v-text="item.description"></p>
                            </div>
                            <div class="ratebar">
                              <vue-slider v-model="item.rate" v-bind="rateOptions" @change="handleRate">
                                <template v-slot:mark="{ pos, label }">
                                  <div class="vue-slider-mark vue-slider-mark-active" :style="{ left: `${pos}%` }">
                                    <div class="vue-slider-mark-label vue-slider-mark-label-active" v-if="label > 0">
                                      {{ label }}
                                    </div>
                                  </div>
                                </template>
                                <template v-slot:process="{ end, style }">
                                  <div class="vue-slider-process" :class="[ (end < 30.01 && end > 0) ? 'red' : ((end < 70.01 && end > 30) ? 'yellow' : 'green')]" :style="[style]"></div>
                                </template>
                              </vue-slider>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-x12 col-s6 col-m4 feedbackForm" v-if="selectedOption" data-aos="fade-up" data-aos-duration="1000">
                  <textarea placeholder="Weitere Ausführungen:" v-model="selectedOption.message" rows="14" class="customField" :disabled="!submitForm"></textarea>
                  <p v-if="notValidMessage">* Bitte geben Sie keine persönlichen Informationen, wie <br/>
                    zum Beispiel Ihre Kreditkartennummer, Namen oder <br/>
                    ähnliches, über diese Funktion an uns weiter.</p>
                  <div>
                    <button @click.stop="handleSubmit()" class="button rounded block" :class="{ disabled: !submitForm }" :disabled="!submitForm"><span>Feedback senden</span></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modalContainer feedbackModalContainer" v-if="feedbackDialog">
      <div class="modalPopup">
        <div class="popupHeader">
          <h2 v-if="popups.feedback.title" v-html="popups.feedback.title"></h2>
          <!-- <div class="spacer"></div> -->
          <span class="iconRight">
            <button class="iconButton small" type="button"  @click.stop="handleModal()">
              <img :src="icons.close" width="100%" />
            </button>
          </span>
        </div>
        <div class="popupContent text-center">
          <template v-for="(item, index) in popups.feedback.content_elements">
            <div :key="index" v-if="item._group === 'content'" v-html="item.content"></div>
          </template>
          <div class="pcLinks">
            <ul>
              <template v-for="(item, index) in popups.feedback.content_elements">
                <li :key="index + 'fbItem'" v-if="item._group !== 'content'">
                  <span>
                    <img :src="icons[item._group]"/>
                  </span>
                  <a :href="item.url" v-if="item._group === 'link'" v-text="item.title" :target="item.newtab === '1' ? '_blank' : '_self' "></a>
                  <a :href="'mailto:' + item.email" v-if="item._group === 'email'" v-text="item.title"></a>
                  <a :href="'tel:' + item.number" v-if="item._group === 'phone_number'" v-text="item.title"></a>
                  <!-- <a v-if="contList._group === 'video'" v-text="contList.title"></a> -->
                  <a :href="item.file" v-if="item._group === 'file'" v-text="item.title" target="_blank"></a>
                  <!-- <a :href="fullImage(contList.image_desktop)" v-if="contList._group === 'image'" v-text="contList.title" target="_blank"></a> -->
                </li>
              </template>
            </ul>
          </div>
        </div>
        <div class="popupActions flex justify-center">
          <button class="button rounded backToDashboard" @click.stop="goToDashboard()"><span>Zurück zum Dashboard</span></button>
          <!-- <button class="button rounded block" @click.stop="handleModal()"><span>Schließen</span></button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import pagesStore from '@/store/modules/page'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

export default {
  data () {
    return {
      // icons: {
      //   phone: require('@/assets/images/icons/icon--kontakt.svg'),
      //   info: require('@/assets/images/icons/icon--faq.svg')
      // },
      icons: {
        link: require('@/assets/images/icon-link.svg'),
        // link: require('@/assets/images/icons/icon--faq.svg'),
        phone_number: require('@/assets/images/icon-telefon.svg'),
        email: require('@/assets/images/icon-mail.svg'),
        video: require('@/assets/images/icon-video.svg'),
        message: require('@/assets/images/icon-message.svg'),
        file: require('@/assets/images/icon-details-text.svg'),
        image: require('@/assets/images/icon-camera.svg'),
        plusIcon: require('@/assets/images/plus-blue.svg'),
        minusIcon: require('@/assets/images/plus-minus.svg'),
        checkUnfilled: require('@/assets/images/checkbox-blue-frame.svg'),
        checkFilled: require('@/assets/images/checkbox-blue-filled.svg'),
        close: require('@/assets/images/x-icon-blue.svg')
        // info: require('@/assets/images/icons/icon--faq.svg')
      },
      feedbackDialog: false,
      selectedOption: null,
      options: [],
      rate: 0,
      rateOptions: {
        dotSize: 20,
        width: 'auto',
        height: 4,
        contained: false,
        // data: null,
        dataLabel: 'label',
        dataValue: 'value',
        min: 0,
        max: 10,
        interval: 1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        tooltip: 'active',
        tooltipPlacement: 'top',
        marks: true
      },
      submitForm: false,
      notValidMessage: false,
      page: null,
      rates: [
        {
          title: 'Informationstiefe:',
          rate: 0
        },
        {
          title: 'Verständlichkeit:',
          rate: 0
        },
        {
          title: 'Übersicht:',
          rate: 0
        }
      ],
      feedbackPopup: {}
    }
  },
  props: {
    content: {
      default: null
    }
  },
  computed: {
    ...mapState('page', ['popups'])
  },
  mixins: [
    dynamicModule('page', pagesStore)
  ],
  components: {
    Multiselect,
    VueSlider
  },
  watch: {
    feedbackDialog (val) {
      let el = document.getElementsByTagName('body')[0]
      el.style.overflow = val ? 'hidden' : ''
    },
    'selectedOption.message' (nv, ov) {
      const val = nv.replace(/\D/g, '')
      let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$')
      let amex = new RegExp('^3[47][0-9]{13}$')
      let mastercard = new RegExp('^5[1-5][0-9]{14}$')
      this.notValidMessage = val.match(visa) || val.match(amex) || val.match(mastercard)
    }
  },
  methods: {
    ...mapActions('page', ['pageDetails', 'loadFeedbackCats', 'clearPage', 'submitFeedback', 'searchPopups']),
    handleRate () {
      let data = 0
      this.rates.filter(item => {
        if (item.rate > 0) {
          data++
        }
      })
      this.submitForm = data === this.rates.length
    },
    resetRate (data) {
      let self = this
      this.rates = data.scales
      this.rates.forEach(item => {
        self.$set(item, 'rate', 0)
      })
    },
    handleSelect (val) {
      this.resetRate(val)
      if (val) {
        this.handleRate()
      }
    },
    handleSubmit () {
      if (!this.notValidMessage && localStorage.getItem('uc_functional') === 'true') {
        const data = {
          feedback_category_id: this.selectedOption.id,
          rates: this.rates,
          comment: this.selectedOption.message
        }
        this.submitFeedback(data).then(() => {
          this.feedbackDialog = true
        })
      } else {
        this.$store.dispatch('user/addCookieConsent', false).then(() => {
          this.$store.dispatch('ui/changeCookiePopup', true)
        })
      }
    },
    handleModal () {
      this.selectedOption = null
      this.feedbackDialog = false
    },
    goToDashboard () {
      this.handleModal()
      this.$router.push({ name: 'Home' })
    }
  },
  mounted () {
    this.loadFeedbackCats().then(response => {
      this.options = response
      this.searchPopups()
    })
  },
  beforeDestroy () {
    let el = document.getElementsByTagName('body')[0]
    el.style.overflow = ''
    this.clearPage()
  }
}
</script>

<style scoped lang="scss"></style>
