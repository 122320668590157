<template>
  <div class="security-section">
    <div class="container">
       <img :src="require('@/assets/images/amex_blue_box.svg')"/>
      <h2 v-text="blue_banner.title" />
      <a :href="blue_banner.button_link" class="button rounded block" v-text="blue_banner.button_title" />
  </div>
  </div>
</template>

<script>
export default {
  props: {
    blue_banner: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
    }
  }
}
</script>
