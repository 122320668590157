import { AuthHttp } from '@/helpers/http-helper'
// import router from '@/router/index'

const state = {
  cards: []
}

const getters = {
  getCards: state => state.cards
}

const mutations = {
  setCards (state, cards) {
    state.cards = cards
  }
}

const actions = {
  searchCards ({ commit }) {
    AuthHttp.get('/cards').then(resp => {
      commit('setCards', resp.data)
    })
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
