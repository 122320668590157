<template>
  <div class="modalContainer halfContentImage" @click.self="$emit('handleToggle', item.type)" :class="item.style">
    <div class="modalPopup">
      <span class="iconRight" @click="$emit('handleToggle', item.type)">
        <button class="iconButton small flat" :class="[iconHover ? 'inverse' : '']" type="button" @mouseover="iconHover = true" @mouseleave="iconHover = false">
          <img :src="iconHover ? icons.close : icons.closeWhite"/>
        </button>
      </span>
      <div class="modalPopup__content">
        <h2 v-text="item.title"></h2>
        <div v-html="item.content_elements || item.content"></div>
        <a class="button rounded small" :href="item.button_action" target="_blank">
          <span v-text="item.button_title"></span>
        </a>
      </div>
      <div class="modalPopup__image">
        <img :src="responsiveImages(item)" alt=""/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
      required: true
    }
  },
  data () {
    return {
      icons: {
        close: require('@/assets/images/x-icon-blue.svg'),
        closeWhite: require('@/assets/images/x-icon-white.svg'),
        closeBlack: require('@/assets/images/x-icon-black.svg')
      },
      popupData: {
        title: 'Headline',
        description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
        buttonText: 'Call to Action',
        buttonLink: '#',
        image: 'photo2-1.jpg'
      },
      iconHover: false
    }
  },
  methods: {
    fullImage (data) {
      return data !== null ? this.$store.state.init.init.config.mediaBaseUrl + data : ''
    },
    responsiveImages (data) {
      if (this.$store.state.ui.device === 'sm') {
        return data.image_mobile ? this.fullImage(data.image_mobile) : this.fullImage(data.image_desktop)
      } else if (this.$store.state.ui.device === 'md') {
        return data.image_tablet ? this.fullImage(data.image_tablet) : this.fullImage(data.image_desktop)
      } else {
        return this.fullImage(data.image_desktop)
      }
    }
  }
}
</script>
