import { AuthHttp } from '@/helpers/http-helper'

const state = {
  nav: [],
  page: {}
}

const getters = {
  getNav: state => state.nav,
  getPage: state => state.page
}

const mutations = {
  setNav (state, data) {
    state.nav = data
  },
  setPage (state, data) {
    state.page = data
  },
  clear (state) {
    state = {
      page: {}
    }
  }
}

const actions = {
  loadNavigation ({ commit }, url) {
    return AuthHttp.get('cookie-pages-nav').then(response => {
      commit('setNav', response.data)
      return response.data
    })
  },
  loadPage ({ commit }, url) {
    return AuthHttp.get('cookie-pages/' + url).then(response => {
      commit('setPage', response.data)
      return response.data
    })
  },
  loadHome ({ commit }) {
    return AuthHttp.get('cookie-pages/homepage').then(response => {
      commit('setPage', response.data)
      return response.data
    })
  },
  clearPage ({ commit }) {
    commit('clear')
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
