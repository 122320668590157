<template>
  <div class="grid-container" v-if="content">
    <div class="row">
      <div class="col-x12 col-xxl10 offset-xxl1">
        <div class="row">
          <div class="col-s8 col-m5">
            <page-title :pageHeaders="content"></page-title>
          </div>
        </div>
      </div>
      <div class="col-x12 col-xxl10 offset-xxl1">
        <div class="plainCards row">
          <div v-for="(item, i) in faqSections" :key="i" class="singlePlainCard" data-aos="fade-up"
               data-aos-duration="1000" :data-aos-delay="50 * i">
            <h4 v-text="item.title"></h4>
            <p v-text="item.description"></p>
            <div class="spcTools">
              <ul>
                <li>
                  <router-link :to="{ name: 'FaqSingle', params: { id: item.id }}">Mehr dazu</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import pagesStore from '@/store/modules/page'
import PageTitle from '@/components/pages/page-title.vue'

export default {
  props: {
    content: {
      default: function () {
        return {
          title: 'Dein American Express Support',
          subtitle: ''
        }
      }
    }
  },
  mixins: [
    dynamicModule('page', pagesStore)
  ],
  data () {
    return {
      faqSections: []
    }
  },
  components: {
    PageTitle
  },
  methods: {
    ...mapActions('page', ['loadFaqs'])
  },
  mounted () {
    this.$nextTick(() => {
      this.loadFaqs().then(response => {
        this.faqSections = response
      })
    })
  }
}
</script>
