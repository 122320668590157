<template>
  <div class="singleChecklist" v-if="item" :class="getClass(item)">
    <div class="checklistDetails">
      <div
        class="checkTitle"
        :class="{ simple: !checked && !item.icon_white && !item.icon_blue }"
      >
        <span
          class="infoBoxIcon"
          v-if="!checked && (item.icon_white || item.icon_blue)"
        >
          <img
            :src="
              $store.state.init.init.config.mediaBaseUrl +
              (item.is_highlight ? item.icon_white : item.icon_blue)
            "
          />
        </span>
        <span
          class="checkStatus"
          v-if="checked"
          @click="handleCheck(item, true)"
        >
          <svg
            v-if="!item.done"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
          >
            <g fill="none" fill-rule="evenodd">
              <g
                :stroke="item.is_highlight && item.pivot ? '#FFF' : '#006FCF'"
                stroke-width="1.5"
                transform="translate(-450 -838)"
              >
                <circle cx="465" cy="853" r="14.25"/>
              </g>
            </g>
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
          >
            <g fill="none" fill-rule="evenodd">
              <g>
                <g transform="translate(-380 -838) translate(380 838)">
                  <circle
                    cx="15"
                    cy="15"
                    r="15"
                    :fill="item.is_highlight && !this.inChecklist ? '#FFF' : '#006FCF'"
                  />
                  <path
                    :stroke="
                      item.is_highlight && !this.inChecklist ? '#006FCF' : '#FDFDFD'
                    "
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 15L12.983 20 21.75 10"
                  />
                </g>
              </g>
            </g>
          </svg>
          <!-- <img v-if="!item.done" :src="icons.checkUnfilled" width="100%"/>
          <img v-else :src="icons.checkFilled" width="100%"/> -->
        </span>
        <h4
          @click="handleToggle(item)"
          v-text="!title ? item.title_checklist : item.title"
        ></h4>
        <span class="toggleButton" @click="handleToggle(item)">
          <!-- <img v-if="!item.visible" :src="icons.plusIcon" width="100%" />
          <img v-else :src="icons.minusIcon" width="100%" /> -->
          <svg
            v-if="!item.visible"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
          >
            <g fill="none" fill-rule="evenodd">
              <g>
                <path
                  fill="#006FCF"
                  d="M541.563 852.063c.517 0 .937.419.937.937s-.42.938-.938.938h-5.625v5.625c0 .517-.42.937-.937.937-.518 0-.938-.42-.938-.938v-5.625h-5.625c-.518 0-.937-.419-.937-.937s.42-.938.938-.938h5.625v-5.625c0-.518.42-.937.937-.937.518 0 .938.42.938.938v5.625h5.625zM535 866.125c7.237 0 13.125-5.888 13.125-13.125s-5.888-13.125-13.125-13.125-13.125 5.888-13.125 13.125 5.888 13.125 13.125 13.125zM535 838c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15z"
                  transform="translate(-520 -838)"
                />
              </g>
            </g>
          </svg>
          <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
          >
            <g fill="none" fill-rule="evenodd">
              <g>
                <path
                  fill="#006FCF"
                  d="M611.563 852.063c.517 0 .937.419.937.937s-.42.938-.938.938h-13.125c-.518 0-.937-.42-.937-.938s.42-.938.938-.938h13.125zM605 866.125c7.237 0 13.125-5.888 13.125-13.125s-5.888-13.125-13.125-13.125-13.125 5.888-13.125 13.125 5.888 13.125 13.125 13.125zM605 838c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15z"
                  transform="translate(-590 -838)"
                />
              </g>
            </g>
          </svg>
        </span>
      </div>
      <transition name="slide">
        <template
          v-if="
            item.content_elements &&
            item.content_elements.length > 0 &&
            item.content_elements[0]._group === 'content'
          "
        >
          <div
            v-if="large"
            class="checkDescription"
            v-html="item.content_elements[0].content"
          ></div>
          <div
            v-if="!large && item.visible"
            class="checkDescription"
            v-html="item.content_elements[0].content"
          ></div>
        </template>
      </transition>
      <div
        class="checkLinks"
        v-if="
          item.visible &&
          item.content_elements &&
          item.content_elements.length > 0
        "
      >
        <ul>
          <template v-for="(contList, l) in item.content_elements">
            <li :key="l + 'contListItems'" v-if="contList._group !== 'content'" :id="'chklst' + item.id">
              <span> <img v-if="type !== 'blue' && !item.is_highlight" :src="icons[contList._group]"/>
                <img v-else :src="iconsLight[contList._group]"/>
              </span>
              <a :href="contList.url" v-if="contList._group === 'link'" v-text="contList.title"
                 @click="handleCTAClick(1, item, false, contList)"
                 :target="contList.newtab === '1' ? '_blank' : '_self'"></a>
              <a
                :href="'mailto:' + contList.email"
                v-if="contList._group === 'email'"
                v-text="contList.title"
                @click="handleCTAClick(1, item, false, contList)"
              ></a>
              <a
                :href="'tel:' + contList.number"
                v-if="contList._group === 'phone_number'"
                v-text="contList.title"
                @click.prevent="handleCTAClick(3, item, false, contList)"
              ></a>
              <a
                v-if="contList._group === 'video'"
                v-text="contList.title"
                @click.prevent="handleCTAClick(2, item, false, contList)"
              ></a>
              <a
                :href="contList.file"
                v-if="contList._group === 'file'"
                v-text="contList.title"
                @click="handleCTAClick(1, item, false, contList)"
                target="_blank"
              ></a>
              <a
                :href="fullImage(contList.image_desktop)"
                v-if="contList._group === 'image'"
                v-text="contList.title"
                @click.prevent="handleCTAClick(2, item, false, contList)"
                target="_blank"
              ></a>
            </li>
            <li
              :key="l + 'contListItems'"
              v-else-if="contList._group === 'content' && l > 0"
              class="pl-0"
            >
              <div
                class="checkDescription mb-20"
                v-html="contList.content"
              ></div>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <transition name="slide">
      <div class="checkRate" v-if="rate && item.visible && item.have_feedback">
        <div class="rateTitle">
          <span>Wie wichtig ist Ihnen dieser Vorteil?</span>
          <div class="flex justify-space-between py-1 px-3">
            <span><i>unwichtig</i></span>
            <span><i>sehr wichtig</i></span>
          </div>
        </div>
        <div class="ratebar">
          <vue-slider
            v-model="item.rate"
            v-bind="rateOptions"
            @change="handleFeedbackSubmit"
          >
            <template v-slot:mark="{ pos, label }">
              <div
                class="vue-slider-mark vue-slider-mark-active"
                :style="{ left: `${pos}%` }"
              >
                <div
                  class="vue-slider-mark-label vue-slider-mark-label-active"
                  v-if="label > 0"
                >
                  {{ label }}
                </div>
              </div>
            </template>
            <template v-slot:process="{ end, style }">
              <div
                class="vue-slider-process"
                :class="[
                  end < 30.01 && end > 0
                    ? 'red'
                    : end < 70.01 && end > 30
                    ? 'yellow'
                    : 'green',
                ]"
                :style="[style]"
              ></div>
            </template>
          </vue-slider>
        </div>
        <!-- <span>{{ user.ratings[rateIndex] + '==' + user.ratings[rateIndex].rate + '==' + rateIndex }}</span> -->
        <transition name="slide">
          <div
            class="checkTextarea"
            v-if="
              canSubmitComment &&
              (rateIndex === -1 ||
                (rateIndex > -1 &&
                  user.ratings[rateIndex] &&
                  user.ratings[rateIndex].rate !== item.rate))
            "
          >
            <form>
              <textarea
                class="customField"
                rows="6"
                placeholder="Weitere Ausführungen:"
                v-model="feedbackComment"
              ></textarea>
            </form>
          </div>
          <div :key="'hahah' + testKey"
               class="checkTextarea submitted"
               v-else-if="
              rateIndex > -1 &&
              user.ratings[rateIndex] &&
              user.ratings[rateIndex].rate === item.rate
            "
          >
            <p>
              <i class="material-icons">info_outline</i>
            </p>
            <p>
              Vielen Dank für Ihre Ausführung. Zur Eingabe weiterer Anmerkungen
              bewegen Sie bitte die Skala erneut.
            </p>
          </div>
        </transition>
        <button :key="'hahaha' + testKey"
                @click="sendFeedback(item)"
                class="button rounded sendFeedbackBtn"
                v-if="
            item.rate > 0 &&
            item.rate < 11 &&
            (rateIndex === -1 ||
              (rateIndex > -1 &&
                user.ratings[rateIndex] &&
                user.ratings[rateIndex].rate !== item.rate))
          "
        >
          <span>Senden</span>
        </button>
      </div>
    </transition>
  </div>
</template>
<script>
// import VueRecaptcha from 'vue-recaptcha'
import uiStore from '@/store/modules/ui'
import { mapActions, mapState } from 'vuex'
import VueSlider from 'vue-slider-component'
import userStore from '@/store/modules/user'
import 'vue-slider-component/theme/default.css'
import dynamicModule from '@/store/utils/dynamicModule'
import UserCentricsController from '@/helpers/userCentrics'

// let ele = document.getElementsByTagName('body')[0]
const name = 'user'
export default {
  name: 'SingleChecklist',
  props: {
    item: {
      type: Object,
      default: null
    },
    large: {
      type: Boolean,
      default: true
    },
    checked: {
      type: [Boolean, Number],
      default: true
    },
    rate: {
      type: [Boolean, Number],
      default: true
    },
    type: {
      type: String,
      default: null
    },
    title: {
      type: Boolean,
      default: true
    },
    trigger: {
      type: Boolean,
      default: false
    },
    inChecklist: {
      type: Boolean,
      default: false
    },
    inStartSlide: {
      type: Boolean,
      default: false
    }
  },
  mixins: [dynamicModule(name, userStore), dynamicModule('ui', uiStore)],
  computed: {
    ...mapState(name, ['user']),
    ...mapState('ui', ['showModal']),
    doneClass: function () {
      return this.item && this.item.done && this.checked ? 'done' : 'notDone'
    },
    rateIndex () {
      return this.user.ratings.findIndex(
        (v) =>
          v.id === this.item.id &&
          v.card === this.$store.state.user?.user?.card?.id
      )
    }
  },
  data () {
    return {
      icons: {
        link: require('@/assets/images/icon-link.svg'),
        phone_number: require('@/assets/images/icon-telefon.svg'),
        email: require('@/assets/images/icon-mail.svg'),
        video: require('@/assets/images/icon-video.svg'),
        message: require('@/assets/images/icon-message.svg'),
        file: require('@/assets/images/icon-details-text.svg'),
        image: require('@/assets/images/icon-camera.svg'),
        plusIcon: require('@/assets/images/plus-blue.svg'),
        minusIcon: require('@/assets/images/plus-minus.svg'),
        checkUnfilled: require('@/assets/images/checkbox-blue-frame.svg'),
        checkFilled: require('@/assets/images/checkbox-blue-filled.svg')
      },
      iconsLight: {
        link: require('@/assets/images/icon-light-link.svg'),
        phone_number: require('@/assets/images/icon-light-telefon.svg'),
        email: require('@/assets/images/icon-light-mail.svg'),
        video: require('@/assets/images/icon-light-video.svg'),
        message: require('@/assets/images/icon-light-message.svg'),
        file: require('@/assets/images/icon-light-details-text.svg'),
        image: require('@/assets/images/icon-light-camera.svg'),
        plusIcon: require('@/assets/images/plus-blue.svg'),
        minusIcon: require('@/assets/images/plus-minus.svg'),
        checkUnfilled: require('@/assets/images/checkbox-blue-frame.svg'),
        checkFilled: require('@/assets/images/checkbox-blue-filled.svg')
      },
      rateOptions: {
        dotSize: 20,
        width: 'auto',
        height: 4,
        contained: false,
        // data: null,
        dataLabel: 'label',
        dataValue: 'value',
        min: 0,
        max: 10,
        interval: 1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        tooltip: 'active',
        tooltipPlacement: 'top',
        marks: true
      },
      checklistDialog: false,
      selectedItem: {
        title: '',
        url: '',
        type: ''
      },
      currentElementId: '',
      feedbackComment: null,
      canSubmitComment: false,
      siteKey: process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY,
      testKey: 0
    }
  },
  // watch: {},
  components: {
    VueSlider
  },
  methods: {
    ...mapActions(name, [
      'addUser',
      'addCheckedList',
      'addFirstBox',
      'addChkModal',
      'submitRatings',
      'infoBoxChecked'
    ]),
    ...mapActions('ui', ['changeModal', 'changeCookiePopup', 'changeCookieModal']),
    ...mapActions('user', ['addCookieConsent']),
    contIcon (val) {
      let data = null
      switch (val) {
        case 'link':
          data = this.icons.link
          break
        case 'email':
          data = this.icons.email
          break
        case 'video':
          data = this.icons.video
          break
        case 'file':
          data = this.icons.file
          break
        case 'phone_number':
          data = this.icons.phone_number
          break
        case 'image':
          data = this.icons.image
          break
        default:
          data = this.icons.link
          break
      }
      return data
    },
    handleCTAClick (type, item, toggle, ctaData) {
      // let url = ctaData.url

      // if (ctaData._group === 'video') {
      //   url = ctaData.iframe
      // } else if (ctaData._group === 'phone_number') {
      //   url = ctaData.number
      // } else if (ctaData._group === 'email') {
      //   url = ctaData.email
      // } else if (ctaData._group === 'file') {
      //   url = ctaData.file
      // } else if (ctaData._group === 'image') {
      //   url = this.fullImage(ctaData.image_desktop)
      // }

      // if (localStorage.getItem('uc_performance')) {
      //   this.$gtm.trackEvent({
      //     event: 'info_box_cta_clicked',
      //     action: 'click',
      //     box_id: item.id,
      //     box_title: item.title,
      //     card: this.user.card.title,
      //     card_id: this.user.card.id,
      //     level_id: this.user.currentLevel,
      //     cta_type: ctaData._group,
      //     cta_title: ctaData.title,
      //     cta_url: url
      //   })
      // }

      if (type === 1) {
        this.handleCheck(item, toggle)
      } else if (type === 2) {
        this.handleItemClick(item, ctaData)
      } else {
        this.handleCall(ctaData.number)
      }
    },
    handleToggle (item) {
      if (typeof item.visible === 'undefined') this.$set(item, 'visible', true)
      else item.visible = !item.visible

      if (item.visible) {
        // if (localStorage.getItem('uc_performance')) {
        //   this.$gtm.trackEvent({
        //     event: this.type === 'faq' ? 'faq_box_opened' : this.inStartSlide ? 'start_slides_info_box_opened' : 'info_box_opened',
        //     action: 'click',
        //     box_id: item.id,
        //     box_title: item.title,
        //     card: this.user.card.title,
        //     card_id: this.user.card.id,
        //     level_id: this.user.currentLevel
        //   })
        // }

        this.canSubmitComment =
          this.rate &&
          ((this.item.rate > 0 && this.item.rate < 4) ||
            (this.item.rate > 7 && this.item.rate < 11))
        if (this.rateIndex !== -1) {
          this.item.rate = this.user.ratings[this.rateIndex].rate
        }
        this.trigger && this.$emit('triggerCollapse', this.item.id)

        // uncomment code below
        // let self = this
        // let usc = new UserCentricsController()
        // usc.arePerformanceCookiesAccepted().then(function (result) {
        //   if (result) {
        //     self.trackInfoBoxOpened(self.item.id)
        //   }
        // })
      }
      // if (this.checkFirst && !this.user.firstInfobox) {
      //   this.addFirstBox(true)
      // }
    },
    sendFeedback (item) {
      if (item.visible) {
        // let self = this
        // let usc = new UserCentricsController()
        // usc.arePerformanceCookiesAccepted().then(function (result) {
        //   result && self.handleRateChange()
        // })
        this.user.cookieConsent && this.handleRateChange()
      }
    },
    handleCheck (item, toggle) {
      let ucFunc = localStorage.getItem('uc_functional')
      const cardId = this.user.card.id
      let checked = this.user.checked
      if (item.done === undefined) this.$set(item, 'done', false)
      if (item.done !== undefined) {
        item.done = toggle ? !item.done : true
        if (checked && !checked.hasOwnProperty(item.id)) {
          this.$set(checked, item.id, {})
          checked[`${item.id}`] = {
            ...checked[`${item.id}`],
            [`${cardId}`]: item.done
          }
        } else {
          checked[`${item.id}`] = {
            ...checked[item.id],
            [`${cardId}`]: item.done
          }
        }
        // checked[item.id] = { ...checked[item.id], [`${cardId}`]: item.done }
        this.addCheckedList(checked)
        this.addUser(this.user)
        // if (localStorage.getItem('uc_performance')) {
        //   this.$gtm.trackEvent({
        //     event: this.inStartSlide ? 'start_slides_infobox_checked' : 'infobox_checked',
        //     action: 'click',
        //     box_id: item.id,
        //     box_title: item.title,
        //     card: this.user.card.title,
        //     card_id: this.user.card.id,
        //     level_id: this.user.currentLevel
        //   })
        // }

        // let self = this
        if (item.done) {
          if (ucFunc !== 'true') {
            this.changeCookiePopup(true)
          }
        }
      }
    },
    fullImage (data) {
      return data !== null
        ? this.$store.state.init.init.config.mediaBaseUrl + data
        : ''
    },
    responsiveImages (data) {
      if (this.$store.state.ui.device === 'sm') {
        return data.image_mobile
          ? this.fullImage(data.image_mobile)
          : this.fullImage(data.image_desktop)
      } else if (this.$store.state.ui.device === 'md') {
        return data.image_tablet
          ? this.fullImage(data.image_tablet)
          : this.fullImage(data.image_desktop)
      } else {
        return this.fullImage(data.image_desktop)
      }
    },
    handleItemClick (parent, item) {
      this.selectedItem = {
        title: this.title ? item.title : item.title_checklist,
        url: item._group === 'video' ? item.iframe : item,
        type: item._group
      }
      this.changeModal(this.selectedItem)
      // this.handleCheck(parent, false)
      // this.handleToggle(parent)
    },
    handleDone () {
      let checkedObject = this.user.checked
      const cardId = this.user.card.id
      const itemId = this.item.id
      if (
        checkedObject.hasOwnProperty(itemId) &&
        checkedObject[itemId].hasOwnProperty(cardId) &&
        checkedObject[itemId][cardId]
      ) {
        if (this.item.done === undefined) {
          this.$set(this.item, 'done', true)
        } else this.item.done = true
      }
    },
    getClass (item) {
      let result = []
      if (item.visible) {
        result.push('opened')
      } else {
        result.push('collapsed')
      }
      if (this.large) {
        result.push('large')
      } else {
        result.push('default')
      }

      if (item.done && this.checked) {
        result.push('done')
      } else {
        result.push('notDone')
      }

      if ((item.is_highlight || this.type === 'blue') && !this.inChecklist) {
        result.push('blueBoxCheck')
      }
      return result
    },
    handleFeedbackSubmit () {
      this.canSubmitComment =
        (this.item.rate > 0 && this.item.rate < 4) ||
        (this.item.rate > 7 && this.item.rate < 11)
      this.testKey++
      if (!this.user.cookieConsent) {
        let self = this
        let usc = new UserCentricsController()
        usc.arePerformanceCookiesAccepted().then(function (result) {
          if (!result) {
            self.changeCookiePopup(true)
          }
        })
      }
    },
    handleRateChange () {
      if (this.item.rate > 0 && parseInt(this.item.have_feedback) === 1) {
        if (localStorage.getItem('uc_performance') === 'true') {
          this.rateChanged(
            this.item.id,
            this.item.rate,
            this.feedbackComment || undefined,
            this.rateIndex
          )
        } else {
          this.addCookieConsent(false)
          this.changeCookiePopup(true)
          this.changeCookieModal(true)
        }
      }
    },
    async trackInfoBoxOpened (infoBoxId) {
      // const token = await new Promise((resolve) => {
      //   // eslint-disable-next-line no-undef
      //   grecaptcha.ready(async () => {
      //     // eslint-disable-next-line no-undef
      //     const token = await grecaptcha.execute(
      //       process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY
      //     )

      //     resolve(token)
      //   })
      // })
      await this.infoBoxChecked({
        // grcaptcha: token,
        info_box_id: infoBoxId
      })
    },
    async rateChanged (infoBoxId, rate, comment, index) {
      // const token = await new Promise((resolve) => {
      //   // eslint-disable-next-line no-undef
      //   grecaptcha.ready(async () => {
      //     // eslint-disable-next-line no-undef
      //     const token = await grecaptcha.execute(
      //       process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY
      //     )

      //     resolve(token)
      //   })
      // })

      await this.submitRatings({
        ratings: {
          // grcaptcha: token,
          info_box_id: infoBoxId,
          rate: rate,
          comment: comment
        },
        i: index
      }).then(() => {
        // this.handleToggle(this.item)
        this.feedbackComment = null
        this.testKey++
      })
    }
  },
  mounted () {
    this.handleDone()
  },
  beforeDestroy () {
    this.item.visible = false
  }
}
</script>
