var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.importantFunctions)?_c('div',{staticClass:"functionsSection"},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-x12 col-xxl10 offset-xxl1"},[_c('div',{staticClass:"sectionTitle flatMd",attrs:{"data-aos":"fade-left","data-aos-duration":"1000"}},[_c('h2',{staticClass:"title",domProps:{"textContent":_vm._s(
              _vm.$store.state.page.homepage.home.important_function_title
                .mobile_title
            )}}),_c('h3',{staticClass:"subtitle",domProps:{"textContent":_vm._s(
              _vm.$store.state.page.homepage.home.important_function_title
                .top_title_desktop
            )}}),_c('h3',{staticClass:"title",domProps:{"textContent":_vm._s(
              _vm.$store.state.page.homepage.home.important_function_title
                .bottom_title_desktop
            )}})]),_c('div',{staticClass:"functionsSlider"},[_c('swiper',{ref:"fnSlider",staticClass:"swiper",attrs:{"options":_vm.swiperFnOption},on:{"slideChange":function($event){return _vm.handleSlideChange()}}},[_vm._l((_vm.handleShitSlider),function(slideCols,i){return [_c('swiper-slide',{key:i,attrs:{"data-aos":"fade-up","data-aos-duration":"1000"}},[_vm._l((slideCols),function(infoBox,n){return [_c('single-checklist',{key:n + i + '-tes',staticClass:"shortTitle",attrs:{"item":infoBox,"large":_vm.$store.state.ui.device === 'lg',"trigger":true,"checked":infoBox.is_countable},on:{"triggerCollapse":_vm.triggerCollapse}})]})],2)]})],2),_c('div',{staticClass:"sliderTools"},[_c('div',{staticClass:"swiper-pagination swiper-pagination-two",attrs:{"slot":"pagination"},slot:"pagination"}),_c('div',{staticClass:"arrows swiper-prev swiper-prev-m",attrs:{"slot":"button-prev"},on:{"click":function($event){return _vm.handleArrowsEvent('b')}},slot:"button-prev"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"25","height":"14","viewBox":"0 0 25 14"}},[_c('path',{attrs:{"id":"Path","d":"M23.958,5.949a1.051,1.051,0,0,1,0,2.1H3.556l4.117,4.155a1.058,1.058,0,0,1,0,1.487,1.035,1.035,0,0,1-1.473,0L.3,7.744a1.059,1.059,0,0,1,0-1.487h0L6.2.308a1.036,1.036,0,0,1,1.473,0,1.058,1.058,0,0,1,0,1.487L3.556,5.949Z","transform":"translate(0)"}})])]),_c('div',{staticClass:"arrows swiper-next swiper-next-m",attrs:{"slot":"button-next"},on:{"click":function($event){return _vm.handleArrowsEvent('n')}},slot:"button-next"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"25","height":"14","viewBox":"0 0 25 14"}},[_c('path',{attrs:{"id":"Path","d":"M1.042,8.05a1.051,1.051,0,0,1,0-2.1h20.4L17.328,1.794a1.056,1.056,0,0,1,0-1.485,1.035,1.035,0,0,1,1.474,0L24.7,6.257a1.058,1.058,0,0,1,0,1.486L18.8,13.692a1.035,1.035,0,0,1-1.474,0,1.057,1.057,0,0,1,0-1.486L21.444,8.05Z"}})])])])],1)])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }