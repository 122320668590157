<template>
  <div class="pageTools">
    <button class="backButton" @click="handleBackButton">
      <span class="buttonIcon">
        <svg v-if="$store.state.ui.device !== 'sm'" xmlns="http://www.w3.org/2000/svg" width="25" height="14" viewBox="0 0 25 14">
          <path id="Path" d="M23.958,5.949a1.051,1.051,0,0,1,0,2.1H3.556l4.117,4.155a1.058,1.058,0,0,1,0,1.487,1.035,1.035,0,0,1-1.473,0L.3,7.744a1.059,1.059,0,0,1,0-1.487h0L6.2.308a1.036,1.036,0,0,1,1.473,0,1.058,1.058,0,0,1,0,1.487L3.556,5.949Z" transform="translate(0)"/>
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 14 24">
          <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
            <g stroke="#000" stroke-width="2.8">
              <path d="M30 88L40 98 30 108" transform="translate(-28 -86) rotate(-180 35 98)"/>
            </g>
          </g>
        </svg>
      </span>
      <!-- <span v-if="$route.fullPath === '/feedback/'" class="buttonText">zurück zum Dashboard</span> -->
      <span class="buttonText">zurück</span>
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import userStore from '@/store/modules/user'
import dynamicModule from '@/store/utils/dynamicModule'
export default {
  methods: {
    handleBackButton () {
      if ((this.$route.name === 'Static' || this.$route.fullPath === '/datenschutz')) {
        location.replace('https://www.americanexpress.com/de')
      } else {
        this.$router.go((sessionStorage.getItem('redirectLoop') && this.$route.params.slug === 'kontakt') ? -2 : -1)
      }
    }
  },
  mixins: [
    dynamicModule('user', userStore)
  ],
  computed: {
    ...mapState('user', ['user'])
  }
}
</script>
