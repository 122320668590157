import { AuthHttp } from '@/helpers/http-helper'
import { store } from '@/store'
import storage from '@/helpers/storage'

const localStorageUser = JSON.parse(storage.getUser())

const state = {
  user: localStorageUser || {
    cookies: false,
    intro: false,
    card: {},
    cardType: null,
    currentLevel: 1,
    switcher: false,
    checked: {},
    selectedCards: {},
    levels: {},
    firstInfobox: false,
    chkModal: false,
    cookieConsent: false,
    categories: {},
    focusBoxes: {},
    ratings: []
  }
}

const getters = {
  getUser: state => state.user,
  getCookies: state => state.user.cookies,
  getCard: state => state.user.card,
  getCardType: state => state.user.cardType,
  getCurrentLevel: state => state.user.currentLevel,
  getChecked: state => state.user.checked,
  getSelectedCards: state => state.user.selectedCards,
  getSwitcher: state => state.user.switcher,
  getLevels: state => state.user.levels,
  getFirstInfobox: state => state.user.firstInfobox,
  getChkModal: state => state.user.chkModal,
  getCategories: state => state.user.categories,
  getFocusBoxes: state => state.user.focusBoxes,
  getRatings: state => state.user.ratings
}

const mutations = {
  setUser (state, user) {
    state.user = user
    storage.setItem('user', JSON.stringify(state.user))
  },
  setCookies (state, cookies) {
    state.user.cookies = cookies
    storage.setItem('user', JSON.stringify(state.user))
  },
  setIntro (state, intro) {
    state.user.intro = intro
    storage.setItem('user', JSON.stringify(state.user))
  },
  setCard (state, card) {
    state.user.card = card
    storage.setItem('user', JSON.stringify(state.user))
  },
  setCardType (state, cardType) {
    state.user.cardType = cardType
    storage.setItem('user', JSON.stringify(state.user))
  },
  setCurrentLevel (state, currentLevel) {
    state.user.currentLevel = currentLevel
    storage.setItem('user', JSON.stringify(state.user))
  },
  setChecked (state, checked) {
    state.user.checked = checked
    storage.setItem('user', JSON.stringify(state.user))
  },
  setSelectedCards (state, cards) {
    state.user.selectedCards = cards
    storage.setItem('user', JSON.stringify(state.user))
  },
  setSwitcher (state, type) {
    state.user.switcher = type
    storage.setItem('user', JSON.stringify(state.user))
  },
  setLevels (state, levels) {
    state.user.levels = levels
    storage.setItem('user', JSON.stringify(state.user))
  },
  setFirstBox (state, bool) {
    state.user.firstInfobox = bool
    storage.setItem('user', JSON.stringify(state.user))
  },
  setChkModal (state, bool) {
    state.user.chkModal = bool
    storage.setItem('user', JSON.stringify(state.user))
  },
  setCategories (state, data) {
    state.user.categories = data
    storage.setItem('user', JSON.stringify(state.user))
  },
  setCookieConsent (state, data) {
    state.user.cookieConsent = data
    storage.setItem('user', JSON.stringify(state.user))
  },
  setFocusBoxes (state, data) {
    state.user.focusBoxes = data
    storage.setItem('user', JSON.stringify(state.user))
  },
  setRatings (state, data) {
    data.ratings.card = store.state.user.user.card.id
    if (data.i === -1) {
      state.user.ratings.push({ id: data.ratings.info_box_id, card: data.ratings.card, rate: data.ratings.rate })
    } else state.user.ratings[data.i] = { id: data.ratings.info_box_id, card: data.ratings.card, rate: data.ratings.rate }
    storage.setItem('user', JSON.stringify(state.user))
  },
  removeUser (state) {
    state.user = {}
    storage.removeItem('user')
  }
}

const actions = {
  addCookies ({ commit }, cookies) {
    commit('setCookies', cookies)
  },
  addIntro ({ commit }, intro) {
    commit('setIntro', intro)
  },
  addUser ({ commit }, user) {
    commit('setUser', user)
  },
  addCardType ({ commit }, cardType) {
    commit('setCardType', cardType)
  },
  addCard ({ commit }, card) {
    commit('setCard', card)
  },
  addLevel ({ commit }, level) {
    commit('setCurrentLevel', level)
  },
  addCheckedList ({ commit }, list) {
    commit('setChecked', list)
  },
  addSelectedCards ({ commit }, cards) {
    commit('setSelectedCards', cards)
  },
  addSwitcher ({ commit }, type) {
    commit('setSwitcher', type)
  },
  addLevels ({ commit }, levels) {
    commit('setLevels', levels)
  },
  addFirstBox ({ commit }, bool) {
    commit('setFirstBox', bool)
  },
  addChkModal ({ commit }, bool) {
    commit('setChkModal', bool)
  },
  addCategories ({ commit }, data) {
    commit('setCategories', data)
  },
  addFocusBoxes ({ commit }, data) {
    commit('setFocusBoxes', data)
  },
  addRatings ({ commit }, data) {
    commit('setRatings', data)
  },
  addCookieConsent ({ commit }, data) {
    commit('setCookieConsent', data)
  },
  submitRatings ({ commit }, data) {
    return AuthHttp.post('/infobox-rate/create', data.ratings).then(resp => {
      commit('setRatings', data)
      return resp
    })
  },
  infoBoxChecked ({ commit }, data) {
    return AuthHttp.post('/info-box/track', data).then(resp => {
      return resp
    })
  },
  clearAll ({ commit }) {
    commit('removeUser')
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
