<template>
  <main>
    <div class="pageContent imageBannerNone" v-if="page.title" sticky-container>
      <div class="grid-container">
        <div class="row">
          <div class="col-x12 col-xxl10 offset-xxl1">
            <div v-sticky="shouldStick" sticky-offset="{ top: 80 }">
              <page-tools></page-tools>
            </div>
          </div>
          <div class="col-x12 col-xxl10 offset-xxl1 simplePage">
            <div class="row flex flex-wrap">
              <div class="col-x12 col-s5">
                <div class="sectionTitle text-left">
                  <div data-aos="fade-up" data-aos-duration="1000" v-sticky="shouldStick" sticky-offset="{ top: 150 }">
                    <h2 class="title" v-if="page.title" v-text="page.title"></h2>
                    <p v-if="page.description" v-text="page.description"></p>
                  </div>
                </div>
              </div>
              <div class="col-x12 col-s7">
                <div class="checklistPageHolder">
                  <ul class="row">
                    <li class="col-x12" v-for="(item, i) in faqBoxes" :key="i" data-aos="fade-up"
                        data-aos-duration="1000" :data-aos-delay="(50 * i)">
                      <single-checklist :item="item" :large="false" v-on:triggerCollapse="triggerCollapse" :type="'faq'"
                                        :trigger="true" :checked="!!item.is_countable" :rate="false"></single-checklist>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import storage from '@/helpers/storage'
import Sticky from 'vue-sticky-directive'
import { mapActions, mapState } from 'vuex'
import userStore from '@/store/modules/user'
import pagesStore from '@/store/modules/page'
import dynamicModule from '@/store/utils/dynamicModule'
import PageTools from '@/components/pages/page-tools.vue'
import SingleChecklist from '@/components/single-checklist.vue'

export default {
  data () {
    return {
      faqSections: [],
      faqInfoboxes: [],
      page: {
        faq_box: []
      }
    }
  },
  metaInfo () {
    return {
      title: 'Faqs: ' + this.page?.title
    }
  },
  mixins: [
    dynamicModule('page', pagesStore),
    dynamicModule('user', userStore)
  ],
  components: {
    SingleChecklist,
    PageTools
  },
  directives: {
    Sticky
  },
  computed: {
    ...mapState('user', ['user']),
    // ...mapState('page', ['page']),
    faqBoxes () {
      return this.page.faq_box.filter((item, i) => {
        this.$set(item, 'visible', false)
        return item
      })
    },
    shouldStick () {
      return this.$store.state.ui.device !== 'sm'
    }
  },
  methods: {
    ...mapActions('user', ['addUser', 'addLevel']),
    ...mapActions('page', ['pageDetails', 'loadFaq', 'clearPage']),
    triggerCollapse (id) {
      for (let i = 0; i < this.faqBoxes.length; i++) {
        if (this.faqBoxes[i].id !== id) {
          this.faqBoxes[i].visible = false
        }
      }
    }
  },
  created () {
    const ssUser = JSON.parse(storage.getUser())
    if (ssUser) {
      this.addUser(ssUser)
    }
  },
  mounted () {
    const routeId = this.$route.params.id
    if (routeId !== undefined) {
      this.loadFaq(routeId).then(resp => {
        this.page = resp
      })
    }
  },
  beforeDestroy () {
    this.clearPage()
  }
}
</script>

<style scoped lang="scss"></style>
