import Vue from 'vue'
import VueRouter from 'vue-router'
import storage from '@/helpers/storage'

const BaseView = () => import('@/views/BaseView')

/**
 * Start view
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const StartIndexView = () => import('@/views/start/Index')
const StartView = () => import('@/views/start/Start')

/**
 * Home view
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const HomeIndexView = () => import('@/views/home/Index')
const HomeView = () => import('@/views/home/Home')

/**
 * Category view
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const CategoryIndexView = () => import('@/views/category/Index')
const CategoryView = () => import('@/views/category/Category')

/**
 * Faq view
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const FaqIndexView = () => import('@/views/faq/Index')
const FaqSingleView = () => import('@/views/faq/FaqSingle')

/**
 * Page view
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const PageIndexView = () => import('@/views/page/Index')
const PageView = () => import('@/views/page/Page')

/**
 * Search view
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const SearchIndexView = () => import('@/views/search/Index')
const SearchView = () => import('@/views/search/Search')

/**
 * Search view
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const StaticIndexView = () => import('@/views/static/Index')
const StaticView = () => import('@/views/static/Static')

/**
 * DataProtection view
 *
 * @returns {Promise<*>|*}
 * @constructor
 */
const DataProtectionIndexView = () => import('@/views/dataProtection/Index')
const DataProtectionView = () => import('@/views/dataProtection/DataProtection')

Vue.use(VueRouter)

const routes = [
  {
    path: process.env.VUE_APP_PUBLIC_PATH,
    component: BaseView,
    children: [
      {
        path: 'start',
        component: StartIndexView,
        children: [
          {
            path: '',
            name: 'Start',
            component: StartView
          }
        ]
      },
      {
        path: '',
        component: HomeIndexView,
        children: [
          {
            path: '/',
            name: 'Home',
            component: HomeView
          }
        ]
      },
      {
        path: 'vorteilswelt',
        component: CategoryIndexView,
        children: [
          {
            path: ':slug',
            name: 'Category',
            component: CategoryView
          }
        ]
      },
      {
        path: 'faq/:id',
        component: FaqIndexView,
        children: [
          {
            path: '',
            name: 'FaqSingle',
            component: FaqSingleView,
            meta: { mainHeader: true }
          }
        ]
      },
      {
        path: 'search',
        component: SearchIndexView,
        children: [
          {
            path: '',
            name: 'Search',
            component: SearchView,
            props: route => ({
              keyword: route.query.keyword
            }),
            meta: { mainHeader: true }
          }
        ]
      },
      {
        path: 'kontakt-seite',
        component: StaticIndexView,
        children: [
          {
            path: '',
            name: 'Static',
            component: StaticView,
            meta: { mainHeader: true }
          }
        ]
      },
      {
        path: 'data-protection',
        component: DataProtectionIndexView,
        children: [
          {
            path: '',
            name: 'DataProtection',
            component: DataProtectionView,
            meta: { mainHeader: true, hideHeader: true }
          },
          {
            path: ':slug',
            name: 'DataProtections',
            component: DataProtectionView,
            meta: { mainHeader: true, hideHeader: true }
          }
        ]
      },
      {
        path: ':slug',
        component: PageIndexView,
        children: [
          {
            path: '',
            name: 'Page',
            component: PageView,
            meta: { mainHeader: true }
          }
        ]
      },
      {
        path: '*',
        component: PageIndexView,
        children: [
          {
            path: '/',
            name: 'OtherPages',
            component: PageView,
            meta: { mainHeader: true }
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      if (savedPosition) {
        // History back position, if user click Back button
        savedPosition.behavior = 'smooth'
        setTimeout(() => {
          resolve(savedPosition)
        }, to.name === 'Home' ? 1000 : 500)
      } else {
        // Scroll to top of page if the user didn't press the back button
        resolve({ x: 0, y: 0 })
      }
    })
  }
})

let allowedPagesToAccessWithoutCookieConsent = [
  process.env.VUE_APP_PUBLIC_PATH + 'kontakt-seite',
  process.env.VUE_APP_PUBLIC_PATH + 'datenschutz',
  process.env.VUE_APP_PUBLIC_PATH + 'principles',
  process.env.VUE_APP_PUBLIC_PATH + 'uber-cookies',
  process.env.VUE_APP_PUBLIC_PATH + 'cookie-check',
  process.env.VUE_APP_PUBLIC_PATH + 'data-protection'
]

router.beforeEach((to, from, next) => {
  const dataSt = storage.getUser()
  const dd = JSON.parse(dataSt)
  if ((!from.name || from.name === 'Static') && to.params.slug === 'kontakt') {
    sessionStorage.setItem('redirectLoop', true)
  } else {
    sessionStorage.removeItem('redirectLoop')
  }
  if (to.name === 'Static' || to.name === 'Start') {
    next()
  } else if (allowedPagesToAccessWithoutCookieConsent.includes(to.path) || allowedPagesToAccessWithoutCookieConsent.includes(to.path.substring(0, to.path.length - 1)) || to.path.includes('data-protection')) {
    next()
  } else if (dataSt) {
    if (dd && dd.intro && localStorage.getItem('uc_essential')) {
      next()
    } else {
      if (!localStorage.hasOwnProperty('redirect')) {
        localStorage.setItem('redirect', to.path)
      }
      next({ name: 'Start', query: to.query })
    }
  } else {
    if (!localStorage.hasOwnProperty('redirect')) {
      localStorage.setItem('redirect', to.path)
    }
    next({ name: 'Start', query: to.query })
  }
})

export default router
