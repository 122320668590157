<template>
    <component :is="tag" :class="className" v-html="compText"></component>
</template>

<script>
export default {
  name: 'Nl2br',
  props: {
    tag: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    className: {
      type: String,
      required: false
    }
  },
  computed: {
    compText () {
      if (this.text) {
        return this.text.trim().replace(/(?:\r\n|\r|\n)/g, '<br/>')
      }
      return null
    }
  }
}
</script>

<style scoped>

</style>
