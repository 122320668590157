import { AuthHttp } from '@/helpers/http-helper'
// import router from '@/router/index'

const state = {
  device: '', // 4 devices: 'lg', 'md', 'tp', 'sm'
  modal: {},
  showModal: false,
  reload: false, // trigger card reload
  cookieModal: false,
  nav: false,
  navItems: [],
  searchBar: false,
  cookiePopup: false,
  surveyPopupText: null,
  surveyPopupImage: null
}

const getters = {
  getDevice: state => state.device,
  getModal: state => state.modal,
  getShowModal: state => state.showModal,
  getReload: state => state.reload,
  getCookieModal: state => state.cookieModal,
  getNav: state => state.nav,
  getNavItems: state => state.navItems,
  getSearchBar: state => state.searchBar,
  getCookiePopup: state => state.cookiePopup,
  getSurveyPopupText: state => state.surveyPopupText,
  getSurveyPopupImage: state => state.surveyPopupImage
}

const mutations = {
  setDevice (state, device) {
    state.device = device
  },
  setModal (state, modal) {
    state.modal = modal
    state.showModal = true
  },
  setReload (state, data) {
    state.reload = data
  },
  setCookieModal (state, data) {
    state.cookieModal = data
  },
  setCookiePopup (state, data) {
    state.cookiePopup = data
  },
  setNav (state, data) {
    state.nav = data
  },
  setNavItems (state, data) {
    state.navItems = data
  },
  setSearchBar (state, data) {
    state.searchBar = data
  },
  setSurveyPopupImage (state, data) {
    state.surveyPopupImage = data
  },
  setSurveyPopupText (state, data) {
    state.surveyPopupText = data
  },
  clearModalData (state, data) {
    state.modal = data
    state.showModal = false
  }
}

const actions = {
  changeDevice ({ commit }, data) {
    commit('setDevice', data)
  },
  changeModal ({ commit }, data) {
    commit('setModal', data)
  },
  changeCookieModal ({ commit }, data) {
    commit('setCookieModal', data)
    return true
  },
  changeCookiePopup ({ commit }, data) {
    commit('setCookiePopup', data)
    return true
  },
  changeNav ({ commit }, data) {
    commit('setNav', data)
    return true
  },
  changeNavItems ({ commit }) {
    AuthHttp.get('/nav').then(resp => {
      commit('setNavItems', resp.data)
      return true
    })
  },
  changeSearchBar ({ commit }, data) {
    commit('setSearchBar', data)
    return true
  },
  triggerReload ({ commit }, data) {
    commit('setReload', data)
  },
  changePopupImage ({ commit }, data) {
    commit('setSurveyPopupImage', data)
  },
  changePopupText ({ commit }, data) {
    commit('setSurveyPopupText', data)
  },
  clearModal ({ commit }) {
    commit('clearModalData', {})
  }
}
const namespaced = true

export default {
  namespaced,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
