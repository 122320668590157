<template>
  <div class="initial-cookie-popup">
    <div class="initial-cookie-popup__inner">
      <header class="initial-cookie-popup__inner__header">
        <h3>American Express Cookie Preferences</h3>
      </header>
      <div class="initial-cookie-popup__inner__content">
        <div class="initial-cookie-popup__inner__content__text">
          <h6>Unsere Verwendung von Cookies</h6>
          <p>
            <b>American Express verwendet Cookies</b> auf dieser Website für
            nicht wesentliche Zwecke. Sie helfen uns damit zu verstehen, wie Sie
            unsere Website nutzen, Inhalte zu personalisieren und unser
            Online-Marketing zu verbessern. Durch Klicken auf „Alle akzeptieren“
            erklären Sie sich damit einverstanden, dass wir und unsere Partner
            Cookies auf Ihrem Gerät für diese Zwecke speichern und abrufen
            können. Sie können Ihre Einstellungen jederzeit ändern und weitere
            Informationen über die Funktionsweise von Cookies finden, indem Sie
            in der Rubrik „Cookie-Einstellungen festlegen“ in unseren
            <router-link :to="{ name: 'DataProtection' }" target="_blank">
              Cookie-Richtlinien
            </router-link>
            nachsehen.
          </p>
        </div>
      </div>
    </div>

    <footer class="initial-cookie-popup__footer">
      <button @click.stop.prevent="$event => acceptCookieModal()">
        Alle ablehnen
      </button>
      <button @click="$emit('cookieDetails')">
        Mehr Optionen
      </button>
      <button @click="acceptAllServices">
        Alle akzeptieren
      </button>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'InitialCookiePopup',
  data () {
    return {}
  },

  methods: {
    ...mapActions('ui', ['changeCookieModal']),
    ...mapActions('user', ['addCookies']),
    declineAllServices () {
      // console.log('Should decline all cookies')
    },

    acceptAllServices () {
      let self = this
      this.$userCentrics.acceptAllServices().then(function () {
        self.$userCentrics.getCategories()

        localStorage.setItem('uc_essential', true)
        localStorage.setItem('uc_functional', true)
        localStorage.setItem('uc_performance', true)

        // !self.hasRecaptcha() && self.createRecaptcha()
        // !self.$gtm.enabled() && self.$gtm.enable(true)

        self.$store.dispatch('user/addCookieConsent', true)
        self.$store.dispatch('user/addCookies', true)
        // self.addCookies(true)
        self.changeCookieModal(false)
      })
    },

    acceptCookieModal () {
      localStorage.setItem('uc_essential', true)
      this.addCookies(true)

      // !this.hasRecaptcha() && this.createRecaptcha()

      // let _self = this

      // this.$store.dispatch('user/addCookieConsent', true)
      if (localStorage.getItem('uc_functional') === 'false') {
        // debugger
        this.$store.replaceState({})
        sessionStorage.clear()
        localStorage.clear()
      }
    }
  },
  computed: {
    ...mapState('ui', ['cookieModal'])
  }
}
</script>
