<template>
  <div class="bannerImageContent" :class="item.color_selection">
    <div class="grid-container">
      <div class="row">
        <div class="col-x12 col-xxl10 offset-xxl1">
          <div class="bannerContainer__wrapper">
            <span class="markLogo">
              <MarkMemberSince />
            </span>
            <div class="bannerContainer__wrapper--content">
              <h2 v-text="item.title"></h2>
              <div v-html="item.content_elements ? item.content_elements : item.content"></div>
              <a class="button rounded small" v-if="item.button_action && item.button_title" target="_blank" :href="item.button_action">
                <span v-text="item.button_title"></span>
              </a>
            </div>
            <div class="bannerContainer__wrapper--image">
              <img :src="image" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MarkMemberSince from '@/components/icons/mark-member-since.vue'
export default {
  components: {
    MarkMemberSince
  },
  props: {
    item: {
      type: Object,
      default: null,
      required: true
    },
    image: {
      type: String,
      default: '',
      required: true
    }
  },
  data () {
    return {}
  }
}
</script>
