<template>
  <div class="grid-container" v-if="content">
    <div class="row">
      <div class="col-12 col-s6 col-l5 col-xxl10 offset-xxl1">
        <div class="sectionTitle" data-aos="fade-left" data-aos-duration="1000">
          <h2 class="title" v-if="content.title" v-text="content.title"></h2>
          <p v-if="content.description" v-text="content.description"></p>
        </div>
      </div>
      <div class="col-x12 col-xxl10 offset-xxl1 simplePage">
        <div class="videoGrid row">
          <div class="videoWrapper col-s6 col-m4" v-for="(video, i) in videos" :key="i" data-aos="fade-up"
               :data-aos-delay="(50 * i)" data-aos-duration="1000">
            <h4 v-text="video.title"></h4>
            <div class="videoBox" @click.stop="handleVideoPlay(video)">
              <img :src="responsiveImages(video)"/>
              <span class="playButton">
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                  <g fill="none" fill-rule="evenodd">
                    <g fill="#006FCF" fill-rule="nonzero">
                      <g>
                        <path
                          d="M15 0c8.313 0 15 6.688 15 15 0 8.313-6.688 15-15 15-8.313 0-15-6.688-15-15C0 6.687 6.688 0 15 0zm0 1.875C7.75 1.875 1.875 7.75 1.875 15S7.75 28.125 15 28.125 28.125 22.25 28.125 15 22.25 1.875 15 1.875zm-1.838 7.148l7.188 4.375c1.2.731 1.2 2.473 0 3.203l-7.188 4.375c-1.25.76-2.85-.139-2.85-1.602v-8.749c0-1.463 1.6-2.363 2.85-1.602zm-.975 1.602v8.75L19.375 15l-7.188-4.375z"
                          transform="translate(-660 -838) translate(660 838)"/>
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modalContainer videoPlayerModal" @click.self="handleVideoClose()" v-if="videoPlayer">
      <div class="modalPopup">
        <div class="popupHeader">
          <h2 v-text="selectedVideo.title"></h2>
          <div class="spacer"></div>
          <span class="iconRight">
            <button class="iconButton small" type="button" @click="handleVideoClose()">
              <img :src="icons.closeWhite"/>
            </button>
          </span>
        </div>
        <div class="popupContent auto-height">
          <iframe :src="selectedVideo.url" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
        </div>
        <!-- <div class="popupActions flex justify-center">
          <button class="button rounded">Schließen</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import dynamicModule from '@/store/utils/dynamicModule'
import pagesStore from '@/store/modules/page'

export default {
  data () {
    return {
      selectedVideo: {
        title: '',
        url: ''
      },
      videoPlayer: false,
      videos: [],
      page: null,
      icons: {
        close: require('@/assets/images/x-icon-blue.svg'),
        closeWhite: require('@/assets/images/x-icon-white.svg')
      }
    }
  },
  props: {
    content: {
      default: function () {
        return { title: 'How-To-Videos', subtitle: '' }
      }
    }
  },
  mixins: [
    dynamicModule('page', pagesStore)
  ],
  watch: {
    videoPlayer (val) {
      let el = document.getElementsByTagName('body')[0]
      el.style.overflow = val ? 'hidden' : ''
    }
  },
  computed: {
    // ...mapState('page', ['page'])
  },
  methods: {
    ...mapActions('page', ['loadVideos']),
    handleVideoPlay (video) {
      if (video) {
        this.selectedVideo = {
          title: video.title,
          url: video.url + '?autoplay=1'
        }
        this.videoPlayer = true
      }
    },
    handleVideoClose () {
      this.videoPlayer = false
      this.selectedVideo = {
        title: '',
        url: ''
      }
    },
    fullImage (data) {
      return data !== null ? this.$store.state.init.init.config.mediaBaseUrl + data : ''
    },
    responsiveImages (data) {
      if (this.$store.state.ui.device === 'sm') {
        return data.image_mobile ? this.fullImage(data.image_mobile) : this.fullImage(data.image_desktop)
      } else if (this.$store.state.ui.device === 'md') {
        return data.image_tablet ? this.fullImage(data.image_tablet) : this.fullImage(data.image_desktop)
      } else {
        return this.fullImage(data.image_desktop)
      }
    }
  },
  mounted () {
    this.loadVideos().then(response => {
      this.videos = response
    })
  },
  beforeDestroy () {
    this.handleVideoClose()
  }
}
</script>
