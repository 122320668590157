import { AuthHttp } from '@/helpers/http-helper'
// import router from '@/router/index'
const getDefaultState = () => {
  return {
    categories: [],
    category: {},
    importantFunctions: {
      title: '',
      subtitle: '',
      description: '',
      infoBoxes: []
    },
    damageReports: {
      title: '',
      subtitle: '',
      description: '',
      infoBoxes: []
    }
  }
}
const state = getDefaultState()

const getters = {
  getCategories: state => state.categories,
  getCategory: state => state.category
}

const mutations = {
  setCategories (state, data) {
    state.categories = data
  },
  setCategory (state, data) {
    state.category = data
  },
  setImportantFunctions (state, data) {
    state.importantFunctions = data
  },
  setDamageReports (state, data) {
    state.damageReports = data
  },
  clearCats (state) {
    state = getDefaultState()
  },
  clearCat (state) {
    state.category = {}
  }
}

const actions = {
  loadCategories ({ commit }, categoryId) {
    return AuthHttp.get('advantage-worlds?ignore=' + categoryId).then(resp => {
      commit('setCategories', resp.data)
      return resp.data
    })
  },
  loadImportantFunctions ({ commit }) {
    return AuthHttp.get('important-functions').then(resp => {
      commit('setImportantFunctions', resp.data)
      return resp.data
    })
  },
  loadDamageReports ({ commit }) {
    return AuthHttp.get('damage-reports').then(resp => {
      commit('setDamageReports', resp.data)
      return resp.data
    })
  },
  details ({ commit }, slug) {
    return AuthHttp.get('/advantage-worlds/slug/' + slug).then(resp => {
      return resp.data
    })
  },
  clearCategory ({ commit }) {
    commit('clearCat')
  },
  clear ({ commit }) {
    commit('clearCats')
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
