<template>
  <div class="tabs">
    <div class="tabs border" data-toggle="tabs">
      <div class="tab-menu" role="tablist">
        <button style="padding: 20px;" v-for="(tab, index) in tabs" @click="activeTab = index" :key="'tab-' + index"
                :class="{'active': index === activeTab}"
                v-text="tab.title"/>
      </div>

      <div class="tab-content pad-tb pad-responsive-lr">
        <div class="tab-content-element">
          <div class="image" v-if="tabs[activeTab] && tabs[activeTab].image">
            <img :src="$store.state.init.init.config.mediaBaseUrl + tabs[activeTab].image"/>
          </div>
          <div class="content" v-if="tabs[activeTab]" v-html="tabs[activeTab].content"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default () {
        return []
      }
    },
    tabElements: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      activeTab: 0
    }
  }
}
</script>
