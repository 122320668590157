import { AuthHttp } from '@/helpers/http-helper'

const state = {
  init: {
    config: {},
    cards: []
    // mainNav: [],
    // footerNav: []
  }
}

const getters = {
  getInit: state => state.init
}

const mutations = {
  setInit (state, init) {
    state.init = init
  }
}

const actions = {
  loadInit ({ commit }) {
    return AuthHttp.get('/init').then(resp => {
      commit('setInit', resp.data)
      return resp.data
    })
  },
  loadCardPopups ({ commit }, data) {
    return AuthHttp.get('/start-page-popups/by-card/' + data).then(resp => {
      return resp.data
    })
  }
}
const namespaced = true

export default {
  namespaced,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
}
